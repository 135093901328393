<template>
  <div class="field">
    <label class="label" v-if="label">
      {{ label }}
    </label>
    
    <div v-if="allowsOverride">
      <document-item-attribute
        :input="input"
        attribute="value"
        @update:documentItem="updateDocumentItem"
      />
    </div>

    <div v-else-if="hasContactSearch">
      <article class="panel input-like is-relative" :class="{ blockIn: dropdownOptions.length }" v-click-outside-app="resetOptions">
        <div class="panel-block">
          <p class="control has-icons-right">
            <input
              type="text"
              class="input"
              v-model="computedValue"
              :placeholder="placeholder"
              @input="search($event)"
              @focus="checkLoadedOptions($event)"
              @change="evalUpdate"
            />
            <span class="icon is-right">
              <font-awesome-icon icon="address-book" size="sm" v-if="searchType == 'contact'"/>
              <font-awesome-icon icon="building" size="sm" v-if="searchType == 'organization'"/>
            </span>
          </p>
        </div>
        <div class="panel-options-container">
          <a role="button" class="panel-block" @click.prevent="selectOption(item)" v-for="item in dropdownOptions" :key="item.id">
            {{ itemValue(item) }}
          </a>
        </div>
      </article>
      <!-- logo offer -->
      <div class="notification" v-if="logoAvailable && logoSuggestion && hasTriggerLogo">
        <button class="delete" @click="logoSuggestion = false"></button>
        <p class="mb-3">Would you like to use the logo on file?</p>
        
        <img v-for="logo in storedSelection.logos" :key="logo.id" :src="logo.url" title="Click to select" style="max-width:180px;" class="is-clickable" @click="selectLogo(storedSelection, logo.id)">
      </div>
    </div>

    <input
      v-else
      type="text"
      class="input"
      v-model="computedValue"
      :placeholder="placeholder"
      @change="emitUpdate($event)"
    />

    <field-message v-if="inputError.message.length" :object="inputError">
      {{ inputError.message }}
    </field-message>
  </div>
</template>

<script>
import { inject, computed, ref, onBeforeMount} from 'vue'
import { inputError, updateInputError } from '@/shared/setup/notifications.js'
import DocumentItemAttribute from '@/components/inputs/accessories/DocumentItemAttribute.vue'
import ContactService from '@/services/ContactService.js'
import { useStore } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  components: {
    DocumentItemAttribute
  },
  setup(props, { emit }){
    const store = useStore()
    const properties = computed(() => props.input.properties)
    const allowsOverride = computed(() => properties.value.allow_override_value && properties.value.allow_override_value == 'true' )
    const locale = inject('dmtLocale')
    const computedValue = computed({
      get: () => {
        // return props.compModelVal.value
        return props.input.value
      },
      set: value => {
        emit('update:immediate', value)
      }
    })
    
    // not used for contacts
    const emitUpdate = (ev) => {
      const value = ev.target.value
      emit('update:modelValue', value)
    }

    const updateDocumentItem = (inputRef, attr, newValue) => {
      emit('update:input', inputRef, attr, newValue)
    }

    const label = computed(() => properties.value.label)
    const placeholder = computed(() => properties.value.placeholder)

    const dropdownOptions = ref([])
    
    const organizationLookup = async (value) => {
      const query = {
        name_cont: value
      }
      ContactService.getOrganizations(query)
      .then(response => {
        dropdownOptions.value = response.data
      })
      .catch(error => {
        console.log(error)
      })
    }

    const contactLookup = async (value) => {
      const query = {
        name_cont: value
      }

      ContactService.getContacts(query)
      .then(response => {
        dropdownOptions.value = response.data
      })
      .catch(error => {
        console.log(error)
      })
    }

    const itemValue = (item) => {
      return searchType.value == 'contact' ? (item.first_name + ' ' + item.last_name) : item.name
    }

    const selectOption = (item) => {
      const value = itemValue(item)
      emit('update:immediate', value)

      // associates document with this Org or Contact (via DIO)
      emit('update:assignContact', item, searchType.value)
      
      const storeObject = {
        key: searchType.value,
        value: item
      }

      store.commit('saveDocumentSelection', storeObject)
      logoSuggestion.value = true

      if (searchType.value == 'contact') {
        evalUpdate()
      }
      
      // clears dropdown
      resetOptions()

    }
    
    // make this based on property - company name (organization), contact name
    const hasContactSearch = computed(() => properties.value.contact_search_type)
    const searchType = computed(() => properties.value.contact_search_type )
    const hasOptions = computed(() => dropdownOptions.value && dropdownOptions.value.length)
    const checkLoadedOptions = (ev) => {
      // on focus, checks for store changes
      
      if (searchType.value == 'organization' && storedContact.value) {
        dropdownOptions.value = ev.target.value ? [] : storedContact.value.organizations
      } else if (searchType.value == 'contact' && storedOrganization.value) {
        dropdownOptions.value = ev.target.value ? [] : storedOrganization.value.contacts
      }
    }

    // Vuex store selection - contact or organization
    const storedSelection = computed(() => store.state.currentDocument.sharedSelection[searchType.value])
    const storedContact = computed(() => store.state.currentDocument.sharedSelection['contact'])
    const storedOrganization = computed(() => store.state.currentDocument.sharedSelection['organization'])
    const logoAvailable = computed(() => storedSelection.value && storedSelection.value.logos)
    const logoSuggestion = ref(false)

    const hasTriggerLogo = computed(() => properties.value.triggers_update_on)

    const selectLogo = (contactObject, logoId) => {
      // emits call that 1. deletes current attachment (if any) 2. Uploads new logo
      // indicate 'triggers_update_on' - in properties. Which data element should be updated
      emit('update:logo', contactObject, searchType.value, hasTriggerLogo.value, logoId )
      logoSuggestion.value = false
    }

    onBeforeMount(async () => {
      // figure out initial selection, if any (mostly for editing a previous document)
      if (!storedSelection.value && searchType.value == 'organization' && computedValue.value) {
        const query = {
          name_eq: computedValue.value
        }
        const matches = await ContactService.getOrganizations(query)

        if (matches.data.length) {
          const storeObject = {
            key: searchType.value,
            value: matches.data[0]
          }
          store.commit('saveDocumentSelection', storeObject)
        }
      }
    })

    const search = debounce((ev) => {
      const q = ev.target.value

      // prevent below upon selection
      if (searchType.value == 'organization') {
        organizationLookup(q)
      } else if (searchType.value == 'contact') {
        contactLookup(q)
      }
      
    }, 800)

    const resetOptions = () => {
      dropdownOptions.value = []
    }

    const evalUpdate = () => {
      // console.log('eval update: ', ev.target.value)
      // //
      window.setTimeout(() => {
        emit('update:modelValue', computedValue.value)
      }, 1000)
    }


    return {
      inputError,
      updateInputError,
      locale,
      computedValue,
      label,
      placeholder,
      allowsOverride,
      emitUpdate,
      updateDocumentItem,
      hasOptions,
      dropdownOptions,
      hasContactSearch,
      selectOption,
      search,
      searchType,
      storedSelection,
      logoAvailable,
      logoSuggestion,
      hasTriggerLogo,
      selectLogo,
      checkLoadedOptions,
      itemValue,
      resetOptions,
      evalUpdate
    }
  },
  props: {
    input: {
      type: Object,
      required: true
    },
    // compModelVal: {
    //   type: [String, Number, Boolean, Array, Object],
    //   default: ''
    // }
  }
}
</script>
