<!-- template will be rendered using the render function below -->
<!-- 

<div class="form-group">
    <label v-if="input.properties.label">{{ input.properties.label }}</label>
    <select
      v-model="input.value_array[1]"
      class="form-control"
      @change="updateDIOwithOverride(input)"
      :class="error.style"
    >
      <option v-for="headline in group_items" :key="headline.id" :value="headline.id">{{
        headline.name_label
      }}</option>
    </select>
  </div>
  
 -->
<script>
import { h } from "vue";


// global function that figures out what to do with the children, ++ next levels
// REFACTOR FOR SETUP, USING PROPS

export default {
  components: {
  },
  setup(props, context){
    function resolveType(object){
      return Array.isArray(object) ? "array" :
      typeof(object) == 'object' ? "object" :
      typeof(object) == 'string' ? "string" :
      "unknown"
    }

    function getChildrenContent(children) {
      let resolved;
      let objType = resolveType(children);

      switch(objType) {
        case 'array':
          // code block

          resolved = children.map(child => {
            
            // sets v-model binding
            if (child.vModel){
              // console.log('assigning model value')
              // child.properties["modelValue"] = props.input.value
              // child.properties['onUpdate:modelValue'] = value => context.emit('updateInput', value)
              // 
              child.properties['onChange'] = $event => {
                // console.log($event)
                context.emit('updateInput', $event.target.value, child.vModel)
              }
            }
          

            return h(child.tagName, child.properties, getChildrenContent(child.children))

          })

          break;
        case 'object':
          // code block
            
            var childObject = children

            if (childObject.collection && childObject.collectionItems) {
              var collection = props.groupSelects[childObject.collection]
              collection = collection[childObject.collectionItems]

              if (collection) {

                // // add v-model to properties before generating element
                // if (childObject.vModel && props.input[childObject.vModel]){
                //   childObject.properties["modelValue"] = props.input[childObject.vModel]
                // }

                // // this is for option - must generate different h() for different input types or use existing components

                resolved = collection.map(item => {
                  let value = item[childObject.valueProperty]
                  let label = item[childObject.labelProperty]
                  let tagType = childObject.tagName
                  let properties = {}

                  properties["value"] = value

                  return h(tagType, properties, label)

                })
                
                return resolved
              }
            }

          break;
        
          case 'string':

             resolved = children

          break;

          default:
            console.log('Unknown type')
          // code block
      }

      return resolved
      
    }

    return () => h(props.node.tagName, props.node.properties, getChildrenContent(props.node.children))

    // return () => {
    //   // console.log(props.node.children.map(c => getChildrenContent(c.children)))
    //   console.log("IS RETURNED (FINAL) ", getChildrenContent(props.node.children))

    //   h(props.node.tagName, props.node.properties, getChildrenContent(props.node.children))
    // }

      
  },
  props: {
    node: {
      type: Object,
      required: true
    },
    groupSelects: {
      type: [Array, Object],
      required: false
    },
    input: {
      type: Object,
      required: true
    }
  },
  emits: ['updateInput'],
  data(){
    return {
    }
  },
  
  created(){
  },
  methods: {
    
  }
}

</script>