<template>
  <div>
    <div class="columns is-multiline is-8" v-if="options.length > 0 && isCategorized">
      <div v-for="parent in options" :key="parent.category_id" class="column is-half mb-5 is-align-items-stretch">
        <div class="card full-height checkbox-select">
          <div class="card-image">
            <figure class="image ">
              <img :src="parent.category_image_url" />
            </figure>
          </div>
          <div class="card-content">
            <p class="title is-4">{{ parent.category }}</p>

            <div v-for="option in parent.items" :key="option.id">
              <label class="checkbox">
                <input type="checkbox" :value="option.id" v-model="computedValue">
                {{ option.name }}
              </label>
              <override-option 
                :option="option"
                :documentItem="documentItems[option.id.toString()]"
                attribute="value_text"
                @update:input="updateDocumentItem"
              />
              <a class="icon" v-if="hasAttachment" :href="firstAttachmentUrl(option)" target="_blank" title="Open preview in a new page">
                <font-awesome-icon icon="file" size="sm" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <Loading :loading="options.length == 0"></Loading>
    </div>

    <field-message v-if="inputError.message.length" :object="inputError">
      {{ inputError.message }}
    </field-message>
  </div>
</template>

<script>
  import { computed, inject, provide } from 'vue'
  import { inputError, updateInputError } from '@/shared/setup/notifications.js'
  import { getGroupOptions } from '@/shared/setup/inputTypeHelpers.js'
  import OverrideOption from '@/components/inputs/accessories/OverrideOption.vue'


  export default {
    components: {
      OverrideOption,
    },
    props: {
      input: {
        type: Object,
        required: true
      },
    },
    setup(props, {emit}) {
      const groupSelectName = computed(() => props.input.group.code)
      const groupSelect = inject('groupSelect')
      const properties = computed(() => props.input.properties)
      const hasAttachment = computed(() => properties.value.include_attachment && properties.value.include_attachment == 'true')
      
      const firstAttachmentUrl = (option) => {
        let url = '#'
        if (option.attachments.length) {
          url = option.attachments[0].url
        }
        return url
      }
      const groupContentType = computed(() => {
        return props.input.group.content_type || props.input.value_array[0]
      })

      const computedValue = computed({
        get: () => {
          if (props.input.value_array && props.input.value_array.length) {
            return props.input.value_array
          } else {
            return [groupContentType.value]
          }
        },
        set: (value) => {
          const newValue = value.includes(groupContentType.value) ? value : [groupContentType.value, ...value]
          emit('update:modelValue', newValue, props.input)
        }
      })
      // const label = computed(() => properties.value.label)

      const documentItems = computed(() => props.input.document_items)
      const isCategorized = computed(() => properties.value.group_by_categories && properties.value.group_by_categories == 'true')

      const options = computed(() => {
        if (isCategorized.value) {
          return groupSelect[groupSelectName.value] || []
        } else {
          return getGroupOptions(groupSelect, groupSelectName.value, 'items') || []
        }
      })

      const updateDocumentItem = (docItemId, attribute, newValue) => {
        emit('update:input', docItemId, attribute, newValue)
      }

      provide('inputAllowsOverride', properties.value.allow_override_value_text)

      return {
        inputError,
        updateInputError,
        options,
        // label,
        updateDocumentItem,
        computedValue,
        documentItems,
        isCategorized,
        hasAttachment,
        firstAttachmentUrl
      }
    }
  }
</script>