<template>
  <div>
    <label class="label" v-if="label">
      {{ label }}
    </label>
    <div class="control" v-if="options.length">
      <div class="select">
        <select v-model="computedValue">
          <option v-for="option in options" :key="option.id" :value="option.id">
            {{ option.name_label || option.value_label || option.name }}
          </option>
        </select>
      </div>
    </div>
    <div v-else>
      <Loading :loading="options.length == 0"></Loading>
    </div>

    <field-message v-if="inputError.message.length" :object="inputError">
      {{ inputError.message }}
    </field-message>
  </div>
</template>

<script>
import { inject, computed, ref } from 'vue'
import { inputError, updateInputError } from '@/shared/setup/notifications.js'
import { getGroupOptions } from '@/shared/setup/inputTypeHelpers.js'

export default {
  setup(props, { emit }) {
    const locale = inject('dmtLocale')
    const groupSelectName = computed(() => props.input.group ? props.input.group.code : '')
    const groupSelect = inject('groupSelect')
    // considers the kind of value that needs to be passed. If there is no content type, first item in the value_array (should not have a default)
    const groupContentType = computed(() => {
      return props.input.group.content_type || props.input.value_array[0]
    })
    const computedValue = computed({
      get: () => {
        return props.input.value_array[1]
      },
      set: (value) => {
        emit('update:modelValue', [groupContentType.value, value], props.input)
      }
    })
    const label = ref(props.input.properties.label)
    const placeholder = ref(props.input.properties.placeholder)

    const options = computed(() => {
      return getGroupOptions(groupSelect, groupSelectName.value, 'items')
    })

    return {
      inputError,
      updateInputError,
      locale,
      computedValue,
      options,
      label,
      placeholder
    }
  },
  props: {
    input: {
      type: Object,
      required: true
    },
  }
}
</script>
