<!-- asks for new passwords -->
<template>
  <Notification
      :notification="notification"
      v-if="notification"
    ></Notification>
  <form @submit.prevent="submitNewPassword" accept-charset="utf-8">

    <div class="field">
      <h3 class="title is-3 is-spaced"><span class="outlined yellow">{{t('password_reset.title')}}</span></h3>
      <p class="subtitle is-6">{{t('password_reset.subtitle')}}</p>

      <label class="label">{{t('password_reset.password')}}</label>
      <div class="control has-icons-left">
        <input
        type="password"
        v-model="password"
        :placeholder="t('password_reset.password')"
        class="input"
        />
        <span class="icon is-small is-left">
           <font-awesome-icon icon="lock" />
        </span>
      </div>
      
    </div>

    <div class="field">
      <label class="label">{{ t('password_reset.password')}}</label>
      <div class="control has-icons-left">
        <input
        type="password"
        v-model="passwordConfirmation"
        :placeholder="t('password_reset.password_confirmation')"
        class="input"
        :class="{'is-success': confirmationMatches}"
        />
        <span class="icon is-small is-left">
           <font-awesome-icon icon="lock" />
        </span>
      </div>
    </div>

    <div class="field m-space">
      <div class="helper-links">
        <a href="#" role="button" @click.prevent="cancelResetPwd()" class="helper"
            >
            {{t("password_request.cancel")}}</a>
      </div>
    </div>

    <div class="field is-grouped is-grouped-right">
      <div class="control">
        <button class="button is-primary">
          {{t('buttons.submit')}}
        </button>
      </div>
    </div>

  </form>
</template>

<script>
import { computed, ref, reactive } from 'vue'
import LoginService from '@/services/LoginService.js'
import { useI18n } from 'vue-i18n'
import { useRouter, useRoute } from "vue-router"

export default {
  emits: ['cancel-reset-pwd'],
  setup(){
    // translations
    const { t } = useI18n()
    const router = useRouter()
    const route = useRoute()
    const password = ref(null)
    const passwordConfirmation = ref(null)

    const passwordsInputed = computed(() => password.value && passwordConfirmation.value)
    const confirmationMatches = computed(() => passwordsInputed.value && password.value == passwordConfirmation.value)
    
    const notification = reactive({
      message: null,
      type: null
    })

    // methods
    const cancelResetPwd = () => {
      router.push({
        name: 'login'
      })
    }

    const submitNewPassword = () => {
      let newPasswords = {
        password: password.value,
        passwordConfirmation: passwordConfirmation.value
      }

      LoginService.resetPassword(newPasswords, route.params.token)
        .then(response => {
          if (response.status == 200) {
            cancelResetPwd()
          }

        })
        .catch(error => {
          console.log(error)
        
        })
    }

    return { 
      t,
      password,
      passwordConfirmation,
      passwordsInputed,
      confirmationMatches,
      submitNewPassword,
      cancelResetPwd,
      notification
    }
  }
}
</script>
