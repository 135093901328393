<template>
  <div class="field has-addons ml-3">
    <p class="control">
      <input type="text" class="input is-small has-price" v-model="computedValue" @change="updateDocumentItem($event)">
    </p>
    <p class="control">
      <a class="button is-static is-small">
        {{ properties.value_price_label }}
      </a>
    </p>
  </div>
</template>

<script>
  import { inject,  computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  export default {
    props: {
      icon: {
        type: String,
        required: false,
        default: 'edit'
      },
      iconSize: {
        type: String,
        required: false,
        default: 'sm'
      },
      option: {
        type: Object,
        required: true
      },
      documentItem: {
        type: Object,
        required: false
      },
      attribute: {
        type: String,
        required: false,
        default: 'value_price'
      },
      rows: {
        type: String,
        required: false
      },
      properties: {
        type: Object
      }
    },
    setup(props, { emit }) {
      const { t } = useI18n()
      const locale = inject('dmtLocale')

      const translation = computed(() => props.documentItem[locale.value])
      
      const computedValue = computed({
        get: () => {
          return (translation.value.value_price / 100).toFixed(2)
        },
        set: (value) => {
          const priceInCents = (value * 100).toFixed(0)
          emit('update:input', props.documentItem.selectable_id, props.attribute, priceInCents)
        }
      })

      const updateDocumentItem = (ev) => {
        // because there are multiple document items, should pass which is the one being updated
        const priceInCents = (ev.target.value * 100).toFixed(0)
        emit('update:input', props.documentItem.selectable_id, props.attribute, priceInCents)
      }


      return {
        t,
        locale,
        updateDocumentItem,
        computedValue
      }
    }
  }
</script>