import router from '@/router'

const editDocument = (document)  => {
  // var customize_url = 'customize'

  // if (
  //   ['default', 'with_preview'].includes(document.display_type) ||
  //   !document.display_type
  // ) {
  //   customize_url = `/${customize_url}/${document.parent_id || document.id}`
  // } else {
  //   customize_url = `/${customize_url}-${
  //     document.display_type
  //   }/${document.parent_id || document.id}`
  // }

  router.push({ name: 'customize', params: {id: document.id} })
}

const downloadFilename = (response) => {
  var fileType = `${response.headers['content-type'].split('/')[1]}`
  var filename = `document_${+new Date()}.${fileType}`
  var disposition = response.headers['content-disposition']

  if (disposition && disposition.indexOf('inline') !== -1) {
    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    var matches = filenameRegex.exec(disposition)
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '')
    }
  }

  return filename
}

const forceFileDownload = (response) => {
  const url = window.URL.createObjectURL(new Blob([response.data]), {
    type: response.headers['content-type']
  })

  const link = document.createElement('a')
  link.href = url
  const title = downloadFilename(response)
  link.setAttribute('download', title)
  document.body.appendChild(link)
  link.click()
}

export { editDocument, forceFileDownload }