<template>
  <footer class="footer">
    <div :class="footerClasses">
      <p class="is-size-7">

        &copy; {{ $t('copyright', { fullyear: today.getFullYear() }) }}
        <router-link :to="{ name: 'privacy-and-terms' }"
        > {{ $t('privacy') }}</router-link
      >
      </p>
    </div>
  </footer>

</template>

<script>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'

export default {
  setup(){
    const today = ref(new Date())
    const router = useRouter()
    const footerClasses = computed(() => {
      const base = ['container']
      const fullWidthPages = ['customize', 'output']
      
      if (fullWidthPages.includes(router.currentRoute.value.name)){
        base.push('is-fullhd')
      } else {
        base.push('is-max-widescreen')
      }

      return base
    })

    return {
      today,
      footerClasses
    }
  }
}
</script>
