<template>
    <Notification
      v-model="notificationVisible"
      :type="notification.type"
      @dismiss="dismissNotification"
    >
      {{ notification.message }}
    </Notification>

    <form @submit.prevent="onSubmit" accept-charset="utf-8" v-if="account && !isLoading">
      <div class="columns">
        <div class="column is-1">
          <figure class="image has-hidden-input">
            <img class="is-rounded" :src="accountAvatar" style="max-width:50px;">
            <input
              type="file"
              class="hidden-avatar-input"
              id="file"
              ref="newAvatar"
              @click="record('Select File')"
              @change="handleAvatarUpload()"
            />

            <span class="upload-note">{{t('form_helpers.upload_new')}}</span>
          </figure>
          <!-- input that handles the file upload -->
        </div>
        <div class="column is-11">
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">{{t('account.login.fields.first_name')}}</label>
                <div class="control">
                  <input
                  type="text"
                  v-model="accountForm.first_name"
                  class="input"
                  />
                </div>
              
                <p class="help is-danger" v-show="!validations.first_name.valid">{{t('account.login.fields.first_name')}} {{t('form_helpers.is_invalid')}}</p>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">{{t('account.login.fields.last_name')}}</label>
                <div class="control">
                  <input
                  type="text"
                  v-model="accountForm.last_name"
                  class="input"
                  />
                </div>
              
                <!-- <p class="help is-danger">{{t('account.login.fields.last_name')}} {{t('form_helpers.is_invalid')}}</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">{{t('account.login.fields.address_line_1')}}</label>
            <div class="control">
              <input
              type="text"
              v-model="accountForm.address_line_1"
              class="input"
              />
            </div>
          
            <p class="help is-danger" v-show="!validations.address_line_1.valid">{{t('account.login.fields.address_line_1')}} {{t('form_helpers.is_invalid')}}</p>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">{{t('account.login.fields.address_line_2')}}</label>
            <div class="control">
              <input
              type="text"
              v-model="accountForm.address_line_2"
              class="input"
              />
            </div>
          
            <!-- <p class="help is-danger">{{t('account.login.fields.address_line_2')}} {{t('form_helpers.is_invalid')}}</p> -->
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">{{t('account.login.fields.postal_code')}}</label>
            <div class="control">
              <input
              type="text"
              v-model="accountForm.postal_code"
              class="input"
              />
            </div>
          
            <!-- <p class="help is-danger">{{t('account.login.fields.postal_code')}} {{t('form_helpers.is_invalid')}}</p> -->
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">{{t('account.login.fields.city')}}</label>
            <div class="control">
              <input
              type="text"
              v-model="accountForm.city"
              class="input"
              />
            </div>
          
            <!-- <p class="help is-danger">{{t('account.login.fields.city')}} {{t('form_helpers.is_invalid')}}</p> -->
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">{{t('account.login.fields.country')}}</label>
            <div class="control">
              <input
              type="text"
              v-model="accountForm.country"
              class="input"
              />
            </div>
            <!-- <p class="help is-danger">{{t('account.login.fields.country')}} {{t('form_helpers.is_invalid')}}</p> -->
          </div>
        </div>
      </div>
      
      <div class="field is-grouped is-grouped-right">
        <div class="control">
          <button class="button is-primary">
            {{t('buttons.save')}}
          </button>
        </div>
      </div>
    </form>
</template>

<script>
import UserService from '@/services/UserService.js'
import { reactive, ref, computed, watch, onMounted } from "vue"
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { textValidation, addressValidation } from '@/shared/setup/validationMethods.js'
import { 
  notificationVisible, 
  notification, 
  dismissNotification, 
  updateNotification
} from '@/shared/setup/notifications.js'

import { resolveHash, recordAction } from '@/shared/setup/analyticsMethods.js'

export default {
  setup(){
    const store = useStore()
    const isUpdating = ref(false)
    const isLoading = ref(true)
    const newAvatar = ref(null)
    const newAvatarPreview = ref(null)

    const account = computed(() => store.state.user.profile)
    
    const accountForm = reactive({
      firts_name: null,
      last_name: null,
      address_line_1: null,
      address_line_2: null,
      postal_code: null,
      city: null,
      country: null
    })

    const validations = reactive({
      first_name: {
        valid: true,
        class: null
      },
      address_line_1: {
        valid: true,
        class: null
      },
    })

    const accountAvatar = computed(() => newAvatarPreview.value || account.value.avatar_thumb_url)

    const onSubmit = async () => {
      let user = new FormData()

      user.append('first_name', accountForm.first_name)
      user.append('last_name', accountForm.last_name)
      user.append('address_line_1', accountForm.address_line_1)
      user.append('address_line_2', accountForm.address_line_2)
      user.append('postal_code', accountForm.postal_code)
      user.append('city', accountForm.city)
      user.append('country', accountForm.country)

      if (newAvatar.value.files.length) {
        user.append('avatar', newAvatar.value.files[0])
      }

      try {
        let response = await UserService.updateProfile(user)
        account.value.avatar_thumb_url = response.data.avatar_thumb_url
        
        const successNotification = {
          message: t("account.login.notifications.updated.success"),
          type: 'success'
        }

        updateNotification(successNotification, true)
        // updates store
        store.commit("saveUserProfile", response.data)

      } catch (e) {
        console.log(e)
        
        const errorNotification = {
          message: t("account.login.notifications.updated.error"),
          type: 'danger'
        }
        updateNotification(errorNotification, true)
      }
    
    }

    const assignAccountForm = () => {
      for (const [key, value] of Object.entries(account.value)){
        accountForm[key] = value
      }
    }


    const checkAccountInformation = async () => {
      if (account.value){
        isLoading.value = false
        assignAccountForm()
      } else {
        Promise.all([
          await store.dispatch('loadUserProfile'),
          store.dispatch('loadUserLogos'),
          store.dispatch('loadUserLocations'),
        ])
        .catch((e) => {
          console.log("Error checking account information", e)

          const errorNotification = {
            message: t("account.login.notifications.loading.error"),
            type: 'danger'
          }
          updateNotification(errorNotification, true)
        })
        .then(() => {
          assignAccountForm()
        })

        isLoading.value = false
      }
    }

    const handleAvatarUpload = () => {
      // validate first // missing
      newAvatarPreview.value = URL.createObjectURL(newAvatar.value.files[0])
    }

    const record = (action) => {
      const other = {
        user_id: account.value.id
      }

      const hash = resolveHash('User', 'Edit', action, null, other)
      recordAction(hash)
    }

    // form validation watchers
    watch(
      () => accountForm.first_name,
      (value) => {
        if (value) {
          textValidation(value, null, validations.first_name)
        }
        
      }
    )

    watch(
      () => accountForm.address_line_1,
      (value) => {
        if (value) {
          addressValidation(value, null, validations.address_line_1)
        }
      }
    )

    // translations
    const { t } = useI18n()

    onMounted(() => {
      record('Edit')

    })
    
    checkAccountInformation()

    return {
      t,
      store,
      notificationVisible, 
      notification, 
      dismissNotification, 
      updateNotification,
      isUpdating,
      newAvatar,
      newAvatarPreview,
      account,
      accountForm,
      accountAvatar,
      onSubmit,
      checkAccountInformation,
      isLoading,
      handleAvatarUpload,
      validations,
      record
      
    }
  },
}
</script>
