import { ref, reactive } from 'vue'

const notificationVisible = ref(false)

const notification = reactive({
  type: '',
  message: ''
})

const dismissNotification = () => {
  notificationVisible.value = false
  notification.type = ''
  notification.message = ''
}

const updateNotification = (newNotification, state) => {
  notificationVisible.value = state
  notification.type = newNotification.type
  notification.message = newNotification.message
}

const inputError = reactive({
  style: '',
  message: ''
})

const updateInputError = (style, message) => {
  inputError.style = style
  inputError.message = message
}


export { notificationVisible, notification, dismissNotification, updateNotification, inputError, updateInputError }