<template>
  <div class="">
    <Notification
      v-model="notificationVisible"
      :type="notification.type"
      @dismiss="dismissNotification"
    >
      {{ notification.message }}
    </Notification>
  </div>

  <section class="section">
    <div class="container is-fullhd is-clearfix">
      <HeaderTitle
      :title="t('customize.heading')"
      :subtitle="t('customize.subtitle')"
      :isOutlined="true"
      class="is-pulled-left"
      ></HeaderTitle>
      <div class="is-pulled-right switch-view" v-if="hasMultipleViews">
        <label for="toggle_view" class="label" :class="{active: !customView}">Default</label>
        <label class="switch-wrap">
          <input type="checkbox" name="toggle_view" v-model="customView" />
          <div class="switch"></div>
        </label>
        <label for="toggle_view" class="label" :class="{active: customView}">Custom</label>
      </div>
      
    </div>
  </section>

  <div class="has-gray-bg customize-screen">
    <section class="section">
      <div class="container is-fullhd">
        <div class="columns pb-4">
          <div class="column">
            <nav class="breadcrumb has-bullet-separator" aria-label="layout navigation" v-show="hasMultitleLayouts">
              <ul>
                <li :class="{'is-active': activeLayout.id === layout.id}" v-for="layout in layoutOptions" :key="layout.id">
                  <a @click.prevent="toggleLayout(layout)">{{ layout.name }}</a>
                </li>
              </ul>
            </nav>
          </div>
          <div class="column">
            <document-languages 
              :options="dmt.languages"
              :loadingDocument="!loaded.newLocale"
              @select="newLocale"
            />
          </div>
        </div>
        <div v-if="customView && dataElements.length > 0">
          <keep-alive>
            <component
            :is="properties.customize_component" 
            :inputs="dataElements"
            :sections="properties.section_hash"
            :key="dmtLocale"
            @update:modelValue="valueUpdate"
            @update:input="inputUpdate"
            @update:logo="updateAssociatedInput"
            @update:removeAttachment="removeAttachment"
            @update:assignContact="assignContact"
            @update:documentItem="documentItemUpdate"
            ></component>
          </keep-alive>
          
        </div>
        
        <!-- default preview + inputs -->
        <div class="columns is-variable is-8" v-else>
          <!-- preview -->
          <div class="column is-half">
            <layout-preview
              :locale="dmtLocale"
              :ts="userSelection"
              v-if="loaded && activeLayout"
              :layout="activeLayout"
            ></layout-preview>
            <div v-else>
              <p class="title is-5 mb-2">{{ t('customize.loading_preview') }}</p>
              <p class="title is-6">{{ t('customize.loading_preview_subtitle') }}</p>
              <Loading :loading="!loaded.document"></Loading>
            </div>
          </div>
          <!-- inputs -->
          <div class="column is-half is-relative" v-if="dataElements.length > 0" :class="{'saving-in-progress': saving}">
            <div :style="computedStyle" :class="{'has-scrollbar': hasScrollbar }" id="inputContainer">
              <div id="layout-inputs">
                <div v-for="input in dataElements" :key="input.id + '-' + dmtLocale">
                  <document-inputs
                    :input="input"
                    @update:modelValue="valueUpdate"
                    @update:input="inputUpdate"
                    @update:logo="updateAssociatedInput"
                    @update:removeAttachment="removeAttachment"
                    @update:assignContact="assignContact"
                    @update:documentItem="documentItemUpdate"
                    @update:gai="updateGroupAssociations"
                    v-if="visibleInputs.includes(input.id)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- toolbar -->
        <div id="customize-actions" v-if="loaded.document">
          <div class="block-icons">
            <!-- Preview box -->
            <preview-options
              v-if="dmt.output_options" 
              :options="dmt.output_options"
              :activeLayoutId="activeLayoutId"
            />
            
            <!-- Next options -->
            <div class="field p-2">
              <button class="button is-link  is-fullwidth" :class="{'is-loading': saving}" @click="saveAndOutput()">
                <span>{{ t('customize.buttons.save') }}</span>
                <span class="icon is-small">
                 <font-awesome-icon icon="chevron-circle-right"/>
                </span>
              </button>
            </div>  
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss">
@import '@/assets/stylesheets/module_specific/document.scss'
</style>

<script>
import { computed, inject, reactive, ref, provide, onBeforeUnmount, nextTick, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import HeaderTitle from '@/components/shared/HeaderTitle.vue'

import DocumentLanguages from '@/components/document/DocumentLanguages'
import PreviewOptions from '@/components/document/DocumentPreviewOptions'
import LayoutPreview from '@/components/document/preview/LayoutPreview'
import DocumentInputs from '@/components/document/update/DocumentInputs'
import DocumentService from '@/services/DocumentService.js'
import { updateDIO, updateDIwithOverride, updateDIOAttachment, removeDIOAttachment, assignContactToDIO, assignLogoToDIO } from '@/services/UpdateService.js'

import InfoRequest from '@/components/document/customize/InfoRequest.vue'
import InfoRequestWithIndustry from '@/components/document/customize/InfoRequestWithIndustry.vue'
import WPOProposal from '@/components/document/customize/WPOProposal.vue'

import { 
  notificationVisible, 
  notification, 
  dismissNotification, 
  updateNotification
} from '@/shared/setup/notifications.js'

import { groupSelectType } from '@/shared/setup/populateMethods.js'
import { inputTypes } from '@/shared/setup/inputTypeHelpers.js'
import isEqual from 'lodash/isEqual'

export default {
  components: {
    HeaderTitle,
    LayoutPreview,
    DocumentInputs,
    DocumentLanguages,
    PreviewOptions,
    InfoRequest,
    InfoRequestWithIndustry,
    WPOProposal
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    parentNotification: {
      type: String
    }
  },
  created(){
    if (this.parentNotification) {
      this.updateNotification(JSON.parse(this.parentNotification), true)
    }
  },
  setup(props){
    const { t } = useI18n()
    const settings = inject('clientGlobalSettings')
    const store = useStore()
    const router = useRouter()
    const dmt = reactive({})

    // custom view
    const properties = computed(() => dmt.properties || null)
    const hasCustomView = computed(() => properties.value && properties.value.customize_component)

    const hasMultipleViews = ref(false)
    const defaultView = computed(() => properties.value ? properties.value.default_view : 'default')
    const customView = ref(false)

    watch(properties, () => {
      customView.value = hasCustomView.value && defaultView.value == 'custom'
      hasMultipleViews.value = properties.value.custom_view_only ? properties.value.custom_view_only != 'true' : hasCustomView.value ? true : false
    })
    
    // 
    const userSelection = computed(() => store.state.currentDocument.sharedSelection)
    const imageSize = computed(() => store.state.device.defaultImgSize)
    const layoutOptions = computed(() => dmt.layouts ? dmt.layouts.map(l => ({name: l.name, id: l.id}) ) : [])
    const activeLayoutId = ref(null)
    const hasMultitleLayouts = computed(() => layoutOptions.value.length > 1)
    const activeLayout = computed(() => dmt.layouts ? dmt.layouts.find(l => l.id === activeLayoutId.value) : null)
    const dataElements = computed(() => {
      if (dmt.data_elements && dmt.data_elements.length) {
        return sortedArray(dmt.data_elements)
      } else {
         return []
      }
    })
    const assetElements = computed(() => dmt.asset_elements || [])
    const groupAssociations = computed(() => dmt.group_associations || [])
    const formulaElements = computed(() => dmt.formula_elements || [])
    const dmtLocale = computed(() => dmt.current_locale || 'en')
    const groupSelect = reactive({})
    const saving = ref(false)
    const loaded = reactive({
      document: false,
      groupSelect: false,
      newLocale: true,
    })

    const loadDocument = () => {
      store.dispatch('clearDocumentSelection')
      fetchDocument()
    }

    const sortedArray = (array) => {
      const withPosition = array.filter(e => e.properties && e.properties.ui_input_position)
      const withoutPosition = array.filter(x => !withPosition.includes(x))
      
      let sorted = []

      if (withPosition.length) {
        sorted = withPosition.sort((a,b) => {
          let fa = parseInt(a.properties.ui_input_position)
          let fb = parseInt(b.properties.ui_input_position)

          if (fa && fb) {
            return fa - fb
          }
        })
      }

      const sortedElements = [...sorted, ...withoutPosition]
      
      return sortedElements
    }

    const fetchDocument = async (localeCode, skipGroupSelect) => {
      if (localeCode) {
        loaded.newLocale = false
      }

      DocumentService.loadDocument(props.id, localeCode)
      .then(response => {
        const loadedDocument = response.data
        
        for (const [key, value] of Object.entries(loadedDocument)){
          dmt[key] = value
        }

        if (dmt.layouts && dmt.layouts.length) {
          toggleLayout(dmt.layouts[0])
        } else {
          const notification = {
            message: 'Document layouts object missing. Name: ' + dmt.name + ' ' + '(' + dmt.id + ')',
            type: 'danger'
          }

          router.push({
            name: 'dashboard',
            params: {
              parentNotification: JSON.stringify(notification)
            }
          })
        }

        loaded.document = true
        loaded.newLocale = true
        
        if (!skipGroupSelect) {
          populateGroupSelect()
        }
        
      })
      .catch((error) => {
        console.log("Error loading document: ", error)

        const notification = {
          message: 'Error encountered loading the document',
          type: 'danger'
        }

        router.push({
          name: 'dashboard',
          params: {
            parentNotification: JSON.stringify(notification)
          }
        })
      })
    }

    const visibleInputs = computed(() => {
      const previewLayoutId = activeLayout.value.preview_layouts[0].id
      
      const inputIdsForActiveLayout = dmt.layout_inputs ? dmt.layout_inputs[previewLayoutId].map(li => li.document_object_id) : []

      // filter by GAI
      if (hiddenInputs.value.length) {
        hiddenInputs.value.forEach((i) => {

          if (inputIdsForActiveLayout.includes(i)) {
            inputIdsForActiveLayout.splice(inputIdsForActiveLayout.indexOf(i), 1)
          }
          
        })
      }


      return inputIdsForActiveLayout
    })

    const hiddenInputs = computed(() => {
      const inputIds = []

      if (dataElements.value.length) {
        groupAssociations.value.forEach((ga) => {
          const gai = ga.group_association_items

          gai.forEach((gai) => {
            // parts have document_object_id. Ford does not. 
            if (gai.target_group_item_code && gai.document_object_id) {
              const triggerElement = dataElements.value.find(de => de.id == gai.document_object_id)
              const selectedDi = triggerElement && triggerElement.document_items ? Object.values(triggerElement.document_items).find(di => di.selected == true) : null

              
              if (selectedDi && selectedDi.code != gai.target_group_item_code) {
                const relationshipIds = ga.relationships.map(r => r.id)
                inputIds.push(...relationshipIds)
              } 
            } else if (gai.target_group_item_code && gai.target_group_code) {
              // accommodates FORD. Without document_object_id, searches for ga.document_item_id == de.parent_id
              // parent_id in the data element relates to the logical_grouping input
              const triggerElement = dataElements.value.find(de => de.group && de.group.code == gai.target_group_code)
              const matchingDi = triggerElement && triggerElement.document_items ? Object.values(triggerElement.document_items).find(di => di.selected == true && di.code == gai.target_group_item_code) : null
              
              // code does not match OR has matching and children are grouped separately (ford children select)
              if (!matchingDi || matchingDi && triggerElement.properties['vue3_association_components']) {
                const relationshipIds = ga.relationships.map(r => r.id)
                inputIds.push(...relationshipIds)
              }
            }
          })

        })
      }
      return inputIds
    })

    // To do: optimize method below
    const populateGroupSelect = async () => {
      // two ways to populate group select: Default or Ransack.
      const elements = dataElements.value
      const queries = []
      const requestQueue = []

      for (const i in elements) {
        const el = elements[i]
        const queryType = groupSelectType(el)
        const groupCode = el.group && el.group.code
        let groupByCat = el.properties.group_by_categories

        if (groupCode && queryType) {
          groupByCat = groupByCat == 'true' ? 'true' : 'false'
          
          let newQuery = {
            code: groupCode,
            groupByCat: groupByCat,
            filterMethod: queryType
          }

          // is equal: checks for existance
          if (queries.length == 0 || !queries.find(q => isEqual(q, newQuery))) {
            queries.push(newQuery)
          }
        }
      }

      queries.forEach((q) => {
        let request
        if (q.filterMethod == 'ransack') {
          request = DocumentService.genericRansackQuery(q.code, q.groupByCat)
        } else {
          // default
          request = DocumentService.genericAssetQuery(q.code, q.groupByCat)
        }

        requestQueue.push(request)
      })

      Promise.all(requestQueue)
      .then(responses => {
        responses.forEach(rsp => {
          const data = rsp.data
          let code
          
          if (Array.isArray(data)) {
            code = data[0].code || 'gs_code_missing'
          } else {
            code = data.code || 'code_missing'
          }
          
          groupSelect[code] = data
        })
        console.log('Populated GroupSelect:')
        console.log(groupSelect)
      })
      .catch(error => {
        // to do: what to do with errors?
        console.log('Group select error: ', error.message)
        const nft = {
          type: 'danger',
          message: 'Some groups were not loaded properly. Check log for more details.'
        }

        updateNotification(nft, true)
      })

      loaded.groupSelect = true
    }

    const toggleLayout = (newLayout) => {
      activeLayoutId.value = newLayout.id
      calculateInputHeight()
    }

    const calculateInputHeight = async () => {
      await nextTick()

      const wrapper = document.getElementById('layout-inputs')
      layoutInputsHeight.value = wrapper ? wrapper.offsetHeight : null
    }

    const layoutInputsHeight = ref()

    // methods
    // updates locale
    const newLocale = (code) => {
      fetchDocument(code, true)
    }

    // input - find which attribute holds the vale for this input
    const editableProperty = (input) => {
      const keyLookUp = input.key
      const fieldTypeLookUp = input.field_type
      const inputTypeValues = Object.values(inputTypes)

      const inputKeyRef = inputTypeValues.find(it => it.keys && it.keys.includes(keyLookUp))
      const inputTypeRef = inputTypeValues.find(i => i.fieldTypes && i.fieldTypes.includes(fieldTypeLookUp))

      // Precedence: input.key (exact) > input.field_type. Defaults to value.
      return inputKeyRef ? inputKeyRef.attribute : inputTypeRef ? inputTypeRef.attribute :"value"
    }


    // updates the data element
    const valueUpdate = (newValue, inputRef, issueCall) => {
      const attribute = editableProperty(inputRef)
      // reflects change immediately
      inputRef[attribute] = newValue

      if (issueCall) {
        if (attribute == 'attachment') {
          updateDIOAttachment(inputRef, attribute, newValue)
        } else {
          updateDIO(inputRef, attribute, newValue)
        }
      }
    }

    const documentItemUpdate = (newValue, ref) => {
      // does not issue a call to update. Only for show. Document items
      // ref = [input, diRef, attr]
      if (Array.isArray(ref) && ref.length == 3){
        const input = ref[0]
        const diRef = ref[1]
        const attr = ref[2]
        if (input.document_items[diRef][attr]) {
          input.document_items[diRef][attr] = newValue
        }
        
      }
    }

    // updates the document item value
    const inputUpdate = (inputRef, property, newValue, diRef) => {
      // console.log('input update fnc', inputRef, property, newValue, diRef)
      updateDIwithOverride(inputRef, property, newValue, diRef)
    }

    // updating an associated input. Company / Contact with logo
    const updateAssociatedInput = (contact, contactType, inputKey, logoId) => {
      const inputRef = dataElements.value.find(de => de.key == inputKey)

      // if has logo, remove.
      if (inputRef && inputRef.asset || inputRef.document_input_object.url) {
        removeDIOAttachment(inputRef, contactType, contact, logoId)
      } else {
        assignLogoToDIO(contact, logoId, inputRef)
      }
    }

    const removeAttachment = (inputRef) => {
      // console.log('remove attachment - ', inputRef)
      removeDIOAttachment(inputRef)
    }

    const assignContact = (contact, contactType, inputRef) => {
      assignContactToDIO(contact, contactType, inputRef)
    }

    const updateGroupAssociations = (triggerRef) => {
      const gai = triggerRef.group_association_items

      gai.forEach(i => {
        const element = dmt.data_elements.find(de => de.key == i.key && de.id == i.id)
        const itemIndex = element ? dmt.data_elements.indexOf(element) : null
        // find data_element and update it
        if (itemIndex) {
          dmt.data_elements.splice(itemIndex, 1, i)
        }
      })
      // should delete?
      delete triggerRef.group_association_items
    }

    // clear currentDocument.sharedSelection from Store upon first load.
    loadDocument()

    // calculate preview size and limit input length - use store
    const inputAreaHeight = computed(() => store.getters.inputContainerHeight)
    const computedStyle = computed(() => {
      // console.log(inputAreaHeight.value)
      return {
        height: inputAreaHeight.value,
        overflowY: 'scroll'
      }
    })

    const hasScrollbar = computed(() => {
      let scrollBar = true

      if (layoutInputsHeight.value) {
        const inputAreaHeightInt = inputAreaHeight.value ? parseInt(inputAreaHeight.value.replace('px', ''))  : 0

        scrollBar =  layoutInputsHeight.value > inputAreaHeightInt
      }

      return scrollBar
    })

    const saveAndOutput = () => {
      saving.value = true

      DocumentService.saveChildDocument(dmt.id, activeLayoutId.value, dmtLocale)
        .then(response => {
          const savedChild = response.data
          console.log('Child Document Saved ->', savedChild)

          router.push({
            name: 'output',
            params: {
              id: savedChild.id
            }
          })
        })
        .catch(error => {
          console.log(error)
          saving.value = false
          
          const notification = {
            type: 'danger',
            message: 'Error encountered saving your document.'
          }

          updateNotification(notification, true)
        })  
    }

    onBeforeUnmount(() => {
      store.dispatch('clearDocumentSelection')
    })

    provide('dataElements', dataElements)
    provide('assetElements', assetElements)
    provide('dmtLocale', dmtLocale)
    provide('groupSelect', groupSelect)
    provide('groupAssociations', groupAssociations)
    provide('customView', customView)
    provide('formulaElements', formulaElements)

    return {
      t,
      notificationVisible,
      notification,
      dismissNotification,
      updateNotification,
      settings,
      properties,
      customView,
      hasCustomView,
      imageSize,
      fetchDocument,
      dmt,
      dmtLocale,
      userSelection,
      loaded,
      saving,
      layoutOptions,
      hasMultitleLayouts,
      layoutInputsHeight,
      toggleLayout,
      activeLayout,
      activeLayoutId,
      dataElements,
      groupAssociations,
      visibleInputs,
      inputAreaHeight,
      computedStyle,
      hiddenInputs,
      hasScrollbar,
      hasMultipleViews,
      defaultView,
      // methods
      editableProperty,
      inputUpdate,
      valueUpdate,
      updateAssociatedInput,
      assignContact,
      documentItemUpdate,
      newLocale,
      saveAndOutput,
      removeAttachment,
      updateGroupAssociations
    }
  }
}
</script>