<template>
 <section >
    <div class="container is-max-widescreen">

      <div class="columns">
        <!-- <Sidebar
        :sidebarItems="sidebarItems"
        class="column is-3 is-sidebar-left"
        ></Sidebar> -->
        <div class="column" :class="contentClass">
          <HeaderTitle
          :title="t('dam.title')"
          :subtitle="t('dam.subtitle')"
          :isOutlined="true"
          class="mb-6"
          ></HeaderTitle>
        </div>
      </div>
    </div>
    <div class="has-gray-bg pb-6">
      <div class="container is-max-widescreen">
        <Loading :loading="loading.products"></Loading>
      </div>
    </div>
  </section>
</template>

<script>
  // require('@/assets/stylesheets/module_specific/contact.scss');

import { useI18n } from 'vue-i18n';
import HeaderTitle from "@/components/shared/HeaderTitle.vue"
// import DamSidebar from "@/components/dam/sidebar"

export default {
  name: "Digital Asset Manager",
  inject: ['clientGlobalSettings'],
  setup(){
   const { t } = useI18n()
   return { t }
  },
  components: {
   HeaderTitle,
   // DamSidebar
  },
  data(){
    return {
      contentClass: "is-offset-4",
      loading: {
        products: {
          show: true
        }
      }
    }
  },
  computed: {

  },
  methods: {

  }
}
</script>

