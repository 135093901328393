{
  "languages": {
    "en": "English",
    "fr": "French",
    "es": "Spanish"
  },
  "logout": "Log out",
  "login": "Log in",
  "support": "Support",
  "copyright": "GetDMS Inc. {fullyear}. All rights reserved.",
  "privacy": "Privacy & Terms of Use",
  "navigation": {
    "template_index": "Templates",
    "my_documents": "My Documents",
    "support": "Support",
    "login": "Login",
    "logout": "Log out",
    "published_documents": "Published Documents",
    "analytics": "Analytics",
    "user_account": "My Account",
    "contacts": "Contacts",
    "dam": "DAM",
    "more": "More"
  },
  "signed_in": {
    "success": {
      "message": "Successfully logged in. Welcome back!",
      "type": "success"
    },
    "error": {
      "message": "Could not sign in. Please try again.",
      "type": "error"
    }
  },
  "log_out": {
    "success": {
      "message": "Successfully logged out.",
      "type": "success"
    }
  },
  "password_reset": {
    "title": "New password",
    "subtitle": "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
    "success": {
      "message": "Your password was successfully updated. Now you can log in using your new password.",
      "type": "success"
    },
    "password": "Password",
    "password_confirmation": "Password Confirmation"
  },
  "password_request": {
    "title": "Reset your password",
    "subtitle": "Enter the email address associated with your account and we'll send you a link to reset your password.",
    "cancel": "Return to login",
    "success": "Please check your inbox for the reset password link."
  },
  "login_page": {
  	"login": "Login",
    "email": "E-mail",
    "password": "Password",
    "welcome": "Welcome to DMS",
    "subtitle": "Create targeted sales, marketing and operational materials in minutes, while trusting that all corporate compliance and brand standards are met.",
    "support_line": "If you do not have an account, please contact {support_email} for access.",
    "forgot_password": "Forgot your password?",
    "stay_signed_in": "Stay signed in for a week",
    "validations": {
      "invalid_email": "E-mail format is invalid."
    }
  },
  "form_helpers": {
    "upload_new": "Upload new",
    "is_invalid": "is invalid",
  },
  "buttons": {
    "login": "Continue",
    "next": "Next",
    "back": "Back",
    "reset": "Reset",
    "submit": "Submit",
    "edit": "Edit",
    "delete": "Delete",
    "view": "View",
    "remove": "Remove",
    "confirm": {
      "delete": "Are you sure you want to delete?",
      "reset": "Are you sure you want to reset?"
    },
    "confirm": "Confirm",
    "cancel": "Cancel",
    "save": "Save"
  },
  "account": {
    "title": "Account",
    "subtitle": "Manage your account details here.",
    "login": {
      "title": "Account information",
      "content_1": "Delectus postulant vis id. An vis verear maluisset. Ei tation labores pri.",
      "fields": {
        "first_name": "First name",
        "last_name": "Last name",
        "address_line_1": "Address line 1",
        "address_line_2": "Address line 2",
        "postal_code": "Postal code",
        "city": "City",
        "country": "Country",
        "avatar": "Photo"
      },
      "notifications": {
        "updated": {
          "success": "Account details updated successfully",
          "error": "Failed to update account details"
        },
        "loading": {
          "error": "Failed to load account details"
        }
      }
    },
    "logos": {
      "title": "Logos",
      "content_1": "Delectus postulant vis id. An vis verear maluisset. Ei tation labores pri."
    },
    "locations": {
      "title": "Locations",
      "content_1": "Delectus postulant vis id. An vis verear maluisset. Ei tation labores pri."
    },
    "company": {
      "title": "Company info",
      "content_1": "Delectus postulant vis id. An vis verear maluisset. Ei tation labores pri."
    },
    "metrics": {
      "title": "Metrics",
      "content_1": "Delectus postulant vis id. An vis verear maluisset. Ei tation labores pri."
    },
    "dashboards": {
      "title": "My Dashboards",
      "content_1": "Toggle between dashboards and reset demo state."
    },
    
  },
  "dashboard": {
    "default_title": "This demo has been created for",
    "default_subtitle": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "welcome": "Welcome {first_name},",
    "sub_welcome": "Where do you want to start today?",
    "message_box": {
      "title": "Lorem Ipsum",
      "message": "Delectus postulant vis id. An vis verear maluisset. Ei tation labores pri. Ex minimum propriae vis. Persius similique his id, dolore interesset cu his."
    },
    "image_path": "dashboard-img.jpg",
    "steps": {
      "title": "New to Ad Builder? Here's where to start:",
      "select": {
      	"title": "Select",
      	"content": "Click on in the menu and choose a template."
      },
      "customize": {
      	"title": "Customize",
      	"content": "Click the edit icons or the blue buttons to customize your ad"
      },
      "output": {
      	"title": "Output",
      	"content": "Export your ad as a PDF or URL, or email it directly."
      }
    },
    "loading": {
      "documents": "Loading your documents",
      "templates": "Loading recent templates"
    }
  },
  "documents": {
  	"id": "ID",
  	"name": "Name",
  	"updated_at": "Last updated",
  	"status": "Status",
    "none_yet": "You don't have any documents yet.",
    "recent": {
      "heading": "Recent Documents",
      "subtitle": "Jump right back into editing one of your recent documents.",
      "cta": "Go to my documents"
    },
    "index": {
      "heading": "My documents",
      "subtitle": "Jump right back into editing one of your recent documents"
    },
    "helpers": {
      "edit": "Continue editing",
      "output": "Output",
      "delete": "Delete document",
      "creating_new": "Creating your new document",
      "loading_published": "Loading the published document. This might take a few seconds."
    },
    "actions": {
      "select": "Select",
      "edit": "Edit"
    },
    "search": {
      "by_name": "Search by document name",
      "by_code": "Search by code",
      "sort": "Sort by",
      "by_status": "Status"
    }
  },
  "templates": {
    "recent": {
      "heading": "Recently Added Templates",
      "subtitle": "Explore our latest templates.",
      "cta": "View all templates"
    },
    "favorites": {
      "heading": "Favorite Templates",
      "subtitle": "Create one of your favorite templates.",
    },
    "actions": {
      "select": "Select",
      "sample": "Sample"
    },
    "helpers": {
      "view_sample": "View sample on another tab",
      "select_template": "Select this template",
      "favorite": "Click to favorite this template",
      "unfavorite": "Click to remove from favorites"
    },
    "index": {
      "heading": "Templates",
      "subtitle": "Explore all our templates or filter by template type.",
    },
    "search": {
      "by_name": "Search by template name",
      "by_code": "Search by code",
      "sort": "Sort by",
      "by_status": "Status"
    }
  },
  "customize": {
    "heading": "Customize",
    "subtitle": "Edit your document information below.",
    "similar": {
      "title": "Similar templates",
      "subtitle": "Easily create a new template using the data from this document"
    },
    "click_override": "Click to view and edit content",
    "layout_not_found": "We could not find the preview for this layout.",
    "no_file_selected": "No file selected yet",
    "upload_file": "Upload",
    "buttons": {
      "save": "Output"
    },
    "loading_preview": "Loading document and preview.",
    "loading_preview_subtitle": "This might take a few seconds.",
    "parts": {
      "manage": "Manage selected parts"
    }
  },
  "output": {
    "heading": "Output",
    "subtitle": "Find options to output your document below.",
    "loading_preview": "Loading output options",
    "view": "View document output",
    "next": {
      "title": "What's next?",
      "more": "If you’d like to create a new document,",
      "more_btn": "start here",
      "edit": "If you’d like to make changes",
      "edit_btn": "you can edit it here"
    },
    "buttons": {
      "back": "Edit this document",
      "publish": "Publish",
      "unpublish": "Unpublish",
      "next": "Create more"
    },
    "pdf": {
      "content_title": "Download PDF",
      "content": "Download your customized document(s) in print-ready PDF.",
      "download_all": "Download all language versions",
      "tab": "PDF"
    },
    "html": {
      "content_title": "Publish",
      "content": "Publish your customized document to easily access it online.",
      "tab": "Web",
      "published_on": "Published on",
      "expiry_date": "Expiry date"
    },
    "image": {
      "content_title": "Image",
      "content": "Download your document in image format.",
      "tab": "Image"
    },
    "linkedin": {
      "content_title": "LinkedIn: Share",
      "content": "Download or share your document on LinkedIn.",
      "tab": "LinkedIn"
    },
    "twitter": {
      "content_title": "Twitter: Share",
      "content": "Download or share your document on Twitter.",
      "tab": "Twitter"
    },
    "facebook": {
      "content_title": "Facebook: Share",
      "content": "Download or share your document on Facebook.",
      "tab": "Facebook"
    },
    "email": {
      "content_title": "Share by e-mail",
      "content": "Send your document to one or multiple recipients",
      "tab": "E-mail"
    },
  },
  "inputs": {
    "loading_options": "Loading options",
    "reload_original": "Revert to original"
  },
  "dam": {
    "title": "Digital Asset Management",
    "subtitle": "Explore all the content in your DAM.",
    "recent": {
      "heading": "Digital Asset Management",
      "subtitle": "Explore all the content in your DAM.",
      "cta": "Explore the DAM"
    },
    "parts": "Parts",
    "resources": "Brand Resources",
    "ads": "Advertisements",
    "videos": "Videos",
    "products": {
      "part_number": "Part Number",
      "part_name": "Part name",
      "price": "Price",
      "select": "Select parts"
    }
  },
  "contacts": {
    "title": "Contacts",
    "subtitle": "Manage your contacts here",
    "search": {
      "people_name": "Search by name",
      "people_email": "Search by e-mail",
      "organization_name": "Search by title",
      "organization_location": "Search by address"
    },
    "people": {
      "title": "People"
    },
    "organizations": {
      "title": "Organizations"
    },
    "buttons": {
      "back": "Back to contacts",
      "details": "Details"
    }
  },
  "single_contact": {
    "title": "",
    "subtitle": "View all the information from this contact here",
    "organizations": {
      "does_not_exist": "There aren't any organizations associated with this contact."
    },
    "locations": {
      "does_not_exist": "There aren't any locations associated with this contact."
    },
    "documents": {
      "does_not_exist": "There aren't any documents associated with this contact."
    }
  },
  "status": {
    "updated_on": "Updated on"
  },
  "helpers": {
    "customize": "Customize",
    "image_preview": "image preview",
    "remove": "Remove",
    "sort_results": "Sort Results",
    "selected": "Selected",
    "select": "Select",
    "sort": {
      "results": "Sort results",
      "az": "A-Z",
      "za": "Z-A",
      "latest": "Latest",
      "oldest": "Oldest"
    },
    "copy": "Copy",
    "copied": "Copied",
    "view": "View",
    "embed": "Embed",
    "link": "Link",
    "delete": "Delete"
  },
  "generic": {
    "icon": "Icon",
    "website": "Website",
    "email": "E-mail",
    "contact": "Contact",
    "logo": "Logo",
    "placeholder": "Image placeholder"
  },
  "preview": {
    "imageAlt": "Document preview: image",
    "preview": "Preview",
    "pdf": "PDF",
    "image": "Image"
  },
  "analytics": {
    "title": "Analytics",
    "subtitle": "Find below multiple reports",
    "templates": "Templates",
    "created": "Created",
    "published": "Published",
    "users": "Users",
    "showing_reports": "Showing reports for",
    "download_csv": "Download Reports (CSV)",
    "dashboard": {
      "title": "This week's activity",
      "subtitle": "What your team has been up to this week",
      "cta": "Go to detailed analytics"
    }
  },
  "not_found": {
    "title": "404: Not Found",
    "subtitle": "It looks like the page or record cannot be found.",
    "back": "Back to homepage"
  }
}