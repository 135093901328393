<template>
  <div class="field">
    <label class="label" v-if="label">
      {{ label }}
    </label>
    
    <article class="panel input-like is-relative" :class="{ blockIn: contactListVisible }" v-click-outside-app="resetOptions">
      <div class="panel-block">
        <p class="control has-icons-right">
          <input
            type="text"
            class="input"
            v-model="contactValue"
            :placeholder="placeholder"
            @input="search($event)"
            @focus="contactListVisible = true"
          />
          <!-- @change="evalUpdate" -->
          <span class="icon is-right">
            <font-awesome-icon icon="address-book" size="sm" v-if="searchType == 'contact'"/>
            <font-awesome-icon icon="building" size="sm" v-if="searchType == 'organization'"/>
          </span>
        </p>
      </div>
      <div class="panel-options-container" v-if="contactListVisible">
        <a role="button" class="panel-block" @click.prevent="selectOption(item)" v-for="item in dropdownOptions" :key="item.id">
          {{ itemValue(item) }}
        </a>
      </div>
    </article>
    <!-- logo offer -->
    <div class="notification" v-if="logoAvailable && logoSuggestion && hasTriggerLogo">
      <button class="delete" @click="logoSuggestion = false"></button>
      <p class="mb-3">Would you like to use the logo on file?</p>
      
      <img v-for="logo in storedSelection.logos" :key="logo.id" :src="logo.url" title="Click to select" style="max-width:180px;" class="is-clickable" @click="selectLogo(storedSelection, logo.id)">
    </div>

    <field-message v-if="inputError.message.length" :object="inputError">
      {{ inputError.message }}
    </field-message>
  </div>
</template>

<script>
import { inject, computed, ref, onBeforeMount, watch} from 'vue'
import { inputError, updateInputError } from '@/shared/setup/notifications.js'
import ContactService from '@/services/ContactService.js'
import { useStore } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  setup(props, { emit }){
    const store = useStore()
    const properties = computed(() => props.input.properties)
    const allowsOverride = computed(() => properties.value.allow_override_value && properties.value.allow_override_value == 'true' )
    const locale = inject('dmtLocale')
    const groupContentType = computed(() => {
      return props.input.group.content_type || props.input.value_array[0]
    })
    const selected = Object.values(props.input.document_items).find(di => di.selected == true)

    const contactValue = ref('')
    
    // not used for contacts
    const emitUpdate = (ev) => {
      const value = ev.target.value
      emit('update:modelValue', value)
    }

    const updateDocumentItem = (inputRef, attr, newValue) => {
      emit('update:input', inputRef, attr, newValue)
    }

    const label = computed(() => properties.value.label)
    const placeholder = computed(() => properties.value.placeholder)

    const groupSelect = inject('groupSelect')
    const dropdownOptions = ref([])

    watch(groupSelect, () => {
      dropdownOptions.value = groupSelect[props.input.group.code] ? groupSelect[props.input.group.code].items : []
      const match = selected ? dropdownOptions.value.find(opt => opt.id == selected.selectable_id) : null
      contactValue.value = match ? itemValue(match) : ''
    })

    const contactLookup = async (value) => {
      const query = {
        name_cont: value
      }

      ContactService.getContactFromRansack(props.input.group.code, query)
      .then(response => {
        dropdownOptions.value = response.data.items
      })
      .catch(error => {
        console.log(error)
      })
    }

    const itemValue = (item) => {
      return searchType.value == 'contact' ? (item.first_name + ' ' + item.last_name) : item.name
    }

    const selectOption = (item) => {
      // associates document with this Org or Contact (via DIO)
      contactValue.value = itemValue(item)
      emit('update:assignContact', item, searchType.value)
      
      const storeObject = {
        key: searchType.value,
        value: item
      }

      store.commit('saveDocumentSelection', storeObject)
      logoSuggestion.value = true

      // updates input
      emit('update:modelValue', [groupContentType.value, item.id], props.input)
      
      // clears dropdown
      resetOptions()

    }

    const contactListVisible = ref(false)
    
    // make this based on property - company name (organization), contact name
    const searchType = computed(() => properties.value.contact_search_type || 'organization')
    const hasOptions = computed(() => dropdownOptions.value && dropdownOptions.value.length)


    // Vuex store selection - contact or organization
    const storedSelection = computed(() => store.state.currentDocument.sharedSelection[searchType.value])
    const logoAvailable = computed(() => storedSelection.value && storedSelection.value.logos)
    const logoSuggestion = ref(false)

    const hasTriggerLogo = computed(() => properties.value.triggers_update_on)

    const selectLogo = (contactObject, logoId) => {
      // emits call that 1. deletes current attachment (if any) 2. Uploads new logo
      // indicate 'triggers_update_on' - in properties. Which data element should be updated
      emit('update:logo', contactObject, searchType.value, hasTriggerLogo.value, logoId )
      logoSuggestion.value = false
    }

    onBeforeMount(async () => {
      // figure out initial selection, if any (mostly for editing a previous document)
      const match = selected ? dropdownOptions.value.find(opt => opt.id == selected.selectable_id) : null
      
      if (match) {
        contactValue.value = itemValue(match)
      } else {
        const query = {
          id_eq: selected.selectable_id
        }
        const matches = await ContactService.getOrganizations(query)

        if (matches.data.length) {
          const storeObject = {
            key: searchType.value,
            value: matches.data[0]
          }
          store.commit('saveDocumentSelection', storeObject)
        }
      }
    })

    const search = debounce((ev) => {
      const q = ev.target.value
      contactLookup(q)
    }, 800)

    const resetOptions = () => {
      contactListVisible.value = false
    }

    return {
      inputError,
      updateInputError,
      locale,
      contactValue,
      label,
      placeholder,
      allowsOverride,
      emitUpdate,
      updateDocumentItem,
      hasOptions,
      dropdownOptions,
      selectOption,
      search,
      searchType,
      storedSelection,
      logoAvailable,
      logoSuggestion,
      hasTriggerLogo,
      selectLogo,
      contactListVisible,
      itemValue,
      resetOptions
    }
  },
  props: {
    input: {
      type: Object,
      required: true
    }
  }
}
</script>
