import { DateTime } from 'luxon';
export default {
  methods: {
    goToPage(direction){
      // next
      if (this.currentPage < this.pageTotal && direction == 1) {
        this.currentPage = this.currentPage + 1
        // previous
      } else if (this.currentPage <= this.pageTotal && direction == -1) {
        this.currentPage = this.currentPage - 1
      }
    },
    dataEl(key) {
      const dataEl = this.dataElements.find(de => de.key == key)

      return dataEl
    },
    assetEl(key) {
      const assetEl = this.assetElements.find(de => de.key == key)
      const apprAsset = assetEl[this.store.getters.imageUrl] // replace by the getter

      return apprAsset ? apprAsset : assetEl['normal']
    },
    valueFromCollection(collection, lookUpId, property) {
      collection = this.ts[collection]

      if (collection && lookUpId && property) {
        const match = collection.filter(colItem => colItem.id == lookUpId)[0]

        return match ? match[property] : null
      }
    },
    dioInfo(data_element, property) {
      var dioInfo

      if (property == 'image') {
        property = this.store.getters.imageUrl

        if (data_element['asset'] && data_element['asset'][property]) {
          dioInfo = data_element['asset'][property]
        
        } else if (
          data_element['document_input_object'] &&
          data_element['document_input_object'][property]
        ) {
          dioInfo = data_element['document_input_object'][property]
        } else {
          dioInfo = data_element[property]
        }
      } else {
        dioInfo = data_element['document_input_object'][property]
      }
      return dioInfo
    },
    translation_key(code, value) {
      // implement look up by key instead of item id
      const documentItem = Object.values(this.translation_keys.document_items).find(di => di.code == code)
      const property = value || 'value'

      return documentItem ? documentItem[this.locale][property] : ''
    },
    layoutProperty(property){
      return this.layout.properties[property] || null
    },
    inlineStyle(dataElementKey) {
      var el = this.dataEl(dataElementKey)
      var selectedValue = el.document_items[el.value_array[1]]
      var el_properties = selectedValue.properties
      var layout_properties = this.layout.properties

      for (const [key, value] of Object.entries(el_properties)) {
        if (value == 'true') {
          var elProp = key.split('html_format_code_').pop()
          elProp = elProp + '_hash'

          if (layout_properties[elProp]) {
            return layout_properties[elProp]['value_inline_style']
          }
        }
      }
    },
    elInlineStyle(property){
      const hash = this.layout.properties['layout_hash']

      return hash[property]['inline']

    },
    selectedImg(dataElement, allowMultiple) {
      let selectedImage
      const documentItem = Object.values(dataElement.document_items).find(di => di.selected == true)
      const imgTypes = ['image/jpeg', 'image/png']

      if (documentItem && documentItem.attachments) {
        const attachments = documentItem.attachments

        if (allowMultiple) {
          selectedImage = attachments.filter(att => imgTypes.includes(att.content_type))
        } else {
          selectedImage = attachments.find(att => imgTypes.includes(att.content_type))
        }
        
        if (!selectedImage) {
          // return missing image placeholder
          const placeholder = {
            name: 'not found',
            normal_url: ''
          }
          selectedImage = placeholder
        }
      }
      return selectedImage
    },
    imageUrl(dataElement){
      // finds selected image and returns url
      const image = this.selectedImg(dataElement)
      return image ? image[this.store.getters.imageUrl] || image['normal_url'] || image['preview_url'] : ''
    },
    imageAlt(dataElement) {
      const image = this.selectedImg(dataElement)
      return image ? image.name : "Image representation"
    },
    formatDate(date, format) {
      // let formattedDate

      if (date) {
        return DateTime.fromISO(date).toFormat(format)
      } else {
        return format
      }
    },
    diAttachmentUrl(docItem, name){
      const di = docItem
      let attachment 

      if (name) {
        attachment = di.attachments.find(a => a.name == name)
      } else {
        attachment = di.attachments.length ? di.attachments[0][this.store.getters.imageUrl] || di.attachments[0]['normal_url'] || di.attachments[0]['preview_url'] : ''
      }

      return attachment
    },
    // related to selection
    selectedImage(dataElement, specificSize, returnURL) {
      // should assume return URL to be true (tbc)
      let selectedImage
      const documentItem = Object.values(dataElement.document_items).find(di => di.selected == true)
      const imgTypes = ['image/jpeg', 'image/png']

      if (documentItem && documentItem.attachments) {
        var attachments = documentItem.attachments

        selectedImage = attachments.find(att =>
          imgTypes.includes(att.content_type)
        )
        
        if (selectedImage) {
          
          if (specificSize) {
            selectedImage = selectedImage[`${specificSize}_url`]
          } else {
            selectedImage = selectedImage[this.store.getters.imageUrl]
          }
        } else {
          // return missing image placeholder
          const placeholder = {
            name: 'not found',
            normal_url: ''
          }

          selectedImage = !returnURL ? placeholder : placeholder.normal_url
        }
      }

      return selectedImage
    },
    selectedValue(dataElement, property) {
      let selectedVal
      // const value_array = dataElement ? dataElement.value_array : []
      const selectedItem = dataElement ? Object.values(dataElement.document_items).find(di => di.selected == true) : null
      
      if (selectedItem) {
        const translation = selectedItem[this.locale]

        if (property == 'value_price') {
          selectedVal = translation[property] ? (translation[property] / 100).toFixed(2) : 0
          selectedVal = `${dataElement.value_price_money_symbol ||
            '$'} ${selectedVal}`
        } else {
          selectedVal = translation[property]
        }
      } else {
        selectedVal = ''
      }

      return selectedVal
    },
    selectedDocumentItems(dataElement) {
      let selected = dataElement && dataElement.document_items ? Object.values(dataElement.document_items).filter(di => di.selected == true) : []

      return selected
    },
    selectedValueDI(documentItem, property) {
      var selectedVal

      if (documentItem) {
        selectedVal = documentItem[this.locale][property]

        if (property == 'value_price') {
          selectedVal = selectedVal ? (selectedVal / 100).toFixed(2) : 0
          selectedVal = `${documentItem.value_price_money_symbol ||
            '$'} ${selectedVal}`
        }
      } else {
        selectedVal = ''
      }
      return selectedVal || ''
    },
    selectedValueWithoutLocale(dataElement, property) {
      var selectedVal
      var value_array = dataElement.value_array
      if (value_array && dataElement.document_items[value_array[1]]) {
        selectedVal = dataElement.document_items[value_array[1]][property]

        if (property == 'value_price') {
          selectedVal = (selectedVal / 100).toFixed(2)
        }
      } else {
        selectedVal = ''
      }
      return selectedVal || ''
    },
    selectedValueFromGroup(dataElement, property) {
      var value_array_selected = dataElement.value_array[1]
      var groupSelect = dataElement.group.code

      var selectedObject = this.group_select[groupSelect]['items'].find(
        obj => obj.id == value_array_selected
      )

      return selectedObject[property]
    },
    locationFooterImg(dataEl, location) {
      const footers = location.image_footers

      if (footers) {
        const matchingFooter = footers.find(f => dataEl.value == f.footer_value)

        return matchingFooter ? matchingFooter[this.store.getters.imageUrl] || matchingFooter.url : ""
      }
    }
  }
}

// refactor methods that rely on value_array 1 to rely on document_items "selected: true" instead