<template>
  <div>
    <overlay :showLoading="false" :forceShow="true" />
    <div class="drawer box" :class="[direction, size]">
      <button class="delete" @click="closeDrawer"></button>
      <div class="drawer-header py-3">
        <h2 class="title is-5"><slot></slot></h2>
      </div>
      <div>
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">{{ t('dam.products.part_number') }}</label>
              <div class="control">
                <input class="input" type="text" v-model="filter.part_number_cont" @change="filterProducts">
              </div>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <label class="label">{{ t('dam.products.part_name') }}</label>
              <div class="control">
                <input class="input" type="text" v-model="filter.globalizations_translations_value_cont" @change="filterProducts">
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="select is-rounded">
              <select v-model="filter.s" @change="filterProducts">
                <option value="">- {{ t('helpers.sort.results') }} -</option>
                <option value="globalization_translations_value+desc">{{ t('helpers.sort.za') }}</option>
                <option value="globalization_translations_value+asc">{{ t('helpers.sort.az') }}</option>
                <option value="updated_at+desc">{{ t('helpers.sort.latest') }}</option>
                <option value="updated_at+asc">{{ t('helpers.sort.oldest') }}</option>
              </select>
            </div>
          </div>
          <div class="column">
            <!-- reset button -->
            <button class="button" @click="resetSearch()">
              {{ t('buttons.reset') }}
            </button>
          </div>
        </div>
      </div>
      <div class="result-container py-4">
        <Loading :loading="items.length == 0" v-if="items.length == 0"></Loading>
        <div v-else>
          <div class="columns">
            <div class="column">
              <p class="title is-6 pt-3">Total results: {{ pagination.totalCount }}</p>
            </div>
            <div class="column is-narrow">
              <p class="title is-6 pt-3" :class="{'has-text-danger': limitReached }">{{ selectedCount }} selected</p>
            </div>
          </div>

          <product-media 
          v-for="item in items" 
          :key="item.id" 
          :item="item"
          :selection="selection" 
          @update:selection="updateSelection"
          />

          <div class="pt-3">
            <v-pagination
              v-show="pagination.totalPages > 1"
              v-model="pagination.currentPage"
              :pages="pagination.totalPages"
              :range-size="1"
              active-color="#28569A"
              @update:modelValue="fetchPage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  require('@/assets/stylesheets/module_specific/pagination.scss')
  import { reactive, inject, ref, computed } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useStore } from 'vuex'
  import { default as ProductService } from '@/services/DamService.js'
  import ProductMedia from '@/components/dam/ProductMedia.vue'
  import VPagination from '@hennge/vue3-pagination'

  export default {
    components: {
      ProductMedia,
      VPagination
    },
    props: {
      direction: {
        type: String,
        required: false,
        default: 'rtl'
      },
      groupCode: {
        type: String,
        required: true
      },
      selection: {
        type: Array,
        required: true
      },
      size: {
        type: String,
        default: 'regular'
      }
    },
    emits: ['close', 'update:selection'],
    setup(props, {emit}) {
      const store = useStore()
      const locale = inject('dmtLocale')
      const perPage = ref(30)
      const { t } = useI18n()
      const items = ref([])
      const maxSelection = inject('selectionLimit')
      const contentType = inject('contentType')

      const selectionCount = computed(() => {
        const filteredSelection = props.selection.filter(s => s !== contentType)

        return filteredSelection.length
      })

      const limitReached = computed(() => selectionCount.value == maxSelection)

      const selectedCount = computed(() => selectionCount.value + '/' + maxSelection)

      const filter = reactive({
        part_number_cont: '',
        globalizations_translations_value_cont: '',
        s: ''
      })
      
      const pagination = reactive({
        currentPage: null,
        totalPages: null
      })

      const resolvePagination = (header) => {
        pagination.currentPage = header.current_page
        pagination.totalPages = header.total_pages
        pagination.totalCount = header.total_count
      }

      const getProducts = async (page) => {
        ProductService.ransackQuery(locale, page, perPage.value, props.groupCode, filter)
        .then(response => {
          items.value = response.data.items
          resolvePagination(JSON.parse(response.headers['x-pagination']))

          // experiment with Vuex - save first collection results
          const vuexCollection = response.data
          vuexCollection.pagination = JSON.parse(response.headers['x-pagination'])
          store.commit('saveCollection', vuexCollection)
        })
        .catch(error => { console.log(error) })
      }

      const fetchPage = (page) => {
        getProducts(page)
      }

      const filterProducts = () => {
        items.value = []
        getProducts(1)
      }

      const resetSearch = () => {
        const storedCollection = store.state.collections[props.groupCode]

        if (storedCollection && storedCollection.pagination) {
          items.value = storedCollection.items
          resolvePagination(storedCollection.pagination)

          filter.part_number_cont = ''
          filter.globalizations_translations_value_cont = ''
        } else {
          getProducts(1)
        }
      }

      const updateSelection = (item) => {
        // adds or removes product item (from collection, full info)
        emit('update:selection', item)
      }

      const closeDrawer = () => {
        emit('close')
      }

      getProducts()

      return {
        t,
        filter,
        perPage,
        filterProducts,
        fetchPage,
        pagination,
        items,
        resetSearch,
        closeDrawer,
        updateSelection,
        selectedCount,
        limitReached
      }
    }
  }
</script>