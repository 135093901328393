import { securedAxiosInstance } from '@/axios/index.js'
import { plainAxiosInstance } from '@/axios/index.js'
import { resolveQueryUrl } from '@/axios/requestMethods.js'

export default {
  getDocuments(query, limit, page) {
    var baseUrl = "/documents.json"
    const requestUrl = resolveQueryUrl(baseUrl, query, limit, page)
    
    return securedAxiosInstance.get(requestUrl)
  },
  myLatestDocuments(limit){
    return securedAxiosInstance.get(`/documents.json?limit_value=${limit}`)
  },
  myPublishedDocuments(page) {
    return securedAxiosInstance.get(`/published_documents.json?page=${page}`)
  },
  loadDocument(id, locale) {
    let route = `/documents/${id}.json`

    if (locale && locale !== 'en') {
      route = `/documents/${id}.json?locale=${locale}`
    }

    return securedAxiosInstance.get(route)
  },
  loadPreview(doc_id, layout_id, locale) {
    return plainAxiosInstance.get(
      `/documents/${doc_id}/show_html/${layout_id}.json?locale=${locale}`
    )
  },
  updateDocument(id, document) {
    return securedAxiosInstance.patch('/update_document/' + id, {
      document
    })
  },
  getOriginalDocumentItem(id, locale) {
    return securedAxiosInstance.get(`/reset_document_id/${id}?locale=${locale}`)
  },
  updateDIO(id, document_input_object) {
    return securedAxiosInstance.patch(
      'update_document_input_object/' + id,
      document_input_object
    )
  },
  updateDIOwithOverride(id, document_input_object, overrides, price_overrides) {
    return securedAxiosInstance.patch(`update_document_input_object/${id}`, {
      document_input_object,
      overrides,
      price_overrides
    })
  },
  updateDIwithOverride(
    id,
    overrides,
    locale
  ) {
    return securedAxiosInstance.patch(`update_document_input_object/${id}`, Object.assign(overrides, locale))
  },
  deleteDocument(id) {
    return securedAxiosInstance.delete(`delete_document/${id}.json`)
  },
  genericAssetQuery(url, includeCategory) {
    // string and boolean
    var callUrl = url

    if (includeCategory == 'true') {
      callUrl = `${url}?include_categories=true`
    }

    return securedAxiosInstance.get(`/group_select/${callUrl}`)
  },
  genericRansackQuery(url, includeCategory) {
    var base_url = `/group_select_query/${url}.json`

    if (includeCategory == 'true') {
      base_url = `${base_url}?include_categories=true`
    }

    return securedAxiosInstance.get(base_url)
  },
  publishDocument(id, date) {
    let baseUrl = `documents/${id}/publish.json`

    if (date) {
      baseUrl = baseUrl + '?expiration_date=' + date
    }
    return securedAxiosInstance.get(baseUrl)
  },
  unpublishDocument(id) {
    return securedAxiosInstance.get(`documents/${id}/unpublish.json`)
  },
  saveChildDocument(docId, layoutId, locale) {
    return securedAxiosInstance.get(
      `documents/${docId}/save/${layoutId}?locale=${locale}&format=json`
    )
  },
  // need refactoring below
  deleteDIOAttachment(dataElement) {
    const base_url = `/delete_attachment_document_input_object/${dataElement.document_input_object.id}`

    return securedAxiosInstance.patch(base_url)
  },
  assignContact(source_type, source, dataElement) {
    const base_url = `/update_document_input_object/${dataElement.document_input_object.id}`
    const attr = {}

    if (source_type && source) {
      attr[`${source_type}_id`] = source.id
    }

    return securedAxiosInstance.patch(base_url, attr)
  },
  assignLogo(source, logoId, dataElement) {
    const dioId = dataElement.document_input_object.id
    const base_url = '/update_document_input_object/' + dioId
    const logoUrl = logoId ? source.logos.find(i => i.id == logoId)['url'] : null
    const document_input_object = {}

    if (source && logoUrl) {
      // document_input_object.id = dioId
      // document_input_object.logo_id = logoId
      document_input_object.attachment_url = logoUrl
    }

    return securedAxiosInstance.patch(
      base_url,
      { document_input_object: document_input_object}
    )

    // return securedAxiosInstance.patch(base_url, attr)
  },
  getImagePreview(doc_id, layout_id, locale){
    const base_url = `/documents/${doc_id}/show_output_image/${layout_id}.html?locale=${locale}`

    return securedAxiosInstance.get(base_url, { responseType: 'blob' })
  },
  getHTMLCode(doc_id, layout_id, locale) {
    const url = `/documents/${doc_id}/show/${layout_id}.html?locale=${locale}`
    return securedAxiosInstance.get(url)
  },
  requestFile(doc_id, layout_id, locale) {
    const url = `/documents/${doc_id}/show_output_image_download/${layout_id}.html?locale=${locale}`
    return securedAxiosInstance.get(url, { responseType: 'blob' })
  },
  requestZip(docId){
    const requestUrl = 'documents/' + docId + '/generate_pdf_zip.zip'
    return securedAxiosInstance.get(requestUrl, { responseType: 'blob' })
  }
}
