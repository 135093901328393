<template>
  <div class="control" v-if="computedType === 'select'" :class="{'has-icons-left': icon, 'is-loading': loading}">
    <div class="select is-fullwidth">
      <select
        v-model="computedValue"
        :name="name"
        :id="id"
        :class="inputElClass">
        <option value="" v-if="placeholder">{{ placeholder }}</option>
        <option v-for="option in options" :key="option.id ?? option.value" :value="option.id ?? option.value">{{ option.label ?? option.value }}</option>
      </select>
    </div>
    <span class="icon is-small is-left" v-if="icon">
      <i class="fas fa-user"></i>
    </span>
  </div>

  <div class="control" v-else-if="computedType === 'textarea'">
    <textarea 
      class="textarea"
      v-model="computedValue"
      :class="inputElClass"
      :name="name"
      :id="id"
      :placeholder="placeholder"
      :required="required"></textarea>
  </div>

  <datepicker
    v-else-if="computedType === 'datepicker'"
    v-model="computedValue"
    :inputFormat="format"
    :class="inputElClass"
    :name="name"
    :id="id"
    :placeholder="placeholder"
    :required="required"
  />

  <p class="control" :class="{'has-icons-left': icon}" v-else>
    <input
      v-model="computedValue"
      :name="name"
      :autocomplete="autocomplete"
      :required="required"
      :id="id"
      :placeholder="placeholder"
      :type="computedType"
      :class="inputElClass"
    >

    <span class="icon is-small is-left" v-if="icon">
      <i class="fas fa-user"></i>
    </span>
  </p>
</template>

<script>
import { computed, ref } from 'vue'
import Datepicker from 'vue3-datepicker'

export default {
  name: 'Control',
  components: {
    Datepicker
  },
  props: {
    name: String,
    id: String,
    required: Boolean,
    autocomplete: String,
    placeholder: String,
    icon: String,
    options: Array,
    type: {
      type: String,
      default: 'text'
    },
    loading: Boolean,
    modelValue: {
      type: [String, Number, Boolean, Array, Object],
      default: ''
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const format = ref('MM/dd/yyyy')
    const computedValue = computed({
      get: () => {
        if (props.type === 'datepicker') {
          const ymd = props.modelValue.split('-')
          return new Date(ymd[0], (parseInt(ymd[1]) - 1), ymd[2])
        } else {
          return props.modelValue
        }
      },
      set: value => {
        let formattedValue
        if (props.type === 'datepicker') {
          formattedValue = value.toISOString().split("T")[0]
          console.log(formattedValue)
        } else {
          formattedValue = value
        }
        emit('update:modelValue', formattedValue)
      }
    })

    const inputElClass = computed(() => {
      const base = [
        'input',
        computedType.value === 'textarea' ? 'h-24' : 'h-12'
      ]

      if (props.icon) {
        base.push('pl-10')
      }

      return base
    })

    const computedType = computed(() => props.options ? 'select' : props.type)

    // const controlIconH = computed(() => props.type === 'textarea' ? 'h-full' : 'h-12')

    return {
      computedValue,
      inputElClass,
      computedType,
      format
    }
  }
}
</script>
