<template>
  <div class="field">
    <label class="label" v-if="label">
      {{ label }}
    </label>

    <input
      type="text"
      class="input"
      v-model="computedValue"
      :placeholder="placeholder"
      @change="emitUpdate($event)"
    />
    <field-message v-if="inputError.message.length" :object="inputError">
      {{ inputError.message }}
    </field-message>
  </div>
</template>

<script>
import { inject, computed } from 'vue'
import { inputError, updateInputError } from '@/shared/setup/notifications.js'
export default {
  setup(props, { emit }){
    const locale = inject('dmtLocale')
    const computedValue = computed({
      get: () => {
        return props.input.value
      },
      set: value => {
        emit('update:immediate', value)
      }
    })
    const emitUpdate = (ev) => {
      const value = ev.target.value
      emit('update:modelValue', value)
    }

    const label = computed(() => props.input.properties.label)
    const placeholder = computed(() => props.input.properties.placeholder)

    return {
      inputError,
      updateInputError,
      locale,
      computedValue,
      label,
      placeholder,
      emitUpdate
    }
  },
  props: {
    input: {
      type: Object,
      required: true
    },
    // compModelVal: {
    //   type: [String, Number, Boolean, Array, Object],
    //   default: ''
    // }
    // modelValue: {
    //   type: [String, Number, Boolean, Array, Object],
    //   default: ''
    // }
  }
}
</script>
