function emailValidation(value, validationStatusObject){
  const emailRegex = /(.+)@(.+){2,}\.(.+){2,}/

  if (value && value.length > 4) {
    validationStatusObject.valid = emailRegex.test(value)

    if (emailRegex.test(value)) {
      validationStatusObject.class = 'is-success'
    } else {
      validationStatusObject.class = 'is-danger'
    }

  } else {
    validationStatusObject.valid = null
  }
}

function textValidation(value, minLength, validationStatusObject){
  const regex = /([A-Z])\w+/

  if (minLength) {
    if (value.length > minLength && value.match(regex)) {
      validationStatusObject.valid = true
      validationStatusObject.class = 'is-success'
    } else {
      validationStatusObject.valid = false
      validationStatusObject.class = 'is-danger'
    }
  
  } else {

    if (value.match(regex)) {
      validationStatusObject.valid = true
      validationStatusObject.class = 'is-success'
    } else {
      validationStatusObject.valid = false
      validationStatusObject.class = 'is-danger'
    }
  }
}

function addressValidation(value, minLength, validationStatusObject){
  const regex = /([A-Z])\w+/;

  if (minLength) {
    if (value.length > minLength && value.match(regex)) {
      validationStatusObject.valid = true
      validationStatusObject.class = 'is-success'
    } else {
      validationStatusObject.valid = false
      validationStatusObject.class = 'is-danger'
    }
  
  } else {

    if (value.match(regex)) {
      validationStatusObject.valid = true
      validationStatusObject.class = 'is-success'
    } else {
      validationStatusObject.valid = false
      validationStatusObject.class = 'is-danger'
    }
  }
}

export { emailValidation, textValidation, addressValidation }
