<template>
  <div>
    <div class="card location-card">
      <div class="card-content">
        <div class="content">
          <p class="title is-5 has-text-weight-bold">{{ location.name }}</p>
          <div class="icon-text mb-1">
            <span class="icon is-small">
              <img :src="require(`@/assets/img/icons/location-point.png`)" />
            </span>
            <span class="pl-1">
              Address
            </span>
          </div>

          <div class="p-child-0 mb-4 address-info-long">
            <p>{{formattedAddress}}</p>
            <p>{{formattedCPC}}</p>
          </div>

          <div class="icon-text mb-1" v-if="includeContact">
            <span class="icon is-small">
              <img :src="require(`@/assets/img/icons/inbox.png`)" />
            </span>
            <span class="pl-1">
              Contact
            </span>
          </div>

          <div class="p-child-0" v-if="includeContact">
            <p>{{location.contact_name}}</p>
            <p>{{location.contact_phone}}</p>
            <p>{{location.website}}</p>
            <p>{{location.email}}</p>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'

export default {
  props: {
    location: {
      type: [Object],
      required: true
    },
    includeContact: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup(props){
    const isOpen = ref(false)
    
    const formattedAddress = computed(() => [props.location.address_line_1, props.location.address_line_2].filter(Boolean).join(', '))

    const formattedCPC = computed(() => [props.location.city, props.location.postal_code, props.location.country].filter(Boolean).join(', '))

    return {
      isOpen,
      formattedAddress,
      formattedCPC
    }
  }
  
}
</script>