<template>
  <div class="lds-ellipsis" :class="{hidden: !isLoading}">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
require('@/assets/stylesheets/module_specific/loading.scss');
import { computed } from 'vue'

export default {
  components: {},
  props: ['loading'],
  setup(props){
    const isLoading = computed(() => props.loading.show ? props.loading.show : props.loading)
    
    return {
      isLoading
    }
  }
}
</script>
