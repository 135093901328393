import { securedAxiosInstance } from '@/axios/index.js'
import { resolveQueryUrl } from '@/axios/requestMethods.js'

export default {
  ransackQuery(locale, page, limit, url, query) {
    let baseUrl = `/group_select_query/${url}`
    let requestUrl = resolveQueryUrl(baseUrl, query, limit, page)

    // var base_url = `/group_select_query/${url}`

    // if (locale) {
    //   base_url =
    //     base_url + `${base_url.includes('?') ? '&' : '?'}locale=${locale}`
    // }

    // if (limit) {
    //   base_url =
    //     base_url + `${base_url.includes('?') ? '&' : '?'}limit_value=${limit}`
    // }

    // if (page) {
    //   base_url = base_url + `${base_url.includes('?') ? '&' : '?'}page=${page}`
    // }

    // if (query) {
    //   for (const [key, value] of Object.entries(query)) {
    //     if (value && value.length > 0) {
    //       if (
    //         [
    //           'brands_id_in',
    //           'markets_id_in',
    //           'categories_id_in',
    //           'categories_id_no_in',
    //           'globalizations_translations_locale_in'
    //         ].includes(key)
    //       ) {
    //         if (
    //           key == 'globalizations_translations_locale_in' &&
    //           query.globalizations_translations_value_cont
    //         ) {
    //           for (var i in value) {
    //             base_url =
    //               base_url +
    //               `${base_url.includes('?') ? '&' : '?'}q[${key}][]=${value[i]}`
    //           }
    //         } else {
    //           for (var x in value) {
    //             base_url =
    //               base_url +
    //               `${base_url.includes('?') ? '&' : '?'}q[${key}][]=${value[x]}`
    //           }
    //         }
    //       } else {
    //         base_url =
    //           base_url +
    //           `${base_url.includes('?') ? '&' : '?'}q[${key}]=${value}`
    //       }
    //     } else if (value && key == 'id_eq') {
    //       base_url =
    //         base_url + `${base_url.includes('?') ? '&' : '?'}q[${key}]=${value}`
    //     }
    //   }
    // }

    // console.log(base_url)

    return securedAxiosInstance.get(requestUrl)
  },
  getInputSuppliers(group_code, data_element_id) {
    var base_url = `group_select/${group_code}.json`
    return securedAxiosInstance.get(
      `${base_url}?source_document_object_id=${data_element_id}`
    )
  },
}