<template>
  <nav class="navbar" role="navigation" aria-label="main navigation" id="mainNavigation" :class="routeParam">
    <div :class="navbarClasses">
      <div class="navbar-brand">
        <router-link
          class="navbar-item"
          :to="{ name: 'dashboard' }"
          v-if="isAuth"
          ><img :src="logoImage"
        /></router-link>
        <router-link class="navbar-item" :to="{ name: 'login' }" v-else
          ><img :src="logoImage"
        /></router-link>

        <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" class="navbar-menu">

        <div class="navbar-end" v-if="isAuth">

          <router-link
            v-for="item in settings.navBar.items" 
            :key="item.name"
            class="navbar-item" 
            :to="{ name: item.route }"
            
          >
            {{ t(item.name) }}
          </router-link>

          <div class="navbar-item has-dropdown is-hoverable" v-for="dd in settings.navBar.dropdowns" :key="dd.title">
            <a class="navbar-link is-arrowless">
              {{ t(dd.title) }}
            </a>

            <div class="navbar-dropdown">
              <router-link class="navbar-item" v-for="ddItem in dd.items" :to="{ name: ddItem.route }" :key="ddItem.name" @click="$event.target.blur()">
                {{ t(ddItem.name) }}
              </router-link>
            </div>
          </div>

          <a 
            class="navbar-item" 
            href="#">
            {{t('support')}}
          </a>
          <a 
            class="navbar-item" 
            @click.stop="logOut()"
            v-if="isAuth">
            {{t('logout')}}
          </a>
        </div>

      </div>
    </div>
  </nav>
</template>

<script>
import LoginService from '@/services/LoginService.js'
import { useI18n } from 'vue-i18n'
import { computed, inject } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  setup(){
    const { t } = useI18n()
    const store = useStore()
    const router = useRouter()
    const brandParam = computed(() => router.currentRoute.value.query.brand)
    const routeParam = computed(() => router.currentRoute.value.name)

    const isAuth = computed(() => store.state.session.isAuth)
    const settings = inject('clientGlobalSettings')
    const imageSize = computed(() => store.getters.imageUrl)
    
    const logoImage = computed(() => {
      const brandedLogo = settings.navBar.brandLogo.brands[brandParam.value]
      const defaultLogo = brandedLogo ? require(`@/assets/img/brands/${brandedLogo}`) : require(`@/assets/img/${settings.navBar.brandLogo.file}`)


      return store.getters.siteLogoItem ? store.getters.siteLogoItem.attachments[0][imageSize.value] : defaultLogo
    })


    const addEventListeners = () => {
      document.addEventListener('DOMContentLoaded', () => {

        // Get all "navbar-burger" elements
        const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

        // Check if there are any navbar burgers
        if ($navbarBurgers.length > 0) {

          // Add a click event on each of them
          $navbarBurgers.forEach( el => {
            el.addEventListener('click', () => {

              // Get the target from the "data-target" attribute
              const target = el.dataset.target;
              const $target = document.getElementById(target);

              // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
              el.classList.toggle('is-active');
              $target.classList.toggle('is-active');

            });
          });
        }

      });
    }

    const updateLocale = (newLocale) => {
      store.commit('updateUILocale', newLocale)
    }

    const logOut = () => {
      // make category boxes visible
      store.commit('toggleLandingMods', true)
      
      LoginService.logout()
        .then(response => signoutSuccess(response))
        .catch(error => signoutFailed(error))
    }

    const signoutSuccess = (response) => {
      if (response.status == 200) {
        goToLogin()
      }
    }
    const signoutFailed = (error) => {
      console.log(error)
      goToLogin()
    }
    const goToLogin = () => {
      store.commit('unauthenticate')
      store.commit('clearUserInformation')

      const successNotification = {
        type: 'success',
        message: t("log_out.success.message")
      }

      router.push({
        name: 'login',
        params: {
          parentNotification: JSON.stringify(successNotification)
        }
      })
    }

    const navbarClasses = computed(() => {
      const base = ['container']
      const fullWidthPages = ['customize', 'output']
      
      if (fullWidthPages.includes(router.currentRoute.value.name)){
        base.push('is-fullhd')
      } else {
        base.push('is-max-widescreen')
      }

      return base
    })

    addEventListeners()

    return { 
      t,
      isAuth,
      settings,
      logoImage,
      addEventListeners,
      updateLocale,
      logOut,
      signoutSuccess,
      signoutFailed,
      goToLogin,
      navbarClasses,
      routeParam
    }
  }
}
</script>
