<template>
	<div>
		<h1 class="title" :class="[titleClass ? titleClass : 'is-2']">
			<span :class="{outlined: isOutlined}">{{ title }}</span>
		</h1>
		<p class="subtitle" :class="[subtitleClass ? subtitleClass : 'is-6']">
			{{subtitle}}
		</p>
	</div>
</template>

<script>
import { computed } from 'vue'

export default {
	props: {
		title: {
			type: String,
			required: true
		},
		isOutlined: {
			type: Boolean,
			required: false
		},
		subtitle: {
			type: String,
			required: false
		},
		anchorTitle: {
			type: String,
			required: false
		},
		anchor: {
			type: String,
			required: false
		},
		anchorIcon: {
			type: String,
			required: false
		},
		classes: {
			type: Array,
			retuired: false,
		}
	},
	setup(props){
		const titleClass = computed(() => props.classes ? props.classes[0] : null) 
		const subtitleClass = computed(() => props.classes ? props.classes[1] : null)

		return {
			titleClass,
			subtitleClass
		}
	}
	
}
</script>