<template>
  <div class="field">
    <p>Input not found. Please report this bug.</p>
    <p>Missing: {{input.key}} ({{input.field_type}})</p>
  </div>
</template>

<script>

export default {
  props: {
    input: {
      type: Object,
      required: true
    }
  }
}
</script>
