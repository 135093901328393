<template>
  <div class="is-right pt-2 buttons">
    <button class="is-small is-white button" v-if="!revertRequested" @click="revertRequested = true">
      <span class="icon ">
        <font-awesome-icon icon="redo" />
      </span>
      <span>{{ t('inputs.reload_original') }}</span>
    </button>
    <!-- confirmation -->
    <div v-if="revertRequested">
      <span class="pr-2">Are you sure?</span>
      <button class="is-small button">
        <span class="icon ">
          <font-awesome-icon icon="times" />
        </span>
        <span @click="revertRequested = false">{{ t('buttons.cancel') }}</span>
      </button>
      <button class="is-small is-success button">
        <span class="icon ">
          <font-awesome-icon icon="check" />
        </span>
        <span @click="confirmRevert()">{{ t('buttons.confirm') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  emits: ['revert'],
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const revertRequested = ref(false)
    const confirmRevert = () => {
      revertRequested.value = false
      emit('revert', props.id)
    }

    return {
      t,
      revertRequested,
      confirmRevert
    }
  }
}
</script>
