import router from '@/router'
import store from '@/store'
import { default as ProductService } from '@/services/DamService.js'
import UserService from '@/services/UserService.js'

const hasGroupSelect = (el, excludeFieldtypes, excludeObjTypes) => {
  if (el.group && el.group.filter_method) {
    if (
      el.group.filter_method != 'ransack' &&
      !excludeFieldtypes.includes(el.field_type) &&
      !excludeObjTypes.includes(el.object_type)
    ) {
      return true
    }
  } else if (
    el.group &&
    !excludeFieldtypes.includes(el.field_type) &&
    !excludeObjTypes.includes(el.object_type)
  ) {
    return true
  }
}

// substitute the above for:
const groupSelectType = (el) => {
  const excludeObjTypes = ['translation_asset', 'translation_key']
  const excludeFieldtypes = ['logical_grouping']
  const filterMethod = el.group && el.group.filter_method ? el.group.filter_method : null
  const isVetted = !excludeFieldtypes.includes(el.field_type) && !excludeObjTypes.includes(el.object_type)
  let gsType
  if (isVetted) {
    gsType = filterMethod || 'default'
  } else {
    gsType = undefined
  }
  return gsType
}

// load user information
const asyncLoadBeforeReroute = async (csrf) => {
  // dispatch actions to get user info
  Promise.all([
    await store.dispatch('loadUserProfile'),
    await store.dispatch('loadPreferredDashboard'),
    store.dispatch('loadUserLogos'),
    store.dispatch('loadUserLocations'),
  ])
  .catch(() => {
    store.commit('unauthenticate')
    store.commit('clearUserInformation')
  })
  .then(() => {
    if (csrf) {
      store.commit('authenticate', csrf)
      
      const redirectTo = store.state.session.lastVisitedRoute ? store.state.session.lastVisitedRoute : null

      if (redirectTo && ['/', '/login'].includes(redirectTo.path)) {
        router.push({
          name: 'dashboard'
        })
      } else if (redirectTo)  {
        // store.commit('clearLastVisit')
        const params = redirectTo.params

        router.push({
          name: redirectTo.name,
          params: params
        })
      }
    }      
  })
}

const findLocation = (inputVal, locations) => {
  const locationMatch = locations.find(loc => {
    const possibleValues = [...loc.image_footers.map(img => img.footer_value), loc.location_value]

    if (possibleValues.includes(inputVal)) {
      return loc
    }
  })

  return locationMatch
}

// resolve functions

const triggerLocationSelect = (el) => {
  const location = findLocation(el.value, store.state.user.locations)
  
  if (location) {
    saveSelection('selectedLocation', location)
  } else {
    
    UserService.getLocations(el.document_id)
    .then(response => {
      const respLocation = response.data[0]
      if (respLocation) {
        saveSelection('selectedLocation', respLocation)
      }
    })
  }
}

const triggerProductSelect = async (el, locale) => {
  // if (productElements.length > 0) {
  //   productElements.forEach(element => this.loadProducts(element))
  // }
  // const notification = {
  //   message: 'Issue identifying product selection. The document might look incomplete.',
  //   type: 'danger'
  // }

  let products = []
  const selectedItems = Object.values(el.document_items).filter(di => di.selected == true).map(x => x.selectable_id)
  const requests = []

  selectedItems.forEach(si => {
    const query = {
      id_eq: si
    }
    const request = ProductService.ransackQuery(locale, 1, 1, el.group.code, query)
    requests.push(request)
  })

  Promise.all(requests)
  .then(responses => {
    responses.forEach(resp => {
      products.push(resp.data.items[0])
    })

    if (products.length) {
      saveSelection(el.key, products)
    }

  })
  .catch(error => {
    console.log(error)
  })



  // updateNotification(notification, true)
}

// const triggerChildrenSelect = (el) => {
//   console.log('children select')
//   // this.updateSelection()
//   // this.updatechildrenElementsSelection(this.dataElements)

//   // const notification = {
//   //   message: 'Issue identifying document selection. The document might look incomplete.',
//   //   type: 'danger'
//   // }

//   // updateNotification(notification, true)
  
// }



// resolves all triggers. Output and Public (previews)
const resolveUserSelection = (dataElements, locale, groupAssociations) => {

  const locationElements = dataElements.filter(de => de.field_type == 'location_select')
  const productElements = dataElements.filter(de => de.group && de.group.group_type == 'PRODUCT')
  const logicalInputs = dataElements.filter(de => de.field_type == 'logical_grouping' )

  if (locationElements.length > 0) {
    
    locationElements.forEach(el => {
      console.log('location element ', el)
      triggerLocationSelect(el) 
    })       
  }

  if (productElements.length > 0) {
    productElements.forEach(el => {
      triggerProductSelect(el, locale)
    })
  }

  if (logicalInputs.length > 0) {
    logicalInputs.forEach(el => {
      // triggerChildrenSelect(el, locale)
      const selectedChildren = dataElements.filter(de => de.parent_id == el.id)
      let selectionKey = el.key
      
      // console.log('Is logical - > ', el.key, selectedChildren)

      const matchOptions = [el.key]

      if (el.group && el.group.code) {
        matchOptions.push(el.group.code)
      }

      const groupAssoc = groupAssociations.find(ga => matchOptions.includes(ga.source_group_code))
      // console.log('group assoc: ', groupAssoc)

      if (groupAssoc) {
        // const codes = groupAssoc.group_association_items.map(gai => gai.target_group_item_code)
        
        
        // codes.forEach(code => {
        //   const de = dataElements.find((de) => Object.values(de.document_items).find(di => di.selected && di.code == code))
          
        //   selectionKey = de ? de.key : null
          
        //   console.log(de, code, selectionKey)

        //   if (selectionKey && selectedChildren.length) {
        //     console.log('selected children', selectedChildren)
        //     saveSelection(selectionKey, selectedChildren)
        //   }
        // })

        const codes = groupAssoc.group_association_items.map(gai => gai.target_group_item_code ? gai.target_group_item_code : gai.target_group_item)
        
        
        codes.forEach(code => {
          const de = dataElements.find((de) => Object.values(de.document_items).find(di => di.selected && di.code == code))
          
          selectionKey = de ? de.key : null
          
          // console.log(de, code, selectionKey)

          if (selectionKey && selectedChildren.length) {
            // console.log('selected children', selectionKey, selectedChildren.map(x => x.key))
            saveSelection(selectionKey, selectedChildren, true)
          }
        })
      }

      
    })
  }

  
}

// updates store with selection

const saveSelection = (key, value, isIncrement) => {
  const object = {
    key: key,
    value: value,
    increment: isIncrement
  }
  store.commit('saveDocumentSelection', object)
}


export { hasGroupSelect, groupSelectType, asyncLoadBeforeReroute, findLocation, saveSelection, resolveUserSelection }