function resolveQueryUrl (baseUrl, query, limit, page) {
  // Search query
  if (query) {
    for (const [key, value] of Object.entries(query)) {
      const resolvedQuery = (baseUrl.includes('?') ? '&' : '?') + 'q[' + key + ']=' + value
      baseUrl = baseUrl + resolvedQuery
    }
  }
  // Query limit
  if (limit) {
    baseUrl = baseUrl + (baseUrl.includes('?') ? '&' : '?') + 'limit_value=' + limit
  }
  // Pagination
  if (page) {
    baseUrl = baseUrl + (baseUrl.includes('?') ? '&' : '?') + 'page=' + page
  }

  return baseUrl
}

export { resolveQueryUrl }
