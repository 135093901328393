<template>
  <div class="attachment-list">
    <img v-for="image in attachments" :key="image.id" :alt="image.name" :src="image[imageSize]" :class="imgClass"/>
  </div>
</template>

<script>
  import { useStore } from 'vuex'
  import { computed } from 'vue'
  export default {
    props: {
      attachments: {
        type: [Array],
        required: true
      },
      size: {
        type: String,
        required: false,
        default: 'small'
      }
    },
    setup(props) {
      const store = useStore()
      const imageSize = computed(() => store.getters.imageUrl)

      const imgClass = computed(() => {
        let imgClass = 'is-sized-small'

        if (props.size) {
          imgClass = 'is-sized-' + props.size
        }

        return imgClass
      })
      
      return {
        imageSize,
        imgClass
      }
    }
  }
</script>