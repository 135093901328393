<template>
  <Notification
    v-model="notificationVisible"
    :type="notification.type"
    @dismiss="dismissNotification"
  >
    {{ notification.message }}
  </Notification>
  <section>
    <div class="container is-max-widescreen">
      <breadcrumbs :items="breadcrumbItems" />

      <HeaderTitle
      :title="pageTitle"
      :subtitle="t('single_contact.subtitle')"
      :isOutlined="true"
      class="mb-6"
      ></HeaderTitle>
    </div>
    <div class="has-gray-bg pt-4 pb-6">
      <div class="container is-max-widescreen pt-3">
        <div class="column">
          <div v-if="contactNotLoaded">
            <Loading :loading="loading.contact"></Loading>
          </div>

          <div v-else>
              <h2 class="title is-4">Information</h2>
              
              <ContactInfoCard
                :object="contact"
              ></ContactInfoCard>
              
              
              <h3 class="title is-4 pt-6">Locations</h3>
              <!-- location cards -->

              <vue-horizontal v-if="contactLocationsExist" :button="contactLocations.length > 4">
                <div class="tile is-ancestor">
                  <div 
                    class="item tile is-parent is-4"
                    v-for="location in contactLocations"
                    :key="location.id">
                      <LocationCard
                        :location="location"
                        :includeContact="false"
                        class="tile is-child"
                      ></LocationCard>
                  </div>
                </div>
              </vue-horizontal>

              <p v-else>{{ t('single_contact.locations.does_not_exist') }}</p>
              

              <h3 class="title is-4 pt-6">Organizations</h3>           
              <!-- organization cards -->
              <vue-horizontal v-if="contactOrganizationsExist" :button="contactOrganizations.length > 4">
                <div class="tile is-ancestor">
                  <div 
                    class="item tile is-parent is-4"
                    v-for="organization in contactOrganizations"
                    :key="organization.id">
                      <OrganizationCard
                        :object="organization"
                        class="tile is-child"
                        ></OrganizationCard>
                  </div>
                </div>
              </vue-horizontal>

              <p v-else>{{ t('single_contact.organizations.does_not_exist') }}</p>

              <h3 class="title is-4 pt-6">Documents</h3>
              <!-- document cards -->
              <DocumentGrid v-if="contactDocumentsExist" :documents="contact.documents" itemClass="has-gap gap-2 max-w-3" :actions="['edit', 'output']"></DocumentGrid>
              
              <p v-else>{{ t('single_contact.documents.does_not_exist') }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
require('@/assets/stylesheets/module_specific/contact.scss');
import { inject, ref, reactive, computed } from 'vue'
import { useI18n } from 'vue-i18n';
import HeaderTitle from '@/components/shared/HeaderTitle.vue'
// import DocumentGrid from '@/components/document/DocumentGrid.vue'
import ContactService from '@/services/ContactService.js'

import OrganizationCard from "@/components/contact/OrganizationCard.vue"
import LocationCard from "@/components/shared/LocationCard.vue"
import DocumentGrid from '@/components/document/DocumentGrid.vue'
import ContactInfoCard from "@/components/contact/ContactInfoCard"

import VueHorizontal from 'vue-horizontal';
// import { contactSidebar as sidebarItems} from '@/shared/setup/sidebarOptions.js'

import { 
  notificationVisible, 
  notification, 
  dismissNotification, 
  updateNotification
} from '@/shared/setup/notifications.js'

export default {
  created () {
    this.loadContact()  
  },
  setup(props){
    const { t } = useI18n()
    const contact = reactive({})
    const errors = ref([])
    const loading = reactive({
      contact: {
        show: true
      }
    })

    // computed
    const settings = inject('clientGlobalSettings')
    
    const contactNotLoaded = computed(() => loading.contact.show )
    
    const pageTitle = computed(() => contact.full_name ? contact.full_name : "Contact")
    
    const contactLocations = computed(() => contact.locations)
    
    const contactOrganizations = computed(() => contact.organizations)
    
    const contactOrganizationsExist = computed(() => contactOrganizations.value.length)
    
    const contactDocumentsExist = computed(() => contact.documents.length)
    
    const contactLocationsExist = computed(() => contactLocations.value.length)

    const loadContact = async () => {
      ContactService.getContact(props.id)
      .then(response => {
        const loadedContact = response.data
        for (const [key, value] of Object.entries(loadedContact)) {
          contact[key] = value
        }
        loading.contact.show = false
      })
      .catch(error => {
        console.log(error)
        const notification = {
          type: 'danger',
          message: 'Error encountered loading this contact.'
        }

        updateNotification(notification, true)
      })
    }

    const breadcrumbItems = ref([
      {
        route: 'user-contacts',
        button: 'contacts.buttons.back'
      }
    ])

    return {
      t,
      notificationVisible,
      notification,
      dismissNotification,
      updateNotification,
      loadContact,
      contact,
      errors,
      loading,
      settings,
      contactNotLoaded,
      pageTitle,
      contactLocations,
      contactOrganizations,
      contactOrganizationsExist,
      contactDocumentsExist,
      contactLocationsExist,
      breadcrumbItems
    }
  },
  components: {
    HeaderTitle,
    ContactInfoCard,
    LocationCard,
    OrganizationCard,
    DocumentGrid,
    VueHorizontal
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  }
}
</script>
