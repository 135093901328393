<template>
	<div class="breadcrumb-container">
    <router-link class="button is-ghost pl-0 " :to="{name: item.route}" v-for="item in items" :key="item.route">
      <span class="icon is-small">
       <font-awesome-icon icon="chevron-circle-left"/>
      </span>
      <span>{{ t(item.button) }}</span>
    </router-link>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
	props: {
		items: {
			type: Array
		}
	},
	setup(){
		const { t } = useI18n()

		return {
			t
		}
	}
}
</script>