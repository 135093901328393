<template>
  <!-- multiple updates at once -->
  <div class="field">
    <div v-for="item in documentItems" :key="item.id" class="field">
      <document-item-attribute
        v-for="attribute in overrideOptions"
        :key="computedInput.id + '-' + attribute"
        :input="computedInput"
        :item="item"
        :attribute="attribute"
        @update:documentItem="updateDocumentItem" 
        @update:immediate="updateImmediate"/>
    </div>
  </div>
</template>

<script>
  import { computed, watch } from 'vue'
  import DocumentItemAttribute from '@/components/inputs/accessories/DocumentItemAttribute.vue'
  export default {
    props: {
      input: {
        type: Object
      },
      size: {
        type: String
      },
    },
    components: {
      DocumentItemAttribute
    },
    emits: ['update:input', 'update:immediate'],
    setup(props, { emit }) {
      // coming from child inputs

      // to do: figure out immediate call here.
      const computedInput = computed(() => props.input)
      
      const documentItems = computed(() => computedInput.value ? Object.values(computedInput.value.document_items).filter(d => d.selected == true) : [])

      const overrideOptions = computed(() => {
        const overrideOptions = []
        
        if (computedInput.value && computedInput.value.properties) {
          for (const [key, value] of Object.entries(computedInput.value.properties)) {
            if (key.includes('allow_override_value') && [true, 'true'].includes(value)) {
              const overrideType = key.split('allow_override_')[1]
              overrideOptions.push(overrideType)
            }
          }
        }

        return overrideOptions
      })

      // Emits update to parent in the background.
     const updateDocumentItem = (inputRef, attr, newValue) => {
      // console.log('document update - document items: ', attr, newValue, props.input.key, inputRef)
        // emit('update:input', inputRef, attr, newValue)
        // document item is sending [props.input, inputRef] as inputRef
         emit('update:input', inputRef, attr, newValue)
      }

      // new

      const updateImmediate = (inputRef, attr, newValue) => {
        emit('update:immediate', inputRef, attr, newValue)
      }

      watch(computedInput, (v) => {
        console.log('input seems to have changed? document items')
        console.log(v.key, v.value_array)
      })


      return {
        updateDocumentItem,
        overrideOptions,
        documentItems,
        computedInput,
        updateImmediate
      }
    }
  }
</script>