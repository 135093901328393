{
  "languages": {
    "en": "English",
    "fr": "French",
    "es": "Spanish"
  },
  "signed_in": {
    "success": {
      "message": "Successfully logged in. Welcome back!",
      "type": "success"
    },
    "error": {
      "message": "Could not sign in. Please try again.",
      "type": "error"
    }
  },
  "log_out": {
    "success": {
      "message": "Successfully logged out.",
      "type": "success"
    }
  },
  "password_reset": {
    "title": "Reset Password",
    "explainer": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae aperiam ad doloremque sunt eum aliquid at nesciunt sapiente consequatur, quidem numquam autem. Quos, culpa sed molestiae voluptatibus accusantium repudiandae sit.",
    "success": {
      "message": "Your password was successfully updated. Now you can log in using your new password.",
      "type": "success"
    },
    "password": "Password",
    "password_confirmation": "Password Confirmation"
  },
  "password_request": {
    "success": "Please check your inbox for the reset password link."
  },
  "login_page": {
    "welcome": "Welcome to Ad Builder",
    "subtitle": "Customize corporate approved sales materials in 3 easy steps",
    "third_line": "Please log in with your assigned username and password.",
    "support_line": "For assistance or to request access, please submit a ticket to our support team.",
    "forgot_password": "Forgot your password?"
  },
  "buttons": {
    "login": "Log in",
    "next": "Next",
    "back": "Back",
    "reset": "Reset",
    "submit": "Submit"
  },
  "dashboard": {
    "welcome": "Welcome {first_name}, where do you want to start?",
    "message_box": {
      "title": "Lorem Ipsum",
      "message": "Delectus postulant vis id. An vis verear maluisset. Ei tation labores pri. Ex minimum propriae vis. Persius similique his id, dolore interesset cu his."
    },
    "image_path": "dashboard-img.jpg",
    "steps": {
      "title": "New to Ad Builder? Here's where to start:",
      "select": "<strong>Select:</strong> Click on in the menu and choose a template.",
      "customize": "<strong>Customize:</strong> Click the edit icons or the blue buttons to customize your ad",
      "output": "<strong>Output:</strong> Export your ad as a PDF or URL, or email it directly."

    }
  }
}