<template>
  <div class="is-relative image-options p-3">
    <div class="pb-4">
      <h3 class="title is-5">
        <slot>{{ t('output.' + option.content_type + '.content_title') }}</slot>
      </h3>
    </div>

    <p>{{ t('output.html.content') }}</p>

    <div class="my-5">
      <div class="columns">
        <div class="column" v-if="isPublished">
          <p><strong>{{ t('output.html.published_on') }}:</strong> {{ formatDate(option.published_date, 'MM/dd/yyyy') }}</p>
          <p><strong>{{ t('output.html.expiry_date') }}:</strong> {{ formatDate(option.expiration_date, 'MM/dd/yyyy') }}</p>
        </div>
        <div class="column" v-else>
          <control type="datepicker" :placeholder="'Expiry date'" v-model="expiryDate" />   
        </div>

        <div class="column is-narrow">
          <button class="button" :class="{'is-primary': !isPublished, 'is-danger': isPublished, 'is-loading': publishing}" @click="publishDocument">
            <span class="icon-text">
              <span>{{ isPublished ? t('output.buttons.unpublish') : t('output.buttons.publish') }}</span>
              <span class="icon">
                 <font-awesome-icon icon="share-square" v-if="!isPublished" /> 
                <font-awesome-icon icon="eye-slash" v-else />
              </span>
            </span>
          </button>
        </div>
      </div>
      

      <div class="mt-3" v-if="isPublished">
        <table>
          <tbody>
            <tr v-for="locale in option.locales" :key="locale.locale">
              <td>
                <p class="flag-link">
                  <country-flag :country="locale.country" size="normal" />
                  <span>{{ locale.locale_name }}</span>
                </p>
              </td>
              <td class="published-actions">
                <router-link class="icon-text eq-padding" :to="{name: 'public-document', params: { docId: dmtId, layoutId: layoutId, locale: locale.locale }}" target="_blank" @click="record('Display', locale.locale)">
                  <span class="icon">
                    <font-awesome-icon icon="link" size="sm" /> 
                  </span>
                  <span>{{ t('helpers.view') }}</span>
                </router-link>
                
                <span class="eq-padding">
                  <input
                    type="text"
                    class="displaced"
                    :value="publicViewUrl(locale.locale)"
                    :id="`url_${layoutId}_${locale.locale}`"
                  />
                  <a class="icon-text" role="button" @click.prevent="copyLink(locale.locale)">
                    <span class="icon">
                      <font-awesome-icon icon="clipboard" size="sm" />  
                    </span>
                    <span>{{ t('helpers.copy') }} {{ t('helpers.link').toLowerCase() }}</span>
                  </a>
                </span>
                
                <a class="icon-text eq-padding" role="button" @click.prevent="getEmbedCode(locale.locale)">
                  <span class="icon">
                     <font-awesome-icon icon="code" size="sm" /> 
                  </span>
                  <span>{{ t('helpers.embed') }}</span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal" :class="{'is-active': modal.active}">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="control is-relative p-2 has-background-grey-lighter">
          <textarea id="htmlContent" v-model="modal.content" class="textarea" readonly style="position:absolute;left:-100%"></textarea>
          <font-awesome-icon icon="clipboard" class="is-clickable" id="copy-html" @click="copyHTML($event)" />
          <div class="code-wrapper">
            <pre>{{modal.content}}</pre>
          </div>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close" @click="modal.active = false"></button>
    </div>
  </div>
</template>

<script>
  import CountryFlag from 'vue-country-flag-next'
  import { useI18n } from 'vue-i18n'
  import { ref, computed, inject, reactive } from 'vue'
  import DocumentService from '@/services/DocumentService.js'
  import Control from '@/components/shared/Control.vue'

  export default {
    components: {
      CountryFlag,
      Control
    },
    props: {
      option: {
        type: Object,
        required: true
      }
    },
    emits: ['update-options', 'notify', 'record'],
    setup(props, { emit }) {
      const { t } = useI18n()
      const publishing = ref(false)
      const isPublished = computed(() => props.option.status == 'published')
      const dmtId = ref(props.option.document_id)
      const layoutId = ref(props.option.layout_id)
      const formatDate = inject('formatDate')
      const modal = reactive({
        active: false,
        content: null
      })

      const expiryDate = ref()

      const publishDocument = () => {
        publishing.value = true
        let request

        if (isPublished.value) {
          request = DocumentService.unpublishDocument(dmtId.value)
        } else {
          const date = expiryDate.value
          request = DocumentService.publishDocument(dmtId.value, date)
        }

        request
        .then(response => {
          publishing.value = false
          const updatedDocument = response.data
          console.log(response)

          emit('update-options', updatedDocument)
        })
        .catch(error => {
          console.log(error)

          const notification = {
            message: 'Issue encountered publishing document.',
            type: 'danger'
          }

          emit('notify', notification)
        })
      }

      const copyLink = (locale) => {
        /* Get the text field */
        const copyText = document.getElementById(`url_${layoutId.value}_${locale}`)
        const feedbackSpan = copyText.nextElementSibling.lastElementChild
        /* Select the text field */
        copyText.select()
        copyText.setSelectionRange(0, 99999) /*For mobile devices*/

        /* Copy the text inside the text field */
        document.execCommand('copy')

        feedbackSpan.innerHTML = t('helpers.copied')

        window.setTimeout(() => {
          feedbackSpan.innerHTML = t('helpers.copy') + ' ' + t('helpers.link').toLowerCase()
        }, 2000)
      }

      const getEmbedCode = (locale) => {
        DocumentService.getHTMLCode(dmtId.value, layoutId.value, locale)
        .then(response => {
          console.log(response.data)
          modal.active = true
          modal.content = response.data
        })
        .catch(error => {
          console.log(error)

          const notification = {
            message: 'Issue encountered getting HTML.',
            type: 'danger'
          }

          emit('notify', notification)
        })
      }

      const publicViewUrl = (locale) => {
        const base = window.location.origin
        return base + '/published/' + dmtId.value + '/' + layoutId.value + '/' + locale
      }

      const copyHTML = (ev) => {
        const copyText = document.getElementById(`htmlContent`)
        /* Select the text field */
        copyText.select()
        copyText.setSelectionRange(0, 99999) /*For mobile devices*/

        ev.target.classList.add('animate__animated', 'animate__rubberBand')

        /* Copy the text inside the text field */
        document.execCommand('copy')
      }


      const record = (action, locale) => {
        const other = {
          document_id: props.option.id,
          locale: locale
        }

        emit('record', action, other)
      }

      return { 
        t,
        dmtId,
        layoutId,
        expiryDate,
        publishDocument,
        isPublished,
        publishing,
        publicViewUrl,
        copyLink,
        formatDate,
        modal,
        getEmbedCode,
        copyHTML,
        record
      }
    }
  }
</script>