<template>
  <Notification
    v-model="notificationVisible"
    :type="notification.type"
    @dismiss="dismissNotification"
  >
    {{ notification.message }}
  </Notification>
  <section>
    <div class="has-gray-bg">
      <div class="container is-max-widescreen">
        
        <div class="columns">
          <div class="column is-8">
            <div class="py-6">
              <h1 class="title is-2 is-spaced"> {{ t('login_page.welcome') }} </h1>
              <h2 class="subtitle is-5">
                {{ t('login_page.subtitle')}}
              </h2>

              <p class="mt-6">{{t('login_page.support_line', { support_email: settings.support_email })}}</p>
            </div>
          </div>


           <div class="column is-5 tb-overflow to-3">
            <div class="box">
              <NewPasswordForm  @cancel-reset-pwd="resetPwd" v-if="token"></NewPasswordForm>
              
              <PasswordRequestForm @cancel-reset-pwd="resetPwd" v-else-if="isResetPwd && !token"></PasswordRequestForm>

              <LoginForm @reset-pwd="resetPwd" @notify="notify" v-else></LoginForm>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { computed, ref, inject } from 'vue'
import LoginForm from '@/components/account/LoginForm.vue'
import PasswordRequestForm from '@/components/account/PasswordRequestForm.vue'
import NewPasswordForm from '@/components/account/NewPasswordForm.vue'
import { useI18n } from 'vue-i18n'
import { 
  notificationVisible, 
  notification, 
  dismissNotification, 
  updateNotification
} from '@/shared/setup/notifications.js'

export default {
  props: {
    parentNotification: {
      type: String
    },
    token: {
      type: String,
      required: false
    },
    brand: {
      type: String,
      required: false
    }
  },
  created(){
    if (this.parentNotification) {
      this.updateNotification(JSON.parse(this.parentNotification), true)
    }
  },
  setup(){
    const { t } = useI18n()
    const clientSettings = inject('clientGlobalSettings')
    const email = ref(null)
    const password = ref(null)
    const isResetPwd = ref(null)

    const settings = computed(() => clientSettings )

    const resetPwd = (boolean) => {
      isResetPwd.value = boolean
    }

    const notify = (notification) => {
      updateNotification(notification, true)
    }

    return { 
      t,
      email,
      password,
      isResetPwd,
      resetPwd,
      settings,
      notify,
      notificationVisible, 
      notification, 
      dismissNotification, 
      updateNotification
    }
  },
  components: {
    LoginForm,
    PasswordRequestForm,
    NewPasswordForm
  }
}
</script>
