<template>
  <div class="container is-max-desktop">
    <div class="has-text-centered my-6">
      <h2 class="title is-3">{{ sections.main_title}}</h2>
    </div>

    <div class="has-text-centered my-6 is-max-tablet">
      <h3 class="title is-4 is-spaced">{{ sections.sections[0].title}}</h3>
      <p class="subtitle is-6">{{ sections.sections[0].subtitle}}</p>
    </div>

    <div class="columns">
      <div class="column is-4 is-offset-4">
        <document-inputs
          :input="dataEl('grp_industry')"
          @update:modelValue="emitUpdate"
          @update:input="updateInput"
          @update:logo="updateLogo"
          @update:removeAttachment="removeAttachment"
          @update:assignContact="assignContact"
          @update:documentItem="updateDocumentItem"
        />
      </div>
    </div>

    <document-inputs
      :input="industryBoxes"
      :key="industryBoxes.id"
      @update:modelValue="emitUpdate"
      @update:input="updateInput"
      @update:logo="updateLogo"
      @update:removeAttachment="removeAttachment"
      @update:assignContact="assignContact"
      @update:documentItem="updateDocumentItem"
    />

    <div class="has-text-centered my-6">
      <h3 class="title is-4">{{ sections.sections[1].title}}</h3>
    </div>

    <div class="">
      <div class="columns">
        <div class="column is-half">
          <document-inputs
            :input="dataEl('company_name')"
            @update:modelValue="emitUpdate"
            @update:input="updateInput"
            @update:logo="updateLogo"
            @update:removeAttachment="removeAttachment"
            @update:assignContact="assignContact"
            @update:documentItem="updateDocumentItem"
          />

        </div>
        <div class="column is-half">
          <document-inputs
            :input="dataEl('contact_name')"
            @update:modelValue="emitUpdate"
            @update:input="updateInput"
            @update:logo="updateLogo"
            @update:removeAttachment="removeAttachment"
            @update:assignContact="assignContact"
            @update:documentItem="updateDocumentItem"
          />
        </div>
      </div>

      <div class="columns">
        <div class="column is-full">
          <document-inputs
            :input="dataEl('client_logo')"
            @update:modelValue="emitUpdate"
            @update:input="updateInput"
            @update:logo="updateLogo"
            @update:removeAttachment="removeAttachment"
            @update:assignContact="assignContact"
            @update:documentItem="updateDocumentItem"
          />
        </div>
      </div>

      <div class="columns">
        <div class="column is-full">
          <document-inputs
            :input="dataEl('personal_message')"
            @update:modelValue="emitUpdate"
            @update:input="updateInput"
            @update:logo="updateLogo"
            @update:removeAttachment="removeAttachment"
            @update:assignContact="assignContact"
            @update:documentItem="updateDocumentItem"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DocumentInputs from '@/components/document/update/DocumentInputs'
  import { useStore } from 'vuex'
  import { inject, watch, computed } from 'vue'

  export default {
    props: {
      inputs: {
        type: Array
      },
      sections: {
        type: Object
      }
    },
    components: {
      DocumentInputs
    },
    emits: [
      'update:modelValue',
      'update:input',
      'update:logo',
      'update:assignContact',
      'update:removeAttachment',
      'update:documentItem'
    ],
    setup(props, { emit }) {
      const groupAssociations = inject('groupAssociations')
      const store = useStore()
      const dataEl = (key) => {
        const dataEl = props.inputs.find(de => de.key == key)
        return dataEl
      }

      const dataElFromSelect = (sourceKey) => {
        let matching
        const selected = Object.values(dataEl(sourceKey).document_items).find(di => di.selected == true)
        
        if (selected) {
          const association = groupAssociations.value.filter(ga => {
            return ga.group_association_items.find(gai => gai.target_group_item_code == selected.code) 
          })

          if (association && association.length == 1) {
            matching = props.inputs.filter(de => association[0].relationships.map(r => r.parent_id).includes(de.parent_id))
          }
        }

        return matching
      }

      const industryBoxes = computed(() => {
        return store.state.currentDocument.sharedSelection['grp_industry'][0]
      })

      const storeSelection = (value) => {
        const object = {
          key: 'grp_industry',
          value: value
        }
        store.commit('saveDocumentSelection', object)
      }

      watch(dataEl('grp_industry'), () => {
        storeSelection(dataElFromSelect('grp_industry'))
      })

      storeSelection(dataElFromSelect('grp_industry'))

      // emits to CustomizeScreen (just pass information)
      const updateInput = (input, property, newValue, diRef) => {
        emit('update:input', input, property, newValue, diRef)
      }

      const updateLogo = (contactObject, contactType, triggerElementKey, logoId) => {
        emit('update:logo', contactObject, contactType, triggerElementKey, logoId)
      }
      
      const removeAttachment = (input) => {
        emit('update:removeAttachment', input)
      }

      const assignContact = (contactObject, contactType, input) => {
        emit('update:assignContact', contactObject, contactType, input)
      }

      const emitUpdate = (value, input, issueCall) => {
        
        emit('update:modelValue', value, input, issueCall)
      }

      const updateDocumentItem = (value, newRef, bool) => {
        emit('update:documentItem', value, newRef, bool)
      }

      return {
        dataEl,
        emitUpdate,
        updateInput,
        updateLogo,
        removeAttachment,
        assignContact,
        updateDocumentItem,
        industryBoxes
      }
    }
  }
</script>