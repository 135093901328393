<template>
  <div id="overlay" v-show="isActive">
    <section class="hero is-fullheight">
      <div class="hero-body container has-text-centered">
        <div class="">
          <p class="title">
            <slot></slot>
          </p>
          <p class="subtitle">
            <Loading :loading="showLoading"></Loading>
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import { useStore } from 'vuex'
  import { computed, onUpdated } from 'vue'

  export default {
    name: 'Overlay component',
    props: {
      showLoading: {
        type: Boolean,
        default: true
      },
      forceShow: {
        type: Boolean
      }
    },
    setup(props) {
      const store = useStore()
      const isActive = computed(() => props.forceShow || store.state.session.loadingInProgress)

      onUpdated(() => {
        if (isActive.value) {
          document.body.classList.add('scroll-disabled')
        } else {
          document.body.classList.remove('scroll-disabled')
        }
        
      })

      return {
        isActive
      }
    }
  }
</script>