<template>
  <div class="field">
    <label class="label" v-if="label">
      {{ label }}
    </label>
    
    <div v-if="allowsOverride">
      <document-item-attribute
        :input="input"
        attribute="value"
        @update:documentItem="updateDocumentItem"
      />
    </div>

    <input
      v-else
      type="text"
      class="input"
      v-model="computedValue"
      :placeholder="placeholder"
      @change="emitUpdate($event)"
    />

    <field-message v-if="inputError.message.length" :object="inputError">
      {{ inputError.message }}
    </field-message>
  </div>
</template>

<script>
import { inject, computed } from 'vue'
import { inputError, updateInputError } from '@/shared/setup/notifications.js'
import DocumentItemAttribute from '@/components/inputs/accessories/DocumentItemAttribute.vue'

export default {
  components: {
    DocumentItemAttribute
  },
  setup(props, { emit }){
    const properties = computed(() => props.input.properties)
    const allowsOverride = computed(() => properties.value.allow_override_value && properties.value.allow_override_value == 'true' )
    const locale = inject('dmtLocale')
    const computedValue = computed({
      get: () => {
        // return props.compModelVal.value
        return props.input.value
      },
      set: value => {
        emit('update:immediate', value)
      }
    })
    
    // not used for contacts
    const emitUpdate = (ev) => {
      const value = ev.target.value
      emit('update:modelValue', value)
    }

    const updateDocumentItem = (inputRef, attr, newValue) => {
      emit('update:input', inputRef, attr, newValue)
    }

    const label = computed(() => properties.value.label)
    const placeholder = computed(() => properties.value.placeholder)

    return {
      inputError,
      updateInputError,
      locale,
      computedValue,
      label,
      placeholder,
      allowsOverride,
      emitUpdate,
      updateDocumentItem
    }
  },
  props: {
    input: {
      type: Object,
      required: true
    },
  }
}
</script>
