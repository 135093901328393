<template>
  <p :class="computedClasses">
    <slot></slot>
  </p>
</template>

<script>
import { computed } from 'vue'

export default {
  props: {
    object: Object
  },
  setup(props){
    const computedClasses = computed(() => {
      let baseClass = ['help']

      if (props.object.style.length) {
        baseClass.push('is-' + props.object.style)
      }

      return baseClass
    })

    return {
      computedClasses
    }
  }
}
</script>
