import axios from 'axios'
import router from '@/router'
import store from '@/store'

const site_location = window.location
var res_api_url = site_location.protocol + '//demo_api.getdms.com/'

if (['localhost:8080'].includes(site_location.host)) {
  res_api_url = site_location.protocol + '//localhost:3000'

} else {
  res_api_url = site_location.protocol + '//dms3_api.getdms.com/'
}

const DMS_API_URL = res_api_url
const DMS_ASSETS_URL = 'https://assets.getdms.com'
console.log("using this api URL: " + DMS_API_URL)

const securedAxiosInstance = axios.create({
  baseURL: DMS_API_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

const plainAxiosInstance = axios.create({
  baseURL: DMS_API_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

// custom wrappers to handle tokens (secured axios instance)

securedAxiosInstance.interceptors.request.use(config => {
  const method = config.method.toUpperCase()
  if (method !== 'OPTIONS' && method !== 'GET') {
    config.headers = {
      ...config.headers,
      'X-CSRF-TOKEN': localStorage.csrf
    }
  }
  return config
})

securedAxiosInstance.interceptors.response.use(null, error => {
  if (error.response && error.response.config) {
    let path = ''
    // when encounters a 401, 404
    // 403 and 500 are handled by the API call
    switch (error.response.status) {
      case 401:
        path = 'login'
        
        console.log("401 error. Saving this route info:")
        console.log(router.currentRoute.value)

        store.commit('unauthenticate', router.currentRoute.value)

        var notification = {
          notification: {
            type: 'warning',
            message: 'Please log in before going ahead.'
          }
        }

        router.push({ name: path, params: notification })
        // return Promise.reject(error)
        break
      case 404:
        console.log(error)
        path = 'not-found'
        router.push({ name: path })
        break
      case 500:
      console.log('500 error')
      break
    }

    

    
  } else {
    console.log('NOT 401, NOT 404')

    delete localStorage.csrf
    delete localStorage.signedIn

    store.commit('unauthenticate', '/login')

    router.push({
      name: 'login',
      params: {
        notification: {
          type: 'danger',
          message:
            'There was an error processing the request. Please try again or contact support if the problem persists.'
        }
      }
    })
  }
})

export { securedAxiosInstance, plainAxiosInstance, DMS_API_URL, DMS_ASSETS_URL }
