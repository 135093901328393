<template>
  <div class="card is-relative document-card" :class="{'animate__animated animate__fadeOutUp': isBeingDeleted}">
    <div class="card-image">
      <FigureImage
        :imageObject="previewImage"
      ></FigureImage>
    </div>
    <div class="card-content is-relative has-background-light">
      <div class="content">
        <div class="columns">
          <div class="column">
            <p class="is-size-7 doc-name">{{ truncate(document.name, 62, "...") }}</p>
            <p class="is-size-7">{{ updatedAt }}</p>
          </div>
          <div class="column is-narrow" v-click-outside-app="hideActions">
            <span class="icon is-clickable" @click="showActions = !showActions">
              <font-awesome-icon icon="caret-down"></font-awesome-icon>
            </span>

            <div class="card-actions box" v-show="showActions" >
              <span class="icon-text pb-1" v-if="editEnabled">
                  <a href="#" @click="editDocument(document)" :title="t('documents.helpers.edit')">
                    <span class="icon">
                      <font-awesome-icon icon="edit"></font-awesome-icon>
                    </span>
                    <span>{{ t('helpers.customize') }}</span>
                  </a>
              </span>
              <span class="icon-text has-text-danger py-1" v-if="childId && outputEnabled">
                <router-link :to="{ name: 'output', params: { id:  childId } }" :title="t('documents.helpers.output')">
                  <span class="icon">
                    <font-awesome-icon icon="eye"></font-awesome-icon>
                  </span>
                  <span>{{ t('documents.helpers.output') }}</span>
                </router-link>
              </span>
              <span class="icon-text pt-1" v-if="deleteEnabled">
                <a href="#" class="has-text-danger" :title="t('documents.helpers.delete')" @click.prevent="deleteDocument()">
                  <span class="icon">
                    <font-awesome-icon icon="trash"></font-awesome-icon>
                  </span>
                  <span>{{ t('helpers.delete') }}</span>
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import '@/assets/stylesheets/module_specific/cards.scss'
</style>
<script>
import { inject, computed, ref } from 'vue'
import FigureImage from '@/components/shared/FigureImage.vue'
import { editDocument } from '@/shared/setup/documentMethods.js'
import { useI18n } from 'vue-i18n'

export default {
  name: 'Document Card',
  components: {
    FigureImage
  },
  emits: ['delete'],
  setup(props, { emit }){
    const calculateAgo = inject('calculateAgo')
    const truncate = inject('truncate')
    const updatedAt = computed(() => calculateAgo(props.document.updated_at) )
    const showActions = ref(false)
    const { t } = useI18n()

    const previewImage = computed(() => props.document.preview_images && props.document.preview_images.length ?  props.document.preview_images[0] : "/placeholder-400x600.png")

    const isBeingDeleted = ref(false)

    // methods
    const deleteDocument = () => {
      emit('delete', props.document.id)
      isBeingDeleted.value = true
    }

    const childId = computed(() => {
      if (props.document.publishing_documents && props.document.publishing_documents.length) {
        const firstChild = props.document.publishing_documents[0]

        return firstChild.id
      } else {
        return null
      }
    })

    const editEnabled = computed(() => props.actions.includes('edit'))
    const outputEnabled = computed(() => props.actions.includes('output'))
    const deleteEnabled = computed(() => props.actions.includes('delete'))

    const hideActions = () => {
      showActions.value = false
    }

    return {
      t,
      updatedAt,
      previewImage,
      editDocument,
      deleteDocument,
      showActions,
      isBeingDeleted,
      childId,
      editEnabled,
      outputEnabled,
      deleteEnabled,
      hideActions,
      truncate
    }
  },
  props: {
    document: {
      type: Object,
      required: true
    },
    actions: {
      type: [Array]
    }
  }
}
</script>