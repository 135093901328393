<template>
  <div class="card">
    <div class="columns card-content">
      <div class="column is-5">
        <p>About this organization: lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque, blanditiis. Vitae iusto rerum doloribus sed, voluptatem veritatis, aperiam error. </p>
        <p>Amaiores vel beatae vitae, eligendi illo ipsam repellat mollitia quaerat, quas nam adipisci molestiae dolor.</p>
      </div>
      <div class="column">
        <IconText
          iconName="phone"
          type="phone"
          :href="object.phone"
          class="mb-2"
        > {{ object.phone }}</IconText>
        <IconText
          iconName="phone"
          type="phone"
          :href="object.fax"
          class="mb-2"
        >
          {{ object.fax }}
        </IconText>
        <IconText
          iconName="desktop"
          target="_blank"
          type="anchor"
          :href="object.website"
          @click="record(object, 'Website')"
          class="mb-2"
        >
          {{ t('generic.website') }}
        </IconText>
        <IconText
          iconName="inbox"
          type="mail"
          :href="object.email"
          @click="record(object, 'Email')"
          class="mb-2"
        >
          {{ object.email }}
        </IconText>
      </div>
      <div class="column is-3">
        <FigureImage
          :imageObject="objectLogo"
          imageClass="is-128x128"
        ></FigureImage>  
      </div>
    </div>
  </div>
</template>

<script>
import IconText from '@/components/shared/IconText.vue'
import FigureImage from '@/components/shared/FigureImage.vue'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { useStore } from 'vuex'
import { resolveHash, recordAction } from '@/shared/setup/analyticsMethods.js'

export default {
  components: {
    IconText,
    FigureImage
  },
  props: {
    object: {
      type: [Object],
      required: true
    }
  },
  setup(props){
    const { t } = useI18n()
    const store = useStore()
    const imageSize = computed(() => store.getters.imageUrl)
    const objectLogo = computed(() => {
      const logo = props.object.logos && props.object.logos.length ? props.object.logos[0][imageSize.value] : null

      return logo || '/missing-4by3.png'
    })

    const record = (contact, action) => {
      const other = {
        contact_id: contact.id
      }

      const hash = resolveHash('Contacts', 'Organization', action, null, other)
      recordAction(hash)
    }

    return { t, objectLogo, record }
  }
  
}
</script>