<template>
  <div class="field">
    <div class="tabs is-small is-boxed mb-0">
      <ul>
        <li 
          v-for="child in computedChildren"
          :key="child.id"
          class="has-text-weight-bold"
          :class="{'is-active': activeChildId == child.id }"
        >
          <a @click="selectTab(child.id)">
            <span>{{ child.group.value_label}}</span>
          </a>
        </li>
      </ul>
    </div>
    <!-- end of tabs -->
    <!-- tab content -->
    <div v-for="child in computedChildren"
      :key="child.id"
      v-show="activeChildId == child.id"
      class="tab-content"
    >
      <!-- select option (single select) -->
      <radio-image-select 
        :input="child"
        @update:modelValue="valueUpdate"
      />
      <!-- end of select option -->

      <!-- fill out option's info -->
      <document-item-attribute
        v-for="attribute in diAttributes"
        :key="attribute"
        :input="child"
        :attribute="attribute"
        @update:documentItem="updateDocumentItem"
      />
    </div>
  </div>
</template>

<script>
  import { watch, onBeforeMount, computed, ref, inject } from 'vue'
  // import { useStore } from 'vuex'
  import DocumentItemAttribute from '@/components/inputs/accessories/DocumentItemAttribute.vue'
  import RadioImageSelect from '@/components/inputs/accessories/RadioImageSelect.vue'

  export default {
    props: {
      inputs: {
        type: [Array, Object]
      }
    },
    components: {
      DocumentItemAttribute,
      RadioImageSelect
    },
    setup(props, { emit }){
      // const store = useStore()
      const dmtLocale = inject('dmtLocale')
      const computedChildren = computed({
        get: () => {
          return props.inputs ? props.inputs.value : []
          // return props.inputs ? props.inputs : []
        }
      })
      
      const selectTab = (num) => {
        activeChildId.value = num ? num : computedChildren.value.length ? computedChildren.value[0].id : null
      }

      const activeChildId = ref(null)

      const diAttributes = ref(['value1', 'value2', 'value_text', 'value_price'])


      onBeforeMount(() => {
        selectTab()
      })

      watch(computedChildren, (val, newVal) => {
        if (val.map(x => x.id) !== newVal.map(y => y.id)) {
          selectTab()
        }
      })


      const updateDocumentItem = (inputRef, attr, newValue) => {
        emit('update:input', inputRef, attr, newValue)
      }

      const valueUpdate = (newValue, inputRef) => {
        emit('update:modelValue', newValue, inputRef)
      }

      return {
        computedChildren,
        updateDocumentItem,
        dmtLocale,
        diAttributes,
        activeChildId,
        selectTab,
        valueUpdate
      }
    }
  }
</script>