<template>
  <div class="px-2 py-4" :class="parentClass">
    <div class="image is-relative template-preview is-clickable"  @click="selectTemplate(template)">
      <figure class="image is-square">
        <img :src="template.preview_image[imageSize]" :alt="template.preview_image.filename">
      </figure>
      
      <div class="template-info-overlay py-4 px-3" >
        <p class="is-size-5 has-text-weight-bold">{{ template.properties.title || template.name }}</p>
        <p class="is-size-6">{{ truncate(template.properties.description , 300, "...")}}</p>
      </div>
    </div>
    <div class="columns mt-3">
      <div class="column">
        <p>{{ template.properties.title || template.name }}</p>
      </div>
      <div class="column is-narrow">
        <a class="icon-text" :href="template.sample_url" target="_blank" :title="t('templates.helpers.view_sample')" v-if="template.sample_url">
          <span class="icon">
            <font-awesome-icon icon="file-pdf" />
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import { useI18n } from 'vue-i18n'
  import { useStore } from 'vuex'
  import { inject, computed } from 'vue'
  import { editDocument } from '@/shared/setup/documentMethods.js'
  import TemplateService from '@/services/TemplateService.js'

  export default {
    props: {
      template: {
        type: Object,
        required: true
      },
      parentClass: {
        type: String
      }
    },
    emits: ['notify'],
    setup(props, { emit }){
      const truncate = inject('truncate')
      const { t } = useI18n()
      const store = useStore()
      const imageSize = computed(() => store.getters.imageUrl)

      const selectTemplate = (template) => {
        if (template.code) {
          store.dispatch('toggleLoading', true)
          
          TemplateService.selectTemplate(template.code)
          .then(response => {
            store.dispatch('toggleLoading', false)
            editDocument(response.data)
          })
          .catch(error => {
            console.log(error)
            store.dispatch('toggleLoading', false)

            const notification = {
              message: 'Issue encountered selecting this template',
              type: 'danger'
            }

            emit('notify', notification, true)
          })
        } else {
          const notification = {
            message: 'Issue encountered selecting this template. Template code missing.',
            type: 'danger'
          }

          emit('notify', notification, true)
        }
        
      }

      return {
        t,
        imageSize,
        truncate,
        selectTemplate
      }

    }
  }
</script>