<template>
  <div id="notification" class="mb-4" v-show="value">
    <div class="notification is-light py-3" :class="className">
      <button class="delete" type="button" @click="close()"></button>
      <slot></slot>
    </div>
  </div>
</template>


<script>
// type: ['success', 'warning', 'info', 'danger']
import { computed } from 'vue'
export default {
  props: {
    type: {
      type: String,
      required: false
    },
    modelValue: {
      type: Boolean
    }
  },
  emits: ['update:modelValue', 'dismiss'],
  setup(props, { emit }){
    const className = computed(() => `is-${props.type}`)

    const value = computed({
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value)
    })

    const close = () => {
      value.value = false
      emit('dismiss')
    }


    return {
      className,
      value,
      close
    }
  }
}
</script>
