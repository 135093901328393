<template>
  <div class="card">
    <div class="card-image">
      <figure class="image">
        <img :src="logo.url" :alt="logoAlt">
      </figure>
    </div>
    <!-- <a href="#">Edit</a>
    <a href="#">Delete</a> -->
  </div>
</template>

<style scoped="true">
  /* to-do: :: adapt to image properties */
  /*.card {
    max-width:  100px;
  }*/

  .card-image {
    padding: 1rem;
  }

  .card-image .image {
    margin:  0 auto;
    display: block;
  }
</style>

<script>
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

export default {
  name: 'Logo Card',
  props: {
    logo: {
      type: Object,
      required: true
    }
  },
  setup(props){
    const { t } = useI18n()
    const logoAlt = computed(() => props.logo.name || t('generic.placeholder'))

    return {
      t,
      logoAlt
    }
  }
}
</script>