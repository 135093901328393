<template>
  <div class="container is-max-widescreen">
    <Notification
      v-model="notificationVisible"
      :type="notification.type"
      @dismiss="dismissNotification"
    >
      {{ notification.message }}
    </Notification>
  </div>

  <section class="section">
    <div class="container is-max-widescreen">
      <HeaderTitle
      :title="t('documents.index.heading')"
      :subtitle="t('documents.index.subtitle')"
      :isOutlined="true"
      ></HeaderTitle>
    </div>
  </section>

  <div class="has-gray-bg">
    <div class="container is-max-widescreen">
      <div>
        <div class="field is-horizontal pt-6">
          <div class="field-body">
            <div class="field">
              <control type="text" :placeholder="t('documents.search.by_name')" v-model="searchForm.name_cont" />
            </div>

            <div class="field">
              <control type="text" :placeholder="t('documents.search.by_code')" v-model="searchForm.code_cont" />
            </div>

            <div class="field is-narrow">
              <control type="select" :placeholder="t('documents.search.by_status')" v-model="searchForm.status_eq" :options="statusOptions"/>
            </div>

            <div class="field is-narrow">
              <control type="select" :placeholder="t('documents.search.sort')" v-model="searchForm.s" :options="sortByOptions" />
            </div>

          </div>
        </div>
      </div>
      
      <div class="vh-100" v-if="documentsNotLoaded">
        <Loading :loading="loading.documents"></Loading>
      </div>
      
      <div class="py-5" v-else>
        <div class="columns is-multiline">
          <p class="title is-5" v-if="documents.length === 0">{{ t('documents.none_yet')}}</p>
          <div class="column is-3" v-for="dmt in documents" :key="dmt.id">
            <document-card 
            :document="dmt"
            :actions="['edit', 'delete', 'output']"
            @delete="deleteDocument"
            />
          </div>
        </div>
      </div>

      <div class="py-5">
        <v-pagination
        v-show="pagination.totalPages > 1"
        v-model="pagination.currentPage"
        :pages="pagination.totalPages"
        :range-size="1"
        active-color="#28569A"
        @update:modelValue="fetchPage" />
      </div>
    </div>
  </div>
</template>


<script>
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { ref, reactive, computed, watch, inject } from 'vue'

import HeaderTitle from '@/components/shared/HeaderTitle.vue'
import Control from '@/components/shared/Control.vue'
import DocumentService from '@/services/DocumentService.js'
import DocumentCard from '@/components/document/DocumentCard.vue'
import VPagination from "@hennge/vue3-pagination"

import debounce from 'lodash/debounce'
import { 
  notificationVisible, 
  notification, 
  dismissNotification, 
  updateNotification
} from '@/shared/setup/notifications.js'

import {
  scrollTo
} from '@/shared/setup/globalMethods.js'

export default {
  components: {
    Control,
    HeaderTitle,
    DocumentCard,
    VPagination
  },
  setup(){
    const { t } = useI18n()
    const settings = inject('clientGlobalSettings')
    const store = useStore()
    const documentListTimestamp = computed(() => store.state.documents.updated_at)
    const timeSinceRefresh = computed(() => documentListTimestamp.value ? (new Date().getTime() - documentListTimestamp.value) / 60000 : null)

    const perPage = ref(50)
    const documents = ref([])
    
    const loading = reactive({
      documents: {
        initial: true,
        show: true
      }
    })

    const pagination = reactive({
      currentPage: null,
      totalPages: null
    })

    const resolvePagination = (header) => {
      pagination.currentPage = header.current_page
      pagination.totalPages = header.total_pages
    }

    const searchForm = reactive({
      name_cont: '',
      code_cont: '',
      status_eq: '',
      s: '',
    })

    const statusOptions = ref([
      {
        value: 'active',
        label: 'Active'
      },
      {
        value: 'draft',
        label: 'Draft'
      }
    ])

    const sortByOptions = ref([
      {
        value: 'name+asc',
        label: 'A-Z'
      },
      {
        value: 'name+desc',
        label: 'Z-A'
      },
      {
        value: 'updated_at+asc',
        label: 'Last updated last'
      },
      {
        value: 'updated_at+desc',
        label: 'Last updated first'
      },
    ])

    const firstDocumentLoad = async () => {
      if (timeSinceRefresh.value == null || timeSinceRefresh.value > settings.cache_expiry.documents){
        
        DocumentService.getDocuments(null, perPage, 1)
        .then(response => {
          documents.value = response.data
          loading.documents.initial = false
          loading.documents.show = false
          const pagHeader = JSON.parse(response.headers['x-pagination'])
          resolvePagination(pagHeader)
          // updates store
          store.dispatch('saveDocumentIndex', response.data)
          store.commit('updatePagination', pagHeader)
        })
        .catch(error => {
          console.log(error)
        })
      } else {
        documents.value = store.state.documents.index
        // update pagination
        resolvePagination(store.state.documents.pagination)
        loading.documents.initial = false
        loading.documents.show = false
      }
    }
    
    const getDocuments = async (page) => {
      DocumentService.getDocuments(searchForm, perPage, page)
      .then(response => {
        documents.value = response.data
        resolvePagination(JSON.parse(response.headers['x-pagination']))
      })
      .catch(error => {
        console.log(error)
      })
    }

    const fetchPage = (newPage) => {
     getDocuments(newPage)
     scrollTo(0,0)
    }

    const deleteDocument = (id) => {
      window.setTimeout(() => {
        documents.value = documents.value.filter(d => d.id !== id)
        // updates store index
        store.dispatch('saveDocumentIndex', documents.value)
      }, 1000)

      DocumentService.deleteDocument(id)
        .then(() => {
          notification.message = 'Document successfully deleted.',
          notification.type = 'success'
          updateNotification(notification, true)
        })
        .catch(error => {
          console.log('Error deleting document' + error)

          notification.message = 'Issue encountered deleting this document. Please try again or contact support.'
          notification.type = 'danger'

          updateNotification(notification, true)
        })
    }


    const documentsNotLoaded = computed(() => loading.documents.show || loading.documents.initial)

    watch(searchForm, debounce(() => {
      getDocuments(1)
    }, 600))

    return {
      t,
      notificationVisible, 
      notification,
      dismissNotification, 
      updateNotification,
      searchForm,
      statusOptions,
      sortByOptions,
      documents,
      getDocuments,
      documentsNotLoaded,
      firstDocumentLoad,
      loading,
      perPage,
      pagination,
      fetchPage,
      deleteDocument
    }


  },
  created(){
    this.firstDocumentLoad()
  },
}

</script>