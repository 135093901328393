import DocumentService from '@/services/DocumentService.js'
import { 
  updateNotification
} from '@/shared/setup/notifications.js'


function resolveDocumentItems(newDocumentItems, reference) {
  // document items are a key, value pair
  for (const [key, value] of Object.entries(newDocumentItems)) {
    reference.document_items[key] = value
  }
}

function resolveAttributes(source, target, attributes) {
  if (Array.isArray(attributes)) {
    for (var i in attributes) {
      const attribute = attributes[i]
      target[attribute] = source[attribute]
       // updated DIO object (source does not have it)
      target.document_input_object[attribute] = source[attribute]

      // if date, also update
      if (attribute == 'value_date') {
        updateDependentDateFields(source, target)
      }
      
    }
  } else {
    const attribute = attributes
    target[attribute] = source[attribute]

    // updated DIO object (source does not have it)
    target.document_input_object[attribute] = source[attribute]

    // if date, also update
    if (attribute == 'value_date') {
      updateDependentDateFields(source, target)
    }
  }
}

function updateDependentDateFields(source, target) {
  const additionalAttributes = ['value_date_default', 'value_date_default_long_text', 'value_date_long', 'value_date_short']
  additionalAttributes.forEach((attr) => {
    target[attr] = source[attr]
    // updated DIO object (source does not have it)
    target.document_input_object[attr] = source[attr]
  })
}

function resolveAttachments(source, target) {
  const attachmentAttributes = ['normal_url', 'original_url', 'preview_url', 'retina_url', 'url']
  // order of precedence: data_element.dio.asset > data_element.asset > data_element
  const object = target.document_input_object.asset ?
                  target.document_input_object.asset :
                  target.asset ? target.asset :
                  target
  // the source is the DIO
  attachmentAttributes.forEach((attr) => {
    if (source) {
      object[attr] = source[attr]
      if (object.document_input_object) {
        object.document_input_object[attr] = source[attr]
      }
    } else {
      if (object[attr]) {
        delete object[attr]
      }
      
      if (object.document_input_object && object.document_input_object[attr] ) {
       delete object.document_input_object[attr] 
      }
      
    }
   
  })
}

// update DIO without override
const updateDIO = (inputObject, attribute, newValue) => {
  console.log('updatevia UpdateService.js #1 > attribute: ', attribute, newValue, inputObject)

  const id = inputObject.document_input_object.id
  const dio = {}

  if (attribute == 'value_array') {

    if (Array.isArray(newValue) && newValue.includes(inputObject.group.content_type)) {
      dio[attribute] = newValue
    } else {
      // Covering cases like ["456"], "456", 456
      // do I need to enforce newValue to be a String?
      dio[attribute] = [inputObject.group.content_type, newValue]
    }

  } else {
    dio[attribute] = newValue
  }

  DocumentService.updateDIO(id, dio)
  .then(response => {
    console.log('update response DIO (w/o override)', response)

    if (response.data.group_association_items) {
      attribute = [attribute, 'group_association_items']
      console.log('group-assoc', attribute)
    }

    resolveAttributes(response.data, inputObject, attribute)
    // resolveGroupAssociations(response.data)
    // compare document items
    resolveDocumentItems(response.data.document_items, inputObject)
  })
  .catch(error => {
    console.log(error)
    const msg = 'Issue encountered updating document, please report this. Key: ' + inputObject.key + ' | Error: ' + error.name + ' | Message: ' + error.message
    const ntf = {
      type: 'danger',
      message: msg
    }
    updateNotification(ntf, true)
  })
}

// attachments need a different method as they must be passed as "Form Data"
const updateDIOAttachment = (inputObject, attributes, newValue) => {
  console.log('update Attachment #3 ')
  const id = inputObject.document_input_object.id
  const formDataDio = new FormData()
  formDataDio.append('attachment', newValue)

  DocumentService.updateDIO(id, formDataDio)
  .then(response => {
    console.log('update response DIO (attachment)', response)

    resolveAttachments(response.data, inputObject)
  })
  .catch(error => {
    console.log(error)
    const msg = 'Issue encountered updating attachment, please report this. Key: ' + inputObject.key + ' | Error: ' + error.name + ' | Message: ' + error.message
    const ntf = {
      type: 'danger',
      message: msg
    }
    updateNotification(ntf, true)
  })
}

function assignLogoToDIO(target, logoId, inputRef){
  console.log('assigning new logo...' , target, logoId)

  DocumentService.assignLogo(
    target,
    logoId,
    inputRef
  )
  .then(response => {
    resolveAttachments(response.data, inputRef)
  })
  .catch(error => {
    console.log(error)
    const msg = 'Issue encountered assigning logo, please report this. Key: ' + inputRef.key + ' | Error: ' + error.name + ' | Message: ' + error.message
    const ntf = {
      type: 'danger',
      message: msg
    }
    updateNotification(ntf, true)
  })
}

function assignContactToDIO(contact, contactType, inputRef){
  console.log('assigning new contact...')

  DocumentService.assignContact(
    contactType,
    contact,
    inputRef
  )
  .then(response => {
    console.log(response)
    // resolveAttachments(response.data, inputReference)
  })
  .catch(error => {
    console.log(error)
    const msg = 'Issue encountered assigning contact, please report this. Key: ' + inputRef.key + ' | Error: ' + error.name + ' | Message: ' + error.message
    const ntf = {
      type: 'danger',
      message: msg
    }
    updateNotification(ntf, true)
  })
}

function removeDIOAttachment(inputRef, contactType, contact, replacementId) {
  console.log('remove dio attachment (logo)')
  DocumentService.deleteDIOAttachment(inputRef)
  .then(response => {
    console.log('delete ok')
    if (response.status == 200 && contact && replacementId) {
      assignLogoToDIO(contact, replacementId, inputRef)
    } else {
      // remove reference from input
      resolveAttachments(null, inputRef)
    }
  })
  .catch(error => {
    console.log(error)
    const msg = 'Issue encountered removing attachment, please report this. Key: ' + inputRef.key + ' | Error: ' + error.name + ' | Message: ' + error.message
    const ntf = {
      type: 'danger',
      message: msg
    }
    updateNotification(ntf, true)
  })
}


// update Document Item override
const updateDIwithOverride = (inputObject, attr, newValue, docItemId) => {
  console.log("Update Doc Item w/ Override", attr, newValue, docItemId)


  let inputProperties = inputObject.properties
  let doc_item

  if (docItemId) {
    doc_item = inputObject.document_items[docItemId.toString()]
  } else {
    doc_item = Object.values(inputObject.document_items).find(di => di.selected)
  }

  let locale = {
    locale: doc_item.locale
  }

  let key = `${doc_item.item_type}_${doc_item.selectable_id.toString()}`

  let overrides = {}

  // for some reason override_value_price is the property for value_price
  let overridingProp = 'allow_override_' + attr
  
  if (attr == 'value_price') {
    overridingProp = 'allow_override_price'
  } 

  if (inputProperties[overridingProp] == 'true') {
    const overrideType = 'override_' + attr
    overrides[overrideType] = {}
    overrides[overrideType][key] = newValue
  }

  let id = inputObject.document_input_object.id

  DocumentService.updateDIwithOverride(
    id,
    overrides,
    locale
  )
    .then(response => {
      // perhaps add a subtle notification to the user
      console.log('updated DIO via DI: success. ResolveDocumentItems')
      resolveDocumentItems(response.data.document_items, inputObject)
    })
    .catch(error => {
      console.log(error)
      const msg = 'Issue encountered updating the document item, please report this. Key: ' + inputObject.key + ' | Error: ' + error.name + ' | Message: ' + error.message
      const ntf = {
        type: 'danger',
        message: msg
      }
      updateNotification(ntf, true)
    })
}


export { updateDIO, updateDIwithOverride, updateDIOAttachment, removeDIOAttachment, assignContactToDIO, assignLogoToDIO }