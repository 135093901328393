import { securedAxiosInstance } from '@/axios/index.js'
// import { resolveQueryUrl } from '@/axios/requestMethods.js'

export default {
  getTemplates(query, limit, page) {
    var baseUrl = "/published_templates.json"
    
    if (query) {
      for (const [key, value] of Object.entries(query)) {
        const resolvedQuery = (baseUrl.includes('?') ? '&' : '?') + key + '=' + value
        baseUrl = baseUrl + resolvedQuery
      }

    }
    
    // Query limit
    if (limit) {
      baseUrl = baseUrl + (baseUrl.includes('?') ? '&' : '?') + 'limit_value=' + limit
    }
    // Pagination
    if (page) {
      baseUrl = baseUrl + (baseUrl.includes('?') ? '&' : '?') + 'page=' + page
    }
    
    return securedAxiosInstance.get(baseUrl)
  },
  getTemplate(id) {
    return securedAxiosInstance.get(`/template/${id}.json`)
  },
  selectTemplate(template_code) {
    return securedAxiosInstance.get(`/documents/${template_code}/select`)
  },
  sendEmail(layout_id, emailInfo) {
    return securedAxiosInstance.post(
      `documents/${layout_id}/send_email`,
      emailInfo
    )
  }
}
