import { createStore } from "vuex"
import NewSession from '@/services/NewSessionService.js'

const store = createStore({
  state: {
    session: {
      isAuth: false,
      lastVisitedRoute: null,
      documentLocale: 'en',
      uiLocale: 'en',
      loadingInProgress: false,
      modBoxesVisible: true,
    },
    device: {
      dpr: null,
      defaultImgSize: 'normal',
    },
    user: {
      locations: [],
      profile: null,
      latestDocuments: [],
      logos: [],
      activeDashboard: null,
      dashboards: [],
      templateCategories: [],
    },
    currentDocument: {
      sharedSelection: {},
      layoutHeight: null
    },
    collections: {},
    templates: {
      updated_at: null,
      latest: [],
      index: []
    },
    documents: {
      updated_at: null,
      latest: [],
      index: [],
      pagination: {}
    },
    contacts: {
      updated_at: null,
      people: {
        index: [],
        pagination: {}
      },
      organizations: {
        index: [],
        pagination: {}
      }
    }
  },
  getters: {
    imageUrl(state) {
      return state.device.defaultImgSize + '_url'
    },
    inputContainerHeight(state) {
      return state.currentDocument.layoutHeight
    },
    landingModsVisible(state) {
      return state.session.modBoxesVisible
    },
    profile(state) {
      return state.user.profile
    },
    dashboards(state) {
      return state.user.dashboards
    },
    activeDashboard(state) {
      return state.user.activeDashboard
    },
    siteLogoItem(state){
      return state.user.activeDashboard && state.user.activeDashboard.dashboard_items ? state.user.activeDashboard.dashboard_items.find(di => di.code == 'site_logo') : null
    },
    templateGroups(state) {
      return state.user.activeDashboard && state.user.activeDashboard.dashboard_items ? state.user.activeDashboard.dashboard_items.find(di => di.code == 'template_groups') : null
    },
    templateCategories(state) {
      return state.user.templateCategories
    }
  },
  mutations: {
    authenticate(state, csrf) {
      console.log("authenticated!")
      state.session.isAuth = true
      localStorage.csrf = csrf
      localStorage.signedIn = true
    },
    unauthenticate(state, lastRoute) {
      console.log("unauthenticated! ", 'last route visited ->', lastRoute)
      state.session.isAuth = false
      state.session.lastVisitedRoute = lastRoute
      
      delete localStorage.csrf
      delete localStorage.signedIn
    },
    updateNextRoute(state, newRoute){
      console.log('save next route ', newRoute)
      state.session.lastVisitedRoute = newRoute
    },
    updateDocLocale(state, locale) {
      state.session.documentLocale = locale
      console.log('new doc locale from vuex: ' + locale)
    },
    updateUILocale(state, locale) {
      state.session.uiLocale = locale
      console.log('new ui locale from vuex: ' + locale)
    },
    updateDPR(state, dpr_value) {
      var new_dpr = dpr_value ? Math.floor(dpr_value) : 0
      state.device.dpr = new_dpr

      if (new_dpr > 1) {
        state.device.defaultImgSize = 'retina'
      } else {
        state.device.defaultImgSize = 'normal'
      }
    },
    updateUserLocations(state, locations) {
      state.user.locations = locations
    },
    updateUserProfile(state, profile) {
      state.user.profile = profile
    },
    clearLastVisit(state){
      state.session.lastVisitedRoute = null
    },
    saveUserProfile(state, profile){
      console.log('saved user profile', profile)
      state.user.profile = profile
    },
    saveUserLocations(state, locations){
      state.user.locations = locations
    },
    saveUserLogos(state, logos){
      state.user.logos = logos
    },
    saveUserLatestDocs(state, documents){
      state.documents.latest = documents
    },
    saveLatestTemplates(state, templates){
      state.templates.latest = templates
    },
    saveTemplateIndex(state, templates){
      state.templates.updated_at = new Date().getTime()
      state.templates.index = templates
    },
    saveDocumentIndex(state, documents){
      state.documents.updated_at = new Date().getTime()
      state.documents.index = documents
    },
    saveContactIndex(state, contactObject){
      state.contacts.updated_at = new Date().getTime()
      state.contacts[contactObject.contactType].index = contactObject.data

      if (contactObject.pagination){
        state.contacts[contactObject.contactType].pagination = contactObject.pagination
      }
    },
    expireCache(state, object){
      state[object].updated_at = null
    },
    saveCollection(state, collection){
      if (collection.code && !state.collections[collection.code]) {
        state.collections[collection.code] = collection
      }
    },
    saveDocumentInfo(state, object){
      const key = object.key
      const value = object.value

      state.currentDocument[key] = value
    },
    clearUserInformation(state){
      state.user.locations = []
      state.user.profile = null
      state.user.latestDocuments = []
      state.user.logos = []
      state.user.activeDashboard = null
      state.user.dashboards = null
      state.user.templateCategories = []

      state.templates.updated_at = null
      state.documents.updated_at = null
      state.contacts.updated_at = null
    },
    clearDocumentSelection(state){
      state.currentDocument.sharedSelection = {}
    },
    clearSingleDocumentSelection(state, key){
      delete state.currentDocument.sharedSelection[key]
    },
    saveDocumentSelection(state, object){
      const key = object.key
      const value = object.value
      const isIncrement = object.increment

      if (isIncrement && state.currentDocument.sharedSelection[key]) {
        if (Array.isArray(state.currentDocument.sharedSelection[key])) {
          state.currentDocument.sharedSelection[key] = state.currentDocument.sharedSelection[key].concat(value)
        }
      } else {
        state.currentDocument.sharedSelection[key] = value
      }
    },
    editCollection(state, object) {
      // object = {
      //   key: 'abc',
      //   item: ''
      //   operation: 'add'
      // }
      const key = object.key
      const item = object.item
      const operation = object.operation
      const collection = state.currentDocument.sharedSelection[key]

      if (collection && Array.isArray(collection)) {
        if (operation == 'remove') {
          const itemIndex = state.currentDocument.sharedSelection[key].indexOf(item)
          state.currentDocument.sharedSelection[key].splice(itemIndex, 1)

        } else {
          state.currentDocument.sharedSelection[key].push(item)
        }
      }
    },
    toggleLoading(state, boolean) {
      state.session.loadingInProgress = boolean
    },
    toggleLandingMods(state, boolean) {
      state.session.modBoxesVisible = boolean
    },
    saveDashboards(state, dashboards) {
      state.user.dashboards = dashboards
    },
    activateDashboard(state, dashboard) {
      console.log('activated: ', dashboard)
      state.user.activeDashboard = dashboard

      if (typeof(dashboard) != 'string') {
        const recentDocuments = dashboard.dashboard_items.find(di => di.code == 'recent_documents')
        const recentTemplates = dashboard.dashboard_items.find(di => di.dashboard_type == 'templates')
        
        if (recentDocuments) {
          state.documents.latest = recentDocuments.recent_documents
        }

        if (recentTemplates) {
          state.documents.latest = recentTemplates.templates
        }
      }
      
    },
    saveTemplateCategories(state, categories) {
      state.user.templateCategories = categories
    },
    updatePagination(state, pagination) {
      state.documents.pagination = pagination
    }
  },
  actions: {
    async loadUserProfile(context){
      var response = await NewSession.getProfile();
      context.commit("saveUserProfile", response.data);
    },
    async loadUserLocations(context){
      var response = await NewSession.getLocations();
      context.commit("saveUserLocations", response.data);
    },
    async loadUserLogos(context){
      var response = await NewSession.getLogos();
      context.commit("saveUserLogos", response.data)
    },
    async loadUserLatestDocuments(context){
      var response = await NewSession.myLatestDocuments(8);
      context.commit('saveUserLatestDocs', response.data)
    },
    async loadLatestTemplates(context){
      var response = await NewSession.getTemplates(30)
      context.commit('saveLatestTemplates', response.data)
    },
    async saveTemplateIndex({commit}, data){
      commit('saveTemplateIndex', data)
    },
    async saveDocumentIndex({commit}, data){
      commit('saveDocumentIndex', data)
    },
    async saveContactList({ commit }, contactObject){
      commit('saveContactIndex', contactObject)
    },
    async expireObjectCache({ commit }, object){
      commit('expireCache', object)
    },
    async clearDocumentSelection({commit}) {
      commit('clearDocumentSelection')
    },
    async clearSingleDocumentSelection({ commit }, key) {
      commit('clearSingleDocumentSelection', key)
    },
    async saveCollection({ commit }, object) {
      commit('saveCollection', object)
    },
    async saveDocumentSelection({ commit }, object) {
      commit('saveDocumentSelection', object)
    },
    async editCollection({ commit }, object) {
      commit('editCollection', object)
    },
    async saveDocumentInfo({ commit }, object) {
      commit('saveDocumentInfo', object)
    },
    async toggleLoading({ commit }, boolean) {
      commit('toggleLoading', boolean)
    },
    async loadPreferredDashboard(context) {
      // const response = await 

      try {
        const response = await NewSession.getPreferredDashboard()
        const dashboard = response.data
        if (dashboard) {
          const templateGroups = dashboard.dashboard_items.find(di => di.code == 'template_groups')
          const templateCategoryCodes = templateGroups.groups.map(tg => tg.code)
          const groupCalls = templateCategoryCodes.map(cc => NewSession.getTemplateCategory(cc))

          const categoryRequests = await Promise.all(groupCalls)
          const categories = categoryRequests.map(r => r.data)
          
          context.commit('saveTemplateCategories', categories)
          context.commit('activateDashboard', dashboard)

        } else {
          // get documents and templates
          context.commit('activateDashboard', 'not found')
          context.dispatch('loadUserLatestDocuments')
          context.dispatch('loadLatestTemplates')
        }
      } catch (e) {
        console.log(e)
        context.commit('activateDashboard', 'not found')
      }

      
    },
    async loadDashboards(context) {
      const response = await NewSession.getDashboards()
      const dashboards = response.data.dashboards || []
      context.commit('saveDashboards', dashboards)

    }
  },
  modules: {}
})

export default store