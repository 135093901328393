<template>
  <div class="buttons are-small is-right language-options" v-show="hasMultipleLanguages">
    <button class="button is-white is-loading no-focus" v-if="isLoading">
    </button>
    <button class="button is-ghost p-0 no-focus"
      v-for="language in options"  
      :key="code(language)" 
      :title="code(language) == dmtLocale ? language.name + ' (active)' : language.name"
      :class="{'is-active': language.code == dmtLocale}"
      @click="newLocale(language)">
      <country-flag :country="language.country" size="normal" />
      <span class="lang-code" :class="{'is-active': code(language) == dmtLocale}">
        {{ code(language).length > 2 ? language.code.substring(0,2).toUpperCase() : code(language).toUpperCase() }}</span>
    </button>
  </div>
</template>

<script>
import { inject, computed } from 'vue'
// https://github.com/P3trur0/vue-country-flag
import CountryFlag from 'vue-country-flag-next'

export default {
  components: {
    CountryFlag 
  },
  props: {
    options: {
      type: [Array],
      required: true,
      default: () => {
        return []
      }
    },
    loadingDocument: {
      type: Boolean,
      default: false
    }
  },
  emits: ['select'],
  setup(props, { emit }) {
    const dmtLocale = inject('dmtLocale')
    const hasMultipleLanguages = computed(() => props.options.length > 1)
    const newLocale = (language) => {
      emit('select', code(language))
    }

    const code = lang => {
      return lang.code ?? lang.locale
    }

    const isLoading = computed(() => props.loadingDocument)

    return {
      newLocale,
      dmtLocale,
      hasMultipleLanguages,
      code,
      isLoading
    }
  }
}
</script>