<!-- asks for email -->
<template>
  <Notification
    v-model="notificationVisible"
    :type="notification.type"
    @dismiss="dismissNotification"
  >
    {{ notification.message }}
  </Notification>

  <form @submit.prevent="requestToken" accept-charset="utf-8">
    <h3 class="title is-3 is-spaced"><span class="outlined yellow">{{ t('password_request.title')}}</span></h3>
    <p class="subtitle is-6">{{ t('password_request.subtitle')}}</p>
    <div class="field">
      <label class="label">{{t('login_page.email')}}</label>
      <div class="control has-icons-left">
        <input
        type="text"
        v-model="email"
        placeholder="E-mail"
        class="input"
        />
        <span class="icon is-small is-left">
           <font-awesome-icon icon="user" />
        </span>
      </div>
    </div>

    <div class="field m-space">
      <div class="helper-links">
        <a href="#" role="button" @click.prevent="cancelResetPwd()" class="helper"
            >
            {{ t("password_request.cancel")}}</a>
      </div>
    </div>

    <div class="field is-grouped is-grouped-right">
      <div class="control">
        <button class="button is-primary">
          {{ t('buttons.reset')}}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { ref, reactive } from 'vue'
import LoginService from '@/services/LoginService.js'
import { useI18n } from 'vue-i18n'

import { 
  dismissNotification,
  notificationVisible,
  updateNotification
} from '@/shared/setup/notifications.js'

export default {
  emits: ['cancel-reset-pwd'],
  setup(props, { emit }){
    // translations
    const { t } = useI18n()
    const email = ref(null)
    const notification = reactive({
      message: null,
      type: null
    })

    // methods
    const requestToken = () => {
      let credentials = {
        email: email.value
      }

      LoginService.resetPasswordRequest(credentials)
        .then(() => {
          notification.message = t("password_request.success")
          notification.type = 'success'

          updateNotification(notification, true)
        })
        .catch(error => {
          console.log(error)
        })
    }
    const cancelResetPwd = () => {
      emit('cancel-reset-pwd', 0)
    }

    return {
      t,
      email,
      notification,
      cancelResetPwd,
      requestToken,
      dismissNotification,
      notificationVisible,
    }
  }
}
</script>
