import { plainAxiosInstance } from '@/axios/index.js'

export default {
  authenticate(credentials) {
    return plainAxiosInstance.post('/signin', {
      login: credentials.login,
      password: credentials.password
    })
  },
  resetPasswordRequest(credentials) {
    return plainAxiosInstance.post('/password_resets', {
      email: credentials.email
    })
  },
  resetPassword(new_passwords, token) {
    return plainAxiosInstance.patch('/password_resets/' + token, {
      password: new_passwords.password,
      password_confirmation: new_passwords.password_confirmation
    })
  },
  logout() {
    return plainAxiosInstance.get('/signout.json')
  }
}
