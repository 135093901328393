<template>
  <Notification
    v-model="notificationVisible"
    :type="notification.type"
    @dismiss="dismissNotification"
  >
    {{ notification.message }}
  </Notification>
  <section class="section">
    <div class="container is-max-widescreen">
      <HeaderTitle
        :title="t('account.title')"
        :subtitle="t('account.subtitle')"
        :isOutlined="true"
      ></HeaderTitle>
    </div>
  </section>
  <div class="has-gray-bg">
    <div class="container is-max-widescreen">
      <div class="tabs pt-4 offset-pl">
        <ul>
          <li :class="{'is-active': tabs.active == tab}" v-for="(tab, index) in tabs.list" :key="index">
            <a @click.prevent="tabs.active = tab">{{ t(`account.${tab}.title`) }}</a>
          </li>
        </ul>
      </div>

      <section class="pb-6">
        <!-- login info -->
        <div v-show="tabs.active == 'login'">
          <div class="box">
            <AccountForm
            ></AccountForm>
          </div>
        </div>

        <!-- user logos -->
        <div v-show="tabs.active == 'logos'" >
          <div class="columns is-multiline">
            <LogoCard 
            v-for="logo in logos"
            :logo="logo" 
            :key="logo.id"
            class="is-2 column"
            >
            </LogoCard>
          </div>
        </div>
        <!-- user locations -->
        <div v-show="tabs.active == 'locations'" >
          <div class="columns is-multiline" v-if="locations.length">
            <LocationCard
              v-for="location in locations"
              :key="location.id"
              :location="location"
              class="is-4 column mb-3"
            ></LocationCard>
          </div>
        </div>

        <!-- company info -->
        <!-- <div v-show="tabs.active == 'company'" >
          <div class="box">
            <p>Company info</p>
          </div>
        </div> -->

        <!-- company info -->
        <div v-show="tabs.active == 'dashboards'" v-if="activeDashboard && dashboardOptions">
          <div class="box">
            <p>Current active dashboard: <strong>{{ activeDashboard.name ?? 'None yet' }}</strong></p>

            <div class="columns py-4">
              <div class="column">
                <div class="field has-addons" v-if="dashboardOptions.length">
                  <div class="control">
                      <div class="select">
                        <select v-model="newDashboard">
                          <option value="">- Select -</option>
                          <option :value="dashboard.id" v-for="dashboard in dashboardOptions" :key="dashboard.id">
                            {{ dashboard.name }}
                          </option>
                        </select>
                      </div>
                  </div>
                  <div class="control">
                    <a class="button is-primary" @click.prevent="activateDashboard()">
                      Activate
                    </a>
                  </div>
                </div>
                <Loading :loading="true" v-else></Loading>
              </div>

              <div class="column has-text-right">
                <button class="button is-primary" @click.prevent="resetDemos()">Reset for new demo</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  
</template>

<style lang="scss">
@import '@/assets/stylesheets/module_specific/location.scss'
</style>

<script>
import UserService from '@/services/UserService.js'

import { inject, computed, ref, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import HeaderTitle from '@/components/shared/HeaderTitle.vue'
import LocationCard from '@/components/shared/LocationCard.vue'
import LogoCard from '@/components/account/LogoCard.vue'
import AccountForm from '@/components/account/AccountForm.vue'

import { 
  notificationVisible, 
  notification, 
  dismissNotification, 
  updateNotification
} from '@/shared/setup/notifications.js'

export default {
  name: 'User account',
  setup(){
    const { t } = useI18n()
    const store = useStore()
    const settings = inject('clientGlobalSettings')
    const locations = computed(() => store.state.user.locations || [])
    const logos = computed(() => store.state.user.logos || [])
    
    const tabs = reactive({
      list: ["login", "dashboards", "logos", "locations"],
      active: "login"
    })
    const activeDashboard = computed(() => store.getters.activeDashboard || null)
    const dashboardOptions = computed(() => store.state.user.dashboards || [])
    const contentClass = ref("is-offset-4")
    const newDashboard = ref()

    const activateDashboard = () => {
      UserService.updatePreferredDashboard(newDashboard.value)
      .then((response) => {
        let notification = {
          type: 'success',
          message: 'Dashboard preference updated successfully.'
        }

        if (!response.data || response.data.type != 'dashboards') {
          notification.type = 'danger'
          notification.message = 'Issue encoutered updating the preferrence. Invalid dashboard response.'
        }

        updateNotification(notification, true)
        
      })
      .catch((e) => {
        const notification = {
          type: 'danger',
          message: 'Issue encoutered updating the preferrence. ' + e.message
        }
        updateNotification(notification, true)
      })
    }

    const resetDemos = () => {
      store.commit('toggleLandingMods', true)
      store.dispatch('loadPreferredDashboard')
      
      const notification = {
        type: 'success',
        message: 'Landing page restored.'
      }

      updateNotification(notification, true)
    }



    store.dispatch('loadDashboards')

    return { 
      t,
      settings,
      locations,
      logos,
      contentClass,
      tabs,
      activeDashboard,
      dashboardOptions,
      newDashboard,
      activateDashboard,
      resetDemos,
      notificationVisible, 
      notification, 
      dismissNotification, 
      updateNotification
    }
  },
  components: {
    HeaderTitle,
    LocationCard,
    AccountForm,
    LogoCard
  }
}
</script>
