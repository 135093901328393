<template>
  <div class="is-relative pdf-options p-3">
    <div class="pb-4">
      <h3 class="title is-5">
        <slot>{{ t('output.' + option.content_type + '.content_title') }}</slot>
      </h3>
    </div>
    <p>{{ t('output.pdf.content') }}</p>
    <div class="mt-3 published-actions">
      <table>
        <tr v-for="locale in option.locales" :key="locale.locale" class="flag-link">
          <td>
            <country-flag :country="locale.country" size="normal" />
            <span><a :href="locale.url" target="_blank">{{ locale.locale_name }}</a></span>
          </td>
          <td>
            <span class="eq-padding">
              <input
                type="text"
                class="displaced"
                :value="locale.url"
                :id="`url_${locale.locale}`"
              />
              <a class="icon-text pt-1" role="button" @click.prevent="copyLink(locale.locale)">
                <span class="icon mr-4">
                  <font-awesome-icon icon="clipboard" size="sm" />  
                </span>
                <span>{{ t('helpers.copy') }} {{ t('helpers.link').toLowerCase() }}</span>
              </a>
            </span>
          </td>
        </tr>
      </table>
      

      <p class="pt-2">
        <a role="button" @click.prevent="massDownload()">
          <span class="icon-text pr-4" :class="zipClass">
            <span class="icon" style="height:1.6rem;width:1.6rem;">
            <font-awesome-icon icon="file-pdf" />
          </span>
          <span class="pl-2">{{ t('output.pdf.download_all') }}</span>
          </span>
        </a>
      </p>
    </div>
  </div>
</template>

<script>
  import { forceFileDownload } from '@/shared/setup/documentMethods.js'
  import DocumentService from '@/services/DocumentService.js'
  import CountryFlag from 'vue-country-flag-next'
  import { useI18n } from 'vue-i18n'
  import { ref } from 'vue'

  export default {
    components: {
      CountryFlag
    },
    props: {
      option: {
        type: Object,
        required: true
      }
    },
    emits: ['notify'],
    setup(props, { emit }) {
      const { t } = useI18n()
      const downloadInProgress = ref(false)
      const zipClass = ref('')
      
      const massDownload = () => {
        if (!downloadInProgress.value) {
          downloadInProgress.value = true
          zipClass.value = 'animate__animated animate__bounce'

          window.setTimeout(() => {
            zipClass.value = ''
          }, 2000)

          DocumentService.requestZip(props.option.document_id)
          .then(response => {
            console.log(response.data)
            forceFileDownload(response)

            downloadInProgress.value = false
          })
          .catch(error => {
            console.log('Issue download zip', error)
            downloadInProgress.value = false
            const ntf = {
              message: 'Issue requesting files. Please try again, and report it if the issue persists.',
              type: 'danger'
            }

            emit('notify', ntf)
          })
        }
        
      }

      const copyLink = (locale) => {
        /* Get the text field */
        const copyText = document.getElementById(`url_${locale}`)
        const feedbackSpan = copyText.nextElementSibling.lastElementChild
        /* Select the text field */
        copyText.select()
        copyText.setSelectionRange(0, 99999) /*For mobile devices*/

        /* Copy the text inside the text field */
        document.execCommand('copy')

        feedbackSpan.innerHTML = t('helpers.copied')

        window.setTimeout(() => {
          feedbackSpan.innerHTML = t('helpers.copy') + ' ' + t('helpers.link').toLowerCase()
        }, 2000)
      }

      return { t, massDownload, downloadInProgress, zipClass, copyLink }
    }
  }
</script>