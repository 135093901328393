import { securedAxiosInstance } from '@/axios/index.js'

export default {
  emailDocument(id, contents) {
    return securedAxiosInstance.post(
      '/documents/' + id + '/send_email.json',
      contents
    )
  },
  updateDocument(id, document) {
    return securedAxiosInstance.patch('/update_document/' + id, {
      document
    })
  }
}
