<template>
  <div :style="computedStyle">
    <label class="label" v-if="label">
      {{ label }}
    </label>
    <!-- refactor for user logos (using store logos) -->
    <div v-if="isCategorized && options.length" class="columns">
      <div
        class="column"
        v-for="category in options"
        :key="category.category + category.id"
      >
        <p class="mb-3">{{ category.category }}</p>
        <div class="mb-5">
          <label
            v-for="item in category.items"
            :key="item.id" 
            class="radio" 
            :for="`${input.id}_${item.id.toString()}_select`">
            <input type="radio" v-model="computedValue" :value="item.id" :id="`${input.id}_${item.id.toString()}_select`">
            <img :src="item.attachments[0].url" alt="" style="width:40px"
          />
          </label>
        </div>
      </div>
    </div>

    <div v-else-if="options.length">
      <label
        v-for="item in options"
        :key="item.id" 
        class="radio" 
        :for="`${input.id}_${item.id.toString()}_select`">
        <input type="radio" v-model="computedValue" :value="item.id" :id="`${input.id}_${item.id.toString()}_select`">
        <img :src="item.url" alt="" style="width:40px"/>
      </label>
    </div>
    
    <div v-else-if="customOptions.length">
      <label
        v-for="item in customOptions"
        :key="item.id" 
        class="radio" 
        :for="`${input.id}_${item.id.toString()}_select`" style="padding-right:8px">
        <input type="radio" v-model="computedValue" :value="item.id" :id="`${input.id}_${item.id.toString()}_select`">
        <img :src="item.url" alt="" style="max-width:40px;max-height: 70px;"/>
      </label>
    </div>
  </div>
</template>

<script>
  import { useStore } from 'vuex'
  import { inject, computed, watch, ref } from 'vue'
  import { default as ProductService } from '@/services/DamService.js'

  export default {
    props: {
      input: {
        type: Object
      },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }){
      const label = computed(() => props.input.properties.label)
      const store = useStore()
      const groupSelect = inject('groupSelect')
      const groupContentType = computed(() => props.input.group.content_type || props.input[0] )
      const groupFromSelection = computed(() => props.input.properties['vue3_selection_group'])
      const userSelection = computed(() => store.state.currentDocument.sharedSelection)
      const watchedSelection = computed(() => userSelection.value[groupFromSelection.value] || [])

      const computedValue = computed({
        get: () => {
          const di = Object.values(props.input.document_items).find(di => di.selected == true)
          return di.selectable_id
          
        },
        set: (value) => {
          emit('update:modelValue', [groupContentType.value, value], props.input)
        }
      })

        // 


      const options = computed(() => {
        const fromGs = groupSelect[props.input.group.code]
        
        return fromGs ? fromGs : []
      })

      const customOptions = ref([])

      // exception for select supplier and supplier image... very custom. Need to rethink this
      const getOptions = async () => {
        const code = props.input.group.code
        const source_input = props.input.group.source_document_object_id
        
        if (code && source_input) {
          ProductService.getInputSuppliers(
            code,
            source_input,
          )
          .then(response => {
            const options = response.data.items
            customOptions.value = options
          })
          .catch(error => {
            console.log('there was an error ' + error.response)
          })
        }
      }

      const isCategorized = computed(() => options.value.length && options.value[0].category)

      if (groupFromSelection.value) {
        getOptions()
      }

      watch(watchedSelection.value, () => {
        getOptions()
      })

      const computedStyle = computed(() => {
        let style

        if (groupFromSelection.value && watchedSelection.value && watchedSelection.value.length == 0) {
          style = {
            display: 'none'
          }
        }

        return style
      })

      return {
        label,
        isCategorized,
        options,
        customOptions,
        computedValue,
        computedStyle,
        watchedSelection
      }
    }
  }
</script>