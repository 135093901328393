import { securedAxiosInstance } from '@/axios/index.js'

export default {
  getProfile() {
    return securedAxiosInstance.get('/me')
  },
  getLocations() {
    var url = '/locations'
    return securedAxiosInstance.get(url)
  },
  getLogos() {
    return securedAxiosInstance.get('/logo_image')
  },
  getTemplates(limit) {
    return securedAxiosInstance.get(`/published_templates.json?limit_value=${limit}`)
  },
  myLatestDocuments(limit){
    return securedAxiosInstance.get(`/documents.json?limit_value=${limit}`)
  },
  getPreferredDashboard() {
    return securedAxiosInstance.get('/preferred_dashboard')
  },
  getDashboards() {
    return securedAxiosInstance.get('/dashboards')
  },
  getTemplateCategory(code){
    return securedAxiosInstance.get('/group_select/' + code)
  }
}
