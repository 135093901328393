// Filter related
import { DateTime } from 'luxon';

function truncate(data, num, delimiter) {
  if (data) {
    if (data.length > num) {
      const reqdString =  data.split('').slice(0, num).join('');
      return delimiter ? reqdString + delimiter : reqdString;
    } else {
      return data
    }
  } else {
    return ''
  }
  
}

function calculateAgo(value) {
// "YYYYMMDD"
  return DateTime.fromISO(value).toRelative()
}

function formatDate(value, format) {
  if (value) {
    return DateTime.fromISO(value).toFormat(format)
  }
}

export { truncate, calculateAgo, formatDate }
