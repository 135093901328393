<template>
  <div class="field h-image-selection">
    <label class="label" v-if="label">
      {{ label }}
    </label>
    <vue-horizontal v-if="options.length">
      <div class="item" v-for="option in options" :key="option.id">
        <img class="is-clickable" :class="{'selected': computedValue == option.id }" :src="option.attachments[0].preview_url" @click="computedValue = option.id"/>
      </div>
    </vue-horizontal>
    <div v-else>
      <Loading :loading="options.length == 0"></Loading>
    </div>

    <field-message v-if="inputError.message.length" :object="inputError">
      {{ inputError.message }}
    </field-message>
  </div>
</template>


<script>
import { inject, computed, ref } from 'vue'
import { inputError, updateInputError } from '@/shared/setup/notifications.js'
import { getGroupOptions } from '@/shared/setup/inputTypeHelpers.js'
import VueHorizontal from 'vue-horizontal'

export default {
  components: {
    VueHorizontal
  },
  props: {
    input: {
      type: Object,
      required: true
    },
    // compModelVal: {
    //   type: [String, Number, Boolean, Array, Object],
    //   default: ''
    // },
  },
  setup(props, { emit }) {
    const locale = inject('dmtLocale')
    const groupSelectName = computed(() => props.input.group.code)
    const groupSelect = inject('groupSelect')
    const computedValue = computed({
      get: () => {
        // return props.compModelVal.value[1]
        return props.input.value_array[1]
      },
      set: value => {
        const groupContentType = props.input.group.content_type || props.input.value_array[0]
        emit('update:modelValue', [groupContentType, value], props.input)
      }
    })

    const label = ref(props.input.properties.label)
    const placeholder = ref(props.input.properties.placeholder)

    const options = computed(() => {
      return getGroupOptions(groupSelect, groupSelectName.value, 'items')
    })

    return {
      inputError,
      updateInputError,
      locale,
      computedValue,
      options,
      label,
      placeholder
    }
  }
}
</script>
