<template>
  <div class="field">
    <label class="label" v-if="label">
      {{ label }}
    </label>
    <textarea
      v-model="computedValue"
      :class="fieldClasses"
      :maxLength="textLimit"
      v-if="showAttribute('value_text')"
      @change="emitUpdate"
      :rows="rows"
    ></textarea>

    <p class="control has-currency" v-else-if="showAttribute('value_price')">
      <input type="text" :class="fieldClasses" :value="currency" readonly>
      <input
        type="number"
        min="1"
        v-model="computedValue"
        :class="fieldClasses"
        :maxLength="textLimit"
        @change="emitUpdate($event)"
      />
    </p>

    <input
      type="text"
      v-model="computedValue"
      :class="fieldClasses"
      :maxLength="textLimit"
      @change="emitUpdate"
      v-else-if="showAttribute('value') || showAttribute('value1') || showAttribute('value2')"
    />
  </div>
</template>

<script>
  import { computed, inject } from 'vue'

  export default {
    props: {
      input: {
        type: Object
      },
      modelValue: {
        type: [Array, String, Number, Object]
      },
      attribute: {
        type: String
      },
      size: {
        type: String
      },
      item: {
        type: Object
      },
      hideLabel: {
        type: Boolean,
        default: false
      },
      maxLength: {
        type: [String, Number]
      }
    },
    emits: ['update:documentItem', 'update:immediate'],
    setup(props, { emit }) {
      const dmtLocale = inject('dmtLocale')
      const rows = computed(() => props.input.properties.text_area_rows || null)
      const textLimit = computed(() => props.maxLength || props.input.properties.text_area_size || props.input.properties.text_field_size || null)

      const documentItem = computed(() => props.item ? props.item : Object.values(props.input.document_items).find(di => di.selected))

      const label = computed(() => {
        if (hasHiddenAttributes.value && hasHiddenAttributes.value.includes(props.attribute) || props.hideLabel) {
          return false
        } else {
          return documentItem.value[props.attribute + '_label'] ? documentItem.value[props.attribute + '_label'] : props.input.properties.label ? props.input.properties.label : null
        }
      })
      const translation = computed(() => documentItem.value[dmtLocale.value])

      const computedValue = computed({
        get: () => {
          if (props.attribute == 'value_price') {
            return (translation.value.value_price / 100).toFixed(2)
          } else {
            return translation.value[props.attribute] 
          }
        },
        set: value => {
          // immediate update of the value set. Emit function below via emitUpdate
          // value_price requires manipulation, so we skip.
          if (props.attribute != 'value_price') {
            translation.value[props.attribute] = value
            // emit('update:immediate', value)
            // new
            const diRef = documentItem.value.selectable_id
            emit('update:immediate', [props.input, diRef], props.attribute, value)
          } 
        }
      })

      const hasHiddenAttributes = computed(() => {
        if (documentItem.value.properties && documentItem.value.properties.ui_hidden) {
          return documentItem.value.properties.ui_hidden
        } else {
          return false
        }
      })

      const showAttribute = (attribute) => {
        if (hasHiddenAttributes.value) {
          return !hasHiddenAttributes.value.includes(attribute) && props.attribute == attribute 
        } else {
          return props.attribute == attribute 
        }
      }

      const fieldClasses = computed(() => {
        const baseClass = []

        if (props.attribute == 'value_text') {
          baseClass.push('textarea')
        } else {
          baseClass.push('input')
        }

        if (props.size) {
          baseClass.push('is-' + props.size)
        }

        return baseClass
      })

      const addonClass = computed(() => props.size ? 'is-' + props.size : '')

      const currency = computed(() => props.attribute == 'value_price' ? translation.value.value_price_currency : null)

      // Emits update to parent in the background.
      const emitUpdate = (ev) => {
        const diRef = documentItem.value.selectable_id
        // console.log('document item attribute - diref', diRef)
        if (props.attribute == 'value_price') {
          // transform into cents first
          const priceInCents = (ev.target.value * 100).toFixed(0)
          // emit('update:documentItem', props.input, props.attribute, priceInCents)
          emit('update:documentItem', [props.input, diRef], props.attribute, priceInCents)
        } else {
          // emit('update:documentItem', props.input, props.attribute, computedValue.value)
          emit('update:documentItem', [props.input, diRef], props.attribute, computedValue.value)
        }
      }

      return {
        computedValue,
        addonClass,
        fieldClasses,
        label,
        currency,
        emitUpdate,
        showAttribute,
        rows,
        textLimit
      }
    }
  }
</script>