<template>
  <span 
    class="icon is-small is-right pl-3 has-text-link is-clickable" 
    @click="toggleItem(option.id)" 
    v-if="['true', true].includes(allowsOverride)"
    :title="t('customize.click_override')"
    >
     <font-awesome-icon :icon="icon" :size="iconSize"/>
  </span>
  <div
    class="field pt-1 is-relative"
    v-if="['true', true].includes(allowsOverride)"
  > 
    <transition name="fade">
        <div class="override-box" v-if="editingItem.id === option.id">
          <textarea
            class="textarea"
            :rows="rows"
            v-if="documentItem"
            :disabled="!documentItem.selected"
            v-model="computedValue"
            @change="updateDocumentItem($event, option.id)"
          ></textarea>
          <textarea
            class="textarea mb-3"
            :rows="rows"
            disabled="true"
            :value="option.description"
            v-else
          ></textarea>
          <revert-to-original
            v-if="documentItem && documentItem.selected"
            :id="documentItem.id" 
            @revert="revertContent"
          ></revert-to-original>
        </div>    
    </transition>
  </div>
</template>

<script>
  import { inject, reactive, ref, computed } from 'vue'
  import { useI18n } from 'vue-i18n'
  import DocumentService from '@/services/DocumentService.js'
  import RevertToOriginal from '@/components/inputs/accessories/RevertToOriginal.vue'

  export default {
    components: {
      RevertToOriginal
    },
    props: {
      icon: {
        type: String,
        required: false,
        default: 'edit'
      },
      iconSize: {
        type: String,
        required: false,
        default: 'sm'
      },
      option: {
        type: Object,
        required: true
      },
      documentItem: {
        type: Object,
        required: false
      },
      attribute: {
        type: String,
        required: false,
        default: 'value_text'
      },
      rows: {
        type: String,
        required: false
      }
    },
    setup(props, { emit }) {
      const { t } = useI18n()
      const locale = inject('dmtLocale')
      const allowsOverride = inject('inputAllowsOverride')
      const itemId = ref(props.option.id.toString())
      const editingItem = reactive({
        id: null
      })

      const toggleItem = (id) => {
        if (editingItem.id === id) {
          editingItem.id = null
        } else {
          editingItem.id = id
        }
      }

      const translation = computed(() => props.documentItem[locale.value])
      
      const computedValue = computed({
        get: () => {
          return translation.value[props.attribute]
        },
        set: (value) => {
          translation.value[props.attribute] = value
        }
      })

      const updateDocumentItem = (ev, docItemId) => {
        // because there are multiple document items, should pass which is the one being updated
        emit('update:input', docItemId, props.attribute, ev.target.value)
      }

      const revertContent = (diId) => {
         DocumentService.getOriginalDocumentItem(diId, locale.value)
        .then(response => {
          const original = response.data[locale.value][props.attribute]
          const dmtItem = props.documentItem
          const ev = {
            target: {
              value: original
            }
          }
          // immediately updates the value
          computedValue.value = original
          // then sends update request in the background
          updateDocumentItem(ev, dmtItem.selectable_id)
        })
        .catch(error => {
          console.log('Error getting the original ' + error)
        })
      }
      return {
        t,
        allowsOverride,
        editingItem,
        toggleItem,
        itemId,
        locale,
        revertContent,
        updateDocumentItem,
        computedValue
      }
    }
  }
</script>