<template>
  <Notification
    v-model="notificationVisible"
    :type="notification.type"
    @dismiss="dismissNotification"
  >
    {{ notification.message }}
  </Notification>

  <section class="section">
    <div class="container is-max-widescreen">
      <h1 class="title is-3">
        {{ dashboardTitle }} <span class="outlined">{{ dashboardContact }}</span>
      </h1>
      <p class="subtitle is-6">
        {{ dashboardSubtitle }}
      </p>
    </div>
  </section>

  <div class="has-gray-bg" v-if="dashboard && dashboard != 'not found'">
    <section class="section" :class="modContainerClass" v-if="modBoxesVisible">
      <div class="container is-max-widescreen">
        <div class="columns is-variable is-multiline" :class="categories.length % 2 ? 'is-8' : 'is-6'">
          <div class="column" :class="categories.length % 2 ? 'is-4' : 'is-3'" v-for="(mod, i) in categories" :key="mod.id">
            <div class="box is-clickable mod-box" @click="selectMod(mod)" title="Show templates in this category" :class="[modAnimationClass, 'animate__delay-' + (i) +'s']">
              <div class="py-3 px-5 mb-3" style="max-height:240px;overflow:hidden;">
                <figure class="image" style="max-height:240px;">
                  <img :src="findImage(mod.attachments)" :alt="mod.value_label" >
                </figure>
                
              </div>
              <div class="has-text-centered px-3 py-5">
                <p class="title is-4">{{ mod.value_label }}</p>
                <p class="subtitle is-6">{{ mod.value_text }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section" v-if="!modBoxesVisible" :class="templateContainerAnimationClass">
      <div class="container is-max-widescreen">
        <div class="box">
          <div class="columns p-6 is-variable is-8 is-multiline is-centered">
            <div class="column is-4" v-for="t in activeTemplates" :key="t.name">
              <template-card-square :template="t" :parentClass="templateAnimationClass" @notify="notify"/>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div id="temp-filter" v-if="!modBoxesVisible">
      <div class="box">
        <div v-for="c in categories" :key="c">
          <span class="filterImg is-clickable" @click="selectMod(c)">
            <img :src="findImage(c.attachments, 'icon')" alt="Module icon">
          </span>
        </div>
      </div>
    </div>

    <overlay>
     <p class="title is-4">{{ t('documents.helpers.creating_new') }}</p>
    </overlay>
  </div>
  <div class="has-gray-bg" v-else-if="dashboard == 'not found'">
    <section class="section is-large" :class="modContainerClass">
      <div class="container is-max-widescreen">
        <p>It looks like you don't have a dashboard yet.</p>
      </div>
    </section>
  </div>
  <div class="has-gray-bg" v-else>
    <section class="section is-large" :class="modContainerClass">
      <div class="container is-max-widescreen has-text-centered">
        <Loading :loading="true"></Loading>
      </div>
    </section>
  </div>
  
</template>

<style lang="scss">
@import  '@/assets/stylesheets/module_specific/landing.scss'
</style>

<script>
import { computed, inject, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import TemplateCardSquare from '@/components/template/TemplateCardSquare.vue'

import { 
  notificationVisible, 
  notification, 
  dismissNotification, 
  updateNotification
} from '@/shared/setup/notifications.js'


export default {
  name: 'Demo dashboard',
  components: {
    TemplateCardSquare
  },
  props: {
    parentNotification: {
      type: String
    },
    filter: {
      type: String
    }
  },
  setup(props){
    const store = useStore()
    const { t } = useI18n()
    // const truncate = inject('truncate')
    const settings = inject('clientGlobalSettings')
    const profile = computed(() => store.state.user.profile)
    const modBoxesVisible = computed(() => store.getters.landingModsVisible)
    const imageSize = computed(() => store.getters.imageUrl)
    // animation-related
    const modAnimationClass = ref(null)
    const modContainerClass = ref(null)
    const templateAnimationClass = ref(null)
    const templateContainerAnimationClass = ref(null)

    const activeMod = ref(null)
    const dashboard = computed(() => store.getters.activeDashboard)
    const dashboardProps = computed(() => dashboard.value && typeof(dashboard.value) == 'object' ? dashboard.value.properties : [])
    
    const dashboardTitle = computed(() => {
      const prop = dashboardProps.value.find(p => p.name == 'demoTitle')
      return prop ? prop.attr_value : ''
    })
    const dashboardContact = computed(() => {
      const prop = dashboardProps.value.find(p => p.name == 'demoContactName')
      return prop ? prop.attr_value : ''
    })
    const dashboardSubtitle = computed(() => {
      const prop = dashboardProps.value.find(p => p.name == 'demoSubtitle')
      return prop ? prop.attr_value : ''
    })

    const categories = computed(() => {
      return store.getters.templateCategories
    })

    const findImage = (attachments, type) => {
      if (attachments && attachments.length) {
        let img = attachments[0]
        if (type) {
          const imgType = attachments.find(a => a.name.includes(type) || a.name_key.includes(type))

          img = imgType ? imgType : img
        }

        return img[imageSize] || img['url']
      } else {
        return ''
      }
      
    }

    const selectMod = (mod) => {
      if (modBoxesVisible.value) {
        modContainerClass.value = 'animate__animated animate__fadeOut animate__faster'
        
        window.setTimeout(() => {
          store.commit('toggleLandingMods', false)
        }, 800)
      }

      // if NOT first select
      if (activeMod.value) {
        templateAnimationClass.value = 'animate__animated animate__fadeIn animate__fast '

      } else {
        templateContainerAnimationClass.value = 'animate__animated animate__fadeInUp animate__faster'
        templateAnimationClass.value = 'animate__animated animate__fadeIn animate__fast '
      }
      
      activeMod.value = mod
    }

    // templates.value.filter(t => t.mod == activeMod.value)
    const activeTemplates = computed(() => activeMod.value ? activeMod.value.items : [])

    onMounted(() => {
      modAnimationClass.value = 'animate__animated animate__bounceInLeft animate__fast'
      
      if (!modBoxesVisible.value) {
        activeMod.value = categories.value[0]
      }

      if (props.parentNotification) {
        updateNotification(JSON.parse(props.parentNotification), true)
      }
    })

    const notify = (notification, boolean) => {
      updateNotification(notification, boolean)
    }

    

    return {
      notificationVisible,
      notification,
      dismissNotification,
      updateNotification,
      settings,
      profile,
      imageSize,
      selectMod,
      notify,
      modContainerClass,
      modAnimationClass,
      templateContainerAnimationClass,
      templateAnimationClass,
      activeMod,
      modBoxesVisible,
      dashboard,
      // templates,
      activeTemplates,
      categories,
      t,
      // truncate,
      dashboardTitle,
      dashboardContact,
      dashboardSubtitle,
      findImage 
    }
  }
}
</script>

