<template>
  <div class="field" v-show="hasPreviews">
    <div class="previewToolbar" >
      <div class="has-background-light label-box has-text-centered">
        <p class="control">
          <span class="title is-6">{{ t('preview.preview') }}</span>
        </p>
      </div>

      <div class="" v-if="currentLocaleImage">
        <a class="control is-clickable" @click="previewFormat('image')">
          <span v-if="modal.loading == 'image'" class="is-loading is-small button is-ghost">Loading</span>
          <div v-else>
            <span class="icon">
              <font-awesome-icon icon="image" size="sm" />
            </span>
            <span>{{ t('preview.image') }}</span>
          </div>
        </a>
      </div>

      <div class="" v-if="currentLocalePDF">
        <a :href="currentLocalePDF.url" target="_blank" class="control is-clickable">
          <span class="icon">
            <font-awesome-icon icon="file-pdf" size="sm" />
          </span>
          <span>{{ t('preview.pdf') }}</span>
        </a>
      </div>

      <div class="field px-3 pt-3">
        <span class="h-divider has-text-grey-dark">or</span>
      </div>
    </div>
  </div>
  

  <div class="modal" :class="{'is-active': modal.isActive}">
    <div class="modal-background"></div>
    <div class="modal-content">
      <p class="image ">
        <img :src="modal.imageUrl" :alt="t('preview.imageAlt')">
      </p>
    </div>
    <button class="modal-close is-large" aria-label="close" @click="closeModal"></button>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { inject, computed, reactive } from 'vue'
import DocumentService from '@/services/DocumentService'

import { 
  updateNotification
} from '@/shared/setup/notifications.js'

export default {
  props: {
    options: {
      type: [Array],
      required: true
    },
    activeLayoutId: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n()
    const modal = reactive({
      isActive: false,
      imageUrl: null,
      loading: false
    })
    const dmtLocale = inject('dmtLocale')
    
    const pdfOption = computed(() => {
      const outputObject = props.options.find(op => op.content_type == 'pdf' && op.document_layout_id == props.activeLayoutId)

      return outputObject
    })

    const imageOption = computed(() => {
      const outputObject = props.options.find(op => op.content_type == 'image' && op.document_layout_id == props.activeLayoutId)

      return outputObject
    })

    const currentLocalePDF = computed(() => pdfOption.value ? pdfOption.value.locales.find(loc => loc.locale == dmtLocale.value) : null)

    const currentLocaleImage = computed(() => imageOption.value ? imageOption.value.locales.find(loc => loc.locale == dmtLocale.value) : null)

    const hasPreviews = computed(() => imageOption.value || pdfOption.value )

    const previewFormat = (format) => {
      modal.loading = format

      if (format == 'image') {
        const dmtId = imageOption.value.document_id
        const layoutId = imageOption.value.document_layout_id
        DocumentService.getImagePreview(dmtId, layoutId, dmtLocale.value)
        .then(response => {
          const imageUrl = URL.createObjectURL(response.data)
          modal.isActive = true
          modal.imageUrl = imageUrl
          modal.loading = false

          document.getElementById('mainNavigation').style.zIndex = -1
          document.body.classList.add('scroll-disabled')

        })
        .catch(error => {
          console.log(error)

          const ntf = {
            message: 'Issue encountered previewing the requested format.',
            type: 'danger'
          }

          notify(ntf)
          closeModal()
          modal.loading = false
        })
      }
    }

    const closeModal = () => {
      modal.isActive = false
      modal.imageUrl = null
      document.getElementById('mainNavigation').style.zIndex = 30
      document.body.classList.remove('scroll-disabled')
    }

    const notify = (object) => {
      updateNotification(object, true)
    }

    return {
      t,
      currentLocaleImage,
      currentLocalePDF,
      hasPreviews,
      previewFormat,
      modal,
      closeModal
    }
  }
}
</script>