import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Registering a Global Components
import NavBar from '@/components/global/NavBar.vue'
import Footer from '@/components/global/Footer.vue'
import Notification from '@/components/global/Notification.vue'
import Loading from '@/components/global/Loading.vue'
import Overlay from '@/components/global/Overlay.vue'
import FieldMessage from '@/components/inputs/accessories/FieldMessage.vue'
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue'

// prime vue
import PrimeVue from 'primevue/config'

// axios
import axios from 'axios'
import VueAxios from 'vue-axios'

// Font Awesome - Icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { 
    faTwitterSquare,
    faFacebookSquare,
    faLinkedin
} from '@fortawesome/free-brands-svg-icons'

import { faGlobe,
        faCaretDown,
        faCompressAlt,
        faExpandAlt,
         faUserSecret,
         faTrash,
         faCalendar,
         faLock,
         faPlus,
         faMinus,
         faMinusCircle,
         faInfoCircle,
         faTimes,
         faClock,
         faUser,
         faKey,
         faChevronRight,
         faCheck,
         faCheckCircle,
         faArrowRight,
         faEdit,
         faFile,
         faFilePdf,
         faFileCsv,
         faFolderOpen,
         faArchive,
         faFolder,
         faPhotoVideo,
         faImage,
         faFileAlt,
         faChartLine,
         faHeart,
         faRedo,
         faUpload,
         faChevronCircleRight,
         faChevronCircleLeft,
         faEye,
         faEyeSlash,
         faAddressBook,
         faBuilding,
         faFileArchive,
         faShareSquare,
         faBan,
         faLink,
         faClipboard,
         faCode,
         faScroll,
         faShareAlt,
         faEnvelope,
         faDesktop,
         faFileCode
       } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faGlobe,
        faCaretDown,
        faCompressAlt,
        faExpandAlt,
       faUserSecret,
       faTrash,
       faCalendar,
       faLock,
       faPlus,
       faMinus,
       faMinusCircle,
       faInfoCircle,
       faTimes,
       faClock,
       faUser,
       faKey,
       faChevronRight,
       faCheck,
       faCheckCircle,
       faArrowRight,
       faEdit,
       faFile,
       faFilePdf,
       faFileCsv,
       faFolderOpen,
       faArchive,
       faFolder,
       faPhotoVideo,
       faImage,
       faFileAlt,
       faChartLine,
       faHeart,
       faRedo,
       faUpload,
       faChevronCircleRight,
       faChevronCircleLeft,
       faEye,
       faEyeSlash,
       faAddressBook,
       faBuilding,
       faFileArchive,
       faShareSquare,
       faBan,
       faLink,
       faClipboard,
       faCode,
       faTwitterSquare,
       faFacebookSquare,
       faLinkedin,
       faScroll,
       faShareAlt,
       faEnvelope,
       faDesktop,
       faFileCode
)

// client settings
import settings from '@/../client_settings.json'

// localization - defaults to EN
import { createI18n } from 'vue-i18n'
import { languages } from '@/locales/index.js'
import { defaultLocale } from '@/locales/index.js'

const translations = Object.assign(languages)

const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: defaultLocale,
    fallbackLocale: 'fr',
    messages: translations
})

import { truncate, calculateAgo, formatDate } from '@/shared/filters/date.js'

// Stylesheet - Bulma
require('@/assets/main.scss');

// animations
import 'animate.css'

// App creation
const app = createApp(App)

app.use(router)
.use(store)
.use(VueAxios, axios)
.use(i18n)
.use(PrimeVue)
.component('font-awesome-icon', FontAwesomeIcon)
.component('NavBar', NavBar)
.component('Footer', Footer)
.component('Notification', Notification)
.component('Loading', Loading)
.component('FieldMessage', FieldMessage)
.component('Overlay', Overlay)
.component('Breadcrumbs', Breadcrumbs)
.provide('clientGlobalSettings', settings)
.provide('truncate', truncate)
.provide('calculateAgo', calculateAgo)
.provide('formatDate', formatDate)
.mount('#app')

app.config.productionTip = false

// legacy methods -
app.mixin({
    methods: {
        determineDPR(){
            var dpr_value = window.devicePixelRatio
            console.log(dpr_value)
            this.$store.commit('updateDPR', dpr_value)
        },
        updateBaseURL(){
            var site_url = window.location.origin
            this.$store.commit('updateBaseURL', site_url)
        }
    }
})

app.directive("click-outside-app", {
    beforeMount: function(el, binding) {
      // Define ourClickEventHandler
      const ourClickEventHandler = event => {
        if (!el.contains(event.target) && el !== event.target) {
          // as we are attaching an click event listern to the document (below)
          // ensure the events target is outside the element or a child of it
          binding.value(event); // before binding it
        }
      };
      // attached the handler to the element so we can remove it later easily
      el.__vueClickEventHandler__ = ourClickEventHandler;

      // attaching ourClickEventHandler to a listener on the document here
      document.addEventListener("click", ourClickEventHandler);
    },
    unmounted: function(el) {
      // Remove Event Listener
      document.removeEventListener("click", el.__vueClickEventHandler__);
    }
})

