<template>
  <NavBar v-if="!['public-document'].includes(routeName)"></NavBar>
    
  <router-view />

  <Footer v-if="!['public-document'].includes(routeName)"></Footer>
</template>

<script>
import { computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { asyncLoadBeforeReroute } from '@/shared/setup/populateMethods.js'

export default {
  name: 'DMS App Root',
  setup(){
    const store = useStore()
    const route = useRoute()
    const profile = computed(() => store.state.user.profile)
    const recentDocuments = computed(() => store.state.documents.latest)
    const recentTemplates = computed(() => store.state.templates.latest)

    const routeName = computed(() => route.name)

    const authenticate = () => {
      if (localStorage.signedIn && localStorage.csrf) {
        store.commit('authenticate', localStorage.csrf)
        // load user information
        asyncLoadBeforeReroute(localStorage.csrf)
      } else if (routeName.value != 'public-document') {
        store.commit('clearUserInformation')
        store.commit('unauthenticate')
      }
    }

    onBeforeMount(() => {
      authenticate()
    })

    return {
      profile,
      recentDocuments,
      recentTemplates,
      routeName,
      authenticate
    }
  }
}
</script>

