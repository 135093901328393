<template>
  <div class="pl-5">
    <p>{{ location.address_line_1 }}</p>
    <p v-if="location.address_line_2">{{ location.address_line_2 }}</p>
    <p>
      {{ location.city }}, {{ location.region }},
      {{ location.postal_code }},
      {{ location.country }}
    </p>
    <p>{{ location.contact_name }} {{ location.contact_phone }}</p>
    <p>{{ location.website }} | {{ location.email }}</p>
  </div>
</template>

<script>
  export default {
    props: {
      location: Object
    }
  }
</script>