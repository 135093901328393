<template>
  <nav class="level pt-4 pb-6">
    <div class="level-item has-text-centered" v-for="item in levelItems" :key="item.identifier">
      <div>
        <p class="heading">{{ item.name }}</p>
        <p class="title">{{ item.value }}</p>
      </div>
    </div>
  </nav>

  <!-- bar reports -->
  <div v-for="report in barReports" :key="report.identifier">
    <h2 class="title is-4">
      {{ report.ui_label }}
    </h2>

    <div class="chart-container mt-3 single-chart box">
      <bar-chart v-if="loaded[report.identifier]" :chartData="barCharts[report.identifier]" :chartOptions="chartOptions"/>
    </div>
  </div>

  <template-view
    :startAt="startAt"
    :endAt="endAt"
    :userId="userId"
    :orderBy="orderBy"
  />

  <!-- list reports  -->
  <div v-for="report in listReports" :key="report.identifier">
    <h2 class="title is-4" v-if="loaded[report.identifier]">
      {{ report.ui_label }}
    </h2>

    <div class="mt-3 single-chart box" v-if="loaded[report.identifier]">
      <list-report :datasets="listCharts[report.identifier].datasets" />
    </div>
  </div>
</template>

<script>
  import BarChart from '@/components/analytics/visual/BarChart.vue'
  import ListReport from '@/components/analytics/visual/ListReport.vue'
  import { reactive, computed, ref, onBeforeMount, toRefs, watch } from 'vue'
  import TemplateView from '@/components/analytics/TemplateView.vue'
  import AnalyticsService from '@/services/AnalyticsService.js'

  import { convertToMinutes, userReports, userLevelItems, chartOptions, brandColors } from '@/shared/setup/analyticsMethods.js'

  export default {
    props: {
      userId: {
        type: [Number, String],
        required: true
      },
      startAt: {
        type: String,
        required: true
      },
      endAt: {
        type: String,
        required: true
      },
      orderBy: {
        type: String,
        required: false,
        default: 'count desc'
      }
    },
    components: {
      BarChart,
      ListReport,
      TemplateView
    },
    setup(props) {
      const barCharts = reactive({})
      const listCharts = reactive({})
      const loaded = reactive({})

      const reportTypes = userReports

      reportTypes.forEach(rt => {
        loaded[rt.identifier] = false
      })

      const levelItems = ref(userLevelItems)


      const reportOptions = computed(() => {
        return {
          start_at: props.startAt,
          end_at: props.endAt,
          order_by: props.orderBy,
          user_id: props.userId
        }
      })

      const barReports = computed(() => reportTypes.filter(r => r.chartType == 'bar'))
      const listReports = computed(() => reportTypes.filter(r => r.chartType == 'list'))

      // Methods

      const generateDocumentReports = () => {
        resetCharts()

        const requests = []

        reportTypes.forEach((rt) => {
          const requestOptions = Object.assign({}, reportOptions.value)
          requestOptions.start_at = props.startAt
          requestOptions.end_at = props.endAt
          requestOptions.report_type = rt.name

          // modifications to options by report

          if (rt.identifier == 'avg_build_time') {
            requestOptions.convert_to_minutes = 'true'
          }

          if (rt.identifier == 'time_in_platform') {
            requestOptions.dt_unit = 'Hour'
          }

          if (rt.identifier.includes('top_and_bottom')) {
            requestOptions.top_set_size = 10
            requestOptions.bottom_set_size = 10
            delete requestOptions.order_by
          }

          requests.push(AnalyticsService.getAnalytics(requestOptions))
        })
        
        Promise.all(requests)
        .then(responses => {
          responses.forEach((r, i) => {
            if (r) {
              const data = r.data
              const newReport = {}
              
              if (data.labels) {
                newReport.labels = data.labels
              }
              
              newReport.datasets = [
                {
                  label: reportTypes[i].label,
                  backgroundColor: reportTypes[i].color || brandColors[1],
                  data: data.datasets
                }
              ]

              const report = reportTypes[i]
              const key = report.identifier
              
              if (key) {

                if (report.chartType == 'bar') {
                  barCharts[key] = newReport
                }

                if (report.chartType == 'list') {
                  listCharts[key] = newReport
                }

                loaded[key] = true

                const index = levelItems.value.findIndex(item => item.identifier == key)
                
                if (index >= 0) {
                  levelItems.value[index].value = data.datasets.length ? data.datasets.reduce((a, b) => a + b ) : 0

                  if (key == 'time_in_platform') {
                    levelItems.value[index].value = convertToMinutes(levelItems.value[index].value).toLocaleString('en')
                  } 
                } 
              }
            }
          })
        })
        .catch(errors => {
          console.log(errors)
        })
      }

      onBeforeMount(() => {
        generateDocumentReports()
      })


      const startDate = toRefs(props).startAt
      const endDate = toRefs(props).endAt
      const userId = toRefs(props).userId
      const orderBy = toRefs(props).orderBy

      watch([startDate, endDate, orderBy, userId], (v) => {
        if (v[1] > v[0]) {
          generateDocumentReports()
        }
      })

      const resetCharts = () => {
        Object.keys(loaded).forEach(k => {
          loaded[k] = false
        })
      }

      return {
        loaded,
        chartOptions,
        barReports,
        listReports,
        levelItems,
        barCharts,
        listCharts
      }
    }
  }
</script>