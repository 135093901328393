<template>
  <div class="text-center contained-block centered">
    <h1 class="h1">Privacy Policy</h1>

    <h2 class="h2">Lorem Ipsum</h2>
    <p>
      Sed at leo est. Cras vulputate velit eget purus auctor sagittis. Aenean
      cursus vehicula felis eget ornare. Donec id elit sit amet erat congue
      scelerisque. Vivamus pulvinar porttitor nisi sed tempus. Aliquam justo
      neque, vulputate vitae sagittis quis, lobortis in ligula. Donec euismod
      lacus lorem, eu maximus turpis faucibus eu. Integer pellentesque, lectus
      eget interdum finibus, turpis magna congue sapien, in facilisis risus
      justo ac velit. Phasellus ante tellus, ornare vel gravida vel, auctor eget
      nisl. Donec cursus, felis ac venenatis dapibus, dui magna elementum nisi,
      vel rutrum leo neque nec odio. Duis quis eleifend eros, ut commodo turpis.
    </p>

    <p>
      Praesent euismod egestas urna ac pretium. Sed diam libero, rhoncus id
      lorem ac, convallis porttitor lorem. Ut vel finibus metus, vitae luctus
      augue. Duis sodales nisl quis rutrum ullamcorper. Fusce placerat ipsum eu
      egestas tincidunt. In tempus gravida risus vel convallis. Aenean ut
      commodo felis. Fusce quis dolor et dolor interdum tincidunt. Nullam non
      eros id felis elementum pharetra. Pellentesque vehicula ligula quis turpis
      luctus egestas. Interdum et malesuada fames ac ante ipsum primis in
      faucibus. Donec quis mollis nisl, in hendrerit lacus.
    </p>

    <p>
      Integer consequat, mauris a euismod scelerisque, ante urna ultricies dui,
      ut sollicitudin ligula enim a diam. Vivamus iaculis purus hendrerit,
      ullamcorper mi et, faucibus nisl. Ut vel elit sit amet massa fermentum
      bibendum. Donec malesuada, mi vel pretium volutpat, velit erat hendrerit
      ex, ut convallis erat metus et erat. Quisque faucibus, neque pellentesque
      sollicitudin placerat, dolor massa vulputate arcu, non facilisis sem arcu
      eget ipsum. Fusce fringilla metus enim, et suscipit elit eleifend nec.
      Proin tempus feugiat tortor id vulputate. Phasellus at nibh vitae elit
      accumsan lacinia. Suspendisse efficitur augue nunc, eu tincidunt orci
      rhoncus placerat. Duis ut dui at eros facilisis semper ac a augue. Cras
      imperdiet ac tellus dapibus facilisis. Donec maximus mattis justo eu
      molestie.
    </p>

    <p>
      Quisque quis ex ac leo venenatis tempus eget et sapien. Curabitur pulvinar
      sem nibh, non commodo justo ultricies in. Sed semper odio a elit tempus
      condimentum. Suspendisse nulla magna, laoreet nec dui et, interdum
      imperdiet dui. Nunc tempus magna quis nibh rhoncus molestie. Suspendisse
      molestie nunc quis iaculis rhoncus. Vestibulum et efficitur magna, et
      interdum augue. Ut sollicitudin lacus non metus pretium, a bibendum ipsum
      accumsan. In pellentesque risus eget eros vehicula facilisis. Pellentesque
      a consequat neque, sit amet congue purus. Morbi at tellus suscipit,
      elementum diam ac, scelerisque ipsum.
    </p>
  </div>
</template>

<script>
export default {
  // components: {
  //   UserForm
  // },
  // data() {
  //   return {
  //     email: null,
  //     password: null
  //   }
  // },
}
</script>

<style lang="scss">
// @import "./src/scss/_privacy.scss";
</style>
