import { securedAxiosInstance } from '@/axios/index.js'
import { resolveQueryUrl } from '@/axios/requestMethods.js'

export default {
  getContacts(query, limit, page) {
    var baseUrl = `/contacts`
    const requestUrl = resolveQueryUrl(baseUrl, query, limit, page)
    
    return securedAxiosInstance.get(requestUrl)
  },
  getOrganizations(query, limit, page) {
    var baseUrl = '/contact_organizations'
    const requestUrl = resolveQueryUrl(baseUrl, query, limit, page)
    
    return securedAxiosInstance.get(requestUrl)
  },
  getContact(id){
    const baseUrl = '/contact/' + id
    return securedAxiosInstance.get(baseUrl)
  },
  getOrganization(id){
    const baseUrl = '/contact_organization/' + id
    return securedAxiosInstance.get(baseUrl)
  },
  getContactFromRansack(code, query) {
    var baseUrl = `/group_select_query/${code}.json`
    const requestUrl = resolveQueryUrl(baseUrl, query)
    return securedAxiosInstance.get(requestUrl)
  }
  // createContact(contact_info) {
  //   var baseUrl = `/contact`


  //   contact_info[
  //     'full_name'
  //   ] = `${contact_info.first_name} ${contact_info.middle_name ? contact_info.middle_name : ''} ${contact_info.last_name}`

  //   return securedAxiosInstance.post(baseUrl, { contact: contact_info })
  // },
  // updateContact(contact_info) {
  //   var baseUrl = `/contact/${contact_info.id}`

  //   delete contact_info.locations
  //   delete contact_info.organizations
  //   delete contact_info.logo

  //   contact_info['full_name'] = `${contact_info.first_name} ${contact_info.middle_name ? contact_info.middle_name : ''} ${contact_info.last_name}`

  //   return securedAxiosInstance.patch(baseUrl, { contact: contact_info })
  // },
  // updateContactLogo(id, contact){
  //   return securedAxiosInstance.patch(`/contact/${id}`, contact, {
  //     headers: {
  //       'content-type': 'multipart/form-data'
  //     }
  //   })
  // },
  // updateOrganizationLogo(id, organization){
  //   return securedAxiosInstance.patch(`/contact_organization/${id}`, organization, {
  //     headers: {
  //       'content-type': 'multipart/form-data'
  //     }
  //   })
  // },
  // createOrganization(orgInfo) {
  //   var baseUrl = `/contact_organization`
    
  //   delete orgInfo.id
  //   delete orgInfo.logo
  //   delete orgInfo.contacts

  //   return securedAxiosInstance.post(baseUrl, { organization: orgInfo })
  // },
  // updateOrganization(orgInfo) {
  //   var baseUrl = `/contact_organization/${orgInfo.id}`

  //   delete orgInfo.locations
  //   delete orgInfo.logo
  //   delete orgInfo.contacts

  //   return securedAxiosInstance.patch(baseUrl, { organization: orgInfo })
  // },
  // deleteOrganization(id) {
  //   var baseUrl = `/contact_organization/${id}`

  //   return securedAxiosInstance.delete(baseUrl)
  // },
  // createOrgLocation(orgId, location_info) {
  //   var baseUrl = `/organization_location/${orgId}`

  //   return securedAxiosInstance.post(baseUrl, { location: location_info })
  // },
  // updateOrgLocation(id, location_info) {
  //   var baseUrl = `/organization_location/${id}`
  //   return securedAxiosInstance.patch(baseUrl, { location: location_info })
  // },
  // deleteContact(id) {
  //   var baseUrl = `/contact/${id}`
  //   return securedAxiosInstance.delete(baseUrl)
  // },
  // createLocation(contact_id, location_info) {
  //   var baseUrl = `/contact_location/${contact_id}`

  //   return securedAxiosInstance.post(baseUrl, { location: location_info })
  // },
  // updateLocation(id, location_info) {
  //   var baseUrl = `/location/${id}`
  //   return securedAxiosInstance.patch(baseUrl, { location: location_info })
  // },
  // deleteLocation(id) {
  //   var baseUrl = `/location/${id}`
  //   return securedAxiosInstance.delete(baseUrl)
  // },
  // findOrganization(query) {
  //   var baseUrl = `/contact_organizations?q[name_cont]=${query.name_cont}`

  //   return securedAxiosInstance.get(baseUrl)
  // }
}
