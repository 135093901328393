<template>
  <div class="container is-max-widescreen">
    <Notification
      v-model="notificationVisible"
      :type="notification.type"
      @dismiss="dismissNotification"
    >
      {{ notification.message }}
    </Notification>
  </div>

  <section class="section">
    <!-- is-max-widescreen -->
    <div class="container is-fullhd">
      <HeaderTitle
      :title="t('output.heading')"
      :subtitle="t('output.subtitle')"
      :isOutlined="true"
      ></HeaderTitle>
    </div>
  </section>

  <div class="has-gray-bg output-screen">
    <section class="section pt-5">
      <div class="container is-fullhd">
        <!-- <router-link class="button is-ghost" :to="{name: 'customize', params: {id: dmt.parent_id} }" v-if="dmt.parent_id">
          <span class="icon is-small">
           <font-awesome-icon icon="chevron-circle-left"/>
          </span>
          <span>{{ t('output.buttons.back') }}</span>
        </router-link> -->
        <div class="columns is-variable is-8 pt-5">
          <!-- preview -->
          <div class="column is-half">
            <layout-preview
              :locale="dmtLocale"
              :ts="userSelection"
              v-if="loaded.document && layout"
              :layout="layout"
            ></layout-preview>
            <div v-else>
              <p class="title is-5 mb-2">{{ t('output.loading_preview') }}</p>
              <p class="title is-6">{{ t('customize.loading_preview_subtitle') }}</p>
              <Loading :loading="!loaded.document"></Loading>
            </div>
          </div>
          <!-- output options -->
          <div class="column is-half is-relative" v-if="outputOptions.length > 0">
            <div class="field mb-5 box">
              <label class="has-text-weight-bold" for="document_name">Document name</label>
              <div class="control" :class="{'is-loading': savingDocument }">
                <input type="text" name="document_name" class="input" v-model="dmt.name" @change="updateDocument" >
              </div>
            </div>

            <div class="tabs is-boxed mb-0">
              <ul>
                <li 
                  v-for="opt in dmt.output_options"
                  :key="opt.content_type"
                  :class="{'is-active': activeTab == opt.content_type }"
                >
                  <a @click="activeTab = opt.content_type">
                    <span class="icon is-small">
                      <font-awesome-icon :icon="icon(opt.content_type)" />
                    </span>
                    <span>{{ tabTitle(opt.content_type) }}</span>
                  </a>
                </li>
              </ul>
            </div>
            
            <div class="tab-content mb-5">
              <pdf-output
                v-for="option in pdfOptions"
                :key="option.id"
                :option="option"
                v-show="activeTab == option.content_type"
                @notify="notify"
              >
              </pdf-output>

              <image-output
                v-for="option in imageOptions"
                :key="option.id"
                :option="option"
                v-show="activeTab == option.content_type"
                @notify="notify"
              >
              </image-output>

              <social-output
                v-for="option in socialOptions"
                :key="option.id"
                :option="option"
                v-show="activeTab == option.content_type"
                @notify="notify"
              >
              </social-output>

              <email-output
                v-for="option in emailOptions"
                :key="option.id"
                :option="option"
                v-show="activeTab == option.content_type"
                @notify="notify"
              >
              </email-output>
              
              <html-output
                v-for="option in htmlOptions"
                :key="option.id"
                :option="option"
                v-show="activeTab == option.content_type"
                @update-options="updateOption"
                @notify="notify"
                @record="record"
              >
              </html-output>

            </div>

            <div class="box mt-3">
              <h3 class="title is-5">{{  t('output.next.title') }}</h3>

              <div class="">
                <p>{{ t('output.next.edit')}}
                  <router-link class="has-text-weight-bold" :to="{name: 'customize', params: {id: dmt.parent_id} }" v-if="dmt.parent_id">
                    <!-- <span class="icon is-small">
                     <font-awesome-icon icon="chevron-circle-left"/>
                    </span> -->
                    <span>{{ t('output.next.edit_btn').toLowerCase() }}</span>.
                  </router-link></p>
                <p class="mb-3">
                  {{ t('output.next.more')}}
                  <router-link class="has-text-weight-bold" :to="{name: 'template-index'}">
                  <span>{{ t('output.next.more_btn') }}</span>.
                  <!-- <span class="icon is-small">
                   <font-awesome-icon icon="chevron-circle-right"/>
                  </span> -->
                </router-link></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import '@/assets/stylesheets/module_specific/output.scss'
import { computed, inject, reactive, provide, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import HeaderTitle from '@/components/shared/HeaderTitle.vue'

import LayoutPreview from '@/components/document/preview/LayoutPreview'
import PdfOutput from '@/components/document/output/PdfOutput.vue'
import ImageOutput from '@/components/document/output/ImageOutput.vue'
import HtmlOutput from '@/components/document/output/HtmlOutput.vue'
import SocialOutput from '@/components/document/output/SocialOutput.vue'
import EmailOutput from '@/components/document/output/EmailOutput.vue'
import DocumentService from '@/services/DocumentService.js'

import { 
  notificationVisible, 
  notification, 
  dismissNotification, 
  updateNotification
} from '@/shared/setup/notifications.js'

import { resolveUserSelection } from '@/shared/setup/populateMethods.js'
import { resolveHash, recordAction } from '@/shared/setup/analyticsMethods.js'

export default {
  components: {
    HeaderTitle,
    LayoutPreview,
    PdfOutput,
    ImageOutput,
    HtmlOutput,
    SocialOutput,
    EmailOutput
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    parentNotification: {
      type: String
    }
  },
  setup(props){
    const store = useStore()
    const dmt = reactive({})
    const userSelection = computed(() => store.state.currentDocument.sharedSelection)
    const imageSize = computed(() => store.state.device.defaultImgSize)
    const savingDocument = ref(false)
    const layout = computed(() => dmt.layouts ? dmt.layouts[0] : null)
    const outputOptions = computed(() => dmt.output_options ? dmt.output_options : [])
    const dataElements = computed(() => dmt.data_elements ? dmt.data_elements : [])
    const assetElements = computed(() => dmt.asset_elements ? dmt.asset_elements : [])
    const groupAssociations = computed(() => dmt.group_associations ? dmt.group_associations : [])
    const dmtLocale = computed(() => dmt.locale ? dmtLocale : 'en')

    const pdfOptions = computed(() => {
      return outputOptions.value.filter(o => o.content_type == 'pdf')
    })

    const socialOptions = computed(() => {
      const medias = ['linkedin', 'facebook', 'twitter']

      return outputOptions.value.filter(o => medias.includes(o.content_type) )
    })

    const imageOptions = computed(() => {
      return outputOptions.value.filter(o => o.content_type == 'image')
    })

    const htmlOptions = computed(() => {
      return outputOptions.value.filter(o => o.content_type == 'html')
    })

    const emailOptions = computed(() => {
      return outputOptions.value.filter(o => o.content_type == 'email')
    })

    const { t } = useI18n()

    const loaded = reactive({
      document: false
    })

    const settings = inject('clientGlobalSettings')

    const loadDocument = () => {
      // should leverage selection from document? probably.
      // store.dispatch('clearDocumentSelection')
      fetchDocument()
    }

    const fetchDocument = async () => {
      // work on wait state here
      DocumentService.loadDocument(props.id)
      .then(response => {
        const loadedDocument = response.data
        
        for (const [key, value] of Object.entries(loadedDocument)){
          dmt[key] = value
        }

        activeTab.value = dmt.output_options[0].content_type

        loaded.document = true  
      })
      .catch(error => {
        console.log("error getting this document", error)
      })
    }

    loadDocument()

    provide('dataElements', dataElements)
    provide('assetElements', assetElements)
    provide('dmtLocale', dmtLocale)
    
    const activeTab = ref()

    const tabTitle = (contentType) => {
      const translation = 'output.'+ contentType + '.tab'
      return t(translation)
    }

    const icon = (contentType) => {
      let icon

      switch (contentType) {
        case 'pdf':
          icon = 'file-pdf'
          break
        case 'image':
          icon = 'image'
          break
        case 'linkedin':
          icon = ['fab', 'linkedin']
          break
        case 'twitter':
          icon = ['fab', 'twitter-square']
          break
        case 'html':
          icon = 'file-code'
          break
        case 'facebook':
          icon = ['fab', 'facebook-square']
          break
        case 'email':
          icon = 'envelope'
          break
        default: 
          icon = 'file'
      }
      
      return icon
    }

    const updateOption = (data) => {
      dmt.output_options = data.output_options
    }

    const notify = (object) => {
      updateNotification(object, true)
    }

    const updateDocument = async () => {
      savingDocument.value = true
      const doc = {
        id: dmt.id,
        name: dmt.name
      }

      DocumentService.updateDocument(doc.id, doc)
      .then(response => {
        console.log('Document Updated via updateDocument')
        console.log(response.data)
        savingDocument.value = false
      })
      .catch(error => {
        console.log('Error updating name', error)
        savingDocument.value = false
        const ntf = {
          message: 'Issue updating document name.',
          type: 'danger'
        }

        notify(ntf)
      })
    }

    console.log(userSelection.value)

    watch(dataElements, (value) => {
      if (value.length) {
        resolveUserSelection(value, dmtLocale.value, groupAssociations.value)
      }
    })

    const record = (action, other) => {
      other.document_name = dmt.name
      other.document_type = dmt.document_type
      const hash = resolveHash('Output Document', 'Document', action, null, other)
      recordAction(hash)
    }



    return {
      t,
      notificationVisible,
      notification,
      dismissNotification,
      updateNotification,
      settings,
      imageSize,
      fetchDocument,
      dmt,
      dmtLocale,
      layout,
      outputOptions,
      loaded,
      userSelection,
      pdfOptions,
      imageOptions,
      socialOptions,
      htmlOptions,
      emailOptions,
      activeTab,
      savingDocument,
      // methods
      loadDocument,
      tabTitle,
      icon,
      updateOption,
      notify,
      updateDocument,
      record
    }
  }
}
</script>