<template>
  <div class="icon-text" :class="customClass">
    <span class="icon is-small">
      <img :src="iconUrl" :title="t('generic.icon')"/>
    </span>
    <span class="pl-2">
      <a :href="`mailto:${href}`" v-if="type === 'mail'">
        <slot></slot>
      </a>
      <a :href="href" :target="target" v-else-if="type === 'anchor'">
        <slot></slot>
      </a>
      <a :href="`tel:${href}`" v-else-if="type === 'phone'">
        <slot></slot>
      </a>
      <p v-else>
        <slot></slot>
      </p>
    </span>
  </div>
</template>

<script>
import { computed } from "vue";
import { useI18n } from 'vue-i18n';

export default {
  setup(props){
    const { t } = useI18n();
    const iconUrl = computed(() => require(`@/assets/img/icons/${props.iconName}.png`))

    return {
      t,
      iconUrl
    }
  },
  props: {
    href: {
      type: [String],
      required: false
    },
    iconName: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: false
    },
    target: {
      type: String,
      required: false
    },
    customClass: {
      type: [Array, String],
      required: false
    }
  }
}
</script>