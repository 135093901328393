<template>
  <div class="field">
    <div class="columns">
      <div class="column">
        <div class="file is-primary has-name">
          <label class="file-label">
            <input class="file-input" ref="fileInput" type="file" @input="upload">
            <span class="file-cta">
              <span class="file-icon">
                <font-awesome-icon icon="upload"></font-awesome-icon>
              </span>
              <span class="file-label">
                {{ label }}
              </span>
            </span>
            <span class="file-name">
              {{ filename }}
            </span>
          </label>
        </div>
      </div>
      <div class="column is-narrow is-flex is-align-items-center" v-if="hasValue">
        <div class="icon-text is-clickable" @click="removeAttachment" :title="t('helpers.remove')">
          <span class="icon has-text-danger">
            <font-awesome-icon icon="times" />
          </span>
        </div>
      </div>
    </div>

    <field-message v-if="inputError.message.length" :object="inputError">
      {{ inputError.message }}
    </field-message>
  </div>
</template>

<script>
import { inject, computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { inputError, updateInputError } from '@/shared/setup/notifications.js'
export default {
  setup(props, { emit }){
    const { t } = useI18n()
    const locale = inject('dmtLocale')
    const file = ref(props.modelValue)
    const fileInput = ref(null)

    const upload = (event) => {
      const value = event.target.files || event.dataTransfer.files
      file.value = value[0]
      if (file.value) {
        emit('update:modelValue', file.value)
      }
    }

    const removeAttachment = () => {
      fileInput.value = null
      emit('update:removeAttachment')
    }

    const hasValue = computed(() => props.input.asset || props.input.document_input_object['url'])

    watch(file, (value) => {
      if (!value) {
        fileInput.value = null
      }
    })

    const label = computed(() => props.input.properties.label || t('customize.upload_file'))
    const filename = computed(() => file.value ? file.value.name : t('customize.no_file_selected'))

    return {
      t,
      inputError,
      updateInputError,
      locale,
      label,
      filename,
      fileInput,
      hasValue,
      upload,
      removeAttachment
    }
  },
  props: {
    input: {
      type: Object,
      required: true
    }
  }
}
</script>
