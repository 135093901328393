<template>
  <form @submit.prevent="onSubmit" accept-charset="utf-8">
    <h3 class="title is-3 is-spaced"><span class="outlined yellow">Sign in</span></h3>
    <div class="field m-space">
      <label class="label">{{t('login_page.login')}}</label>
      <div class="control has-icons-left has-icons-right">
        <input
        type="text"
        v-model="login"
        :placeholder="t('login_page.email')"
        class="input"
        :class="validation.login.class"
        @change="emailValidation($event.target.value, validation.login)"
        />
        <span class="icon is-small is-left">
           <font-awesome-icon icon="user" />
        </span>
        <span class="icon is-small is-right has-text-success" v-show="validation.login.class == 'is-success'">
          <font-awesome-icon icon="check" />
        </span>
      </div>
      <p class="help is-danger" v-show="invalidEmail">{{t('login_page.validations.invalid_email')}}</p>
    </div>
    <div class="field">
      <div class="label-cont has-helper">
        <label class="label">{{t("login_page.password")}}</label>
        <a href="#" role="button" @click.prevent="resetPwd()" class="helper"
          >
          {{t("login_page.forgot_password")}}</a>
      </div>
      
      <div class="control has-icons-left">
        <input
        type="password"
        v-model="password"
        placeholder="********"
        class="input"
        />
        <span class="icon is-small is-left">
           <font-awesome-icon icon="key" />
        </span>
      </div>
    </div>
    <div class="field m-space">
      <div class="helper-links">
        <label class="checkbox">
          <input type="checkbox">
          {{t('login_page.stay_signed_in')}}
        </label>
      </div>
    </div>

    <div class="field is-grouped is-grouped-right">
      <div class="control">
        
        <button class="button is-primary" :disabled="invalidEmail" v-show="!loginInProgress">
          {{t('buttons.login')}}
        </button>
        <div style="width:120px" v-show="loginInProgress">
          <progress class="progress is-small is-primary" max="100">10%</progress>
        </div>
        

      </div>

    </div>
  </form>
</template>

<script>
import LoginService from '@/services/LoginService.js'
import { ref, reactive, computed } from "vue"
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { emailValidation } from '@/shared/setup/validationMethods.js'

import { asyncLoadBeforeReroute } from '@/shared/setup/populateMethods.js'

export default {
  name: 'User Login',
  emits: ['reset-pwd', 'notify'],
  setup(props, { emit }){
    const store = useStore()
    const { t } = useI18n()
    const login = ref(null)
    const password = ref(null)

    const validation = reactive({
      login: {
        valid: null,
        class: null,
        inProgress: false
      }
    })

    const loginInProgress = ref(false)

    

    // computed
    const invalidEmail = computed(() => validation.login.valid === false)

    // methods
    const resetPwd = () => {
      emit('reset-pwd', 1)
    }

    const signinSuccessful = (response) => {
      if (!response.data.csrf || response.data.csrf.length < 1) {
        signinFailed(response)
      } else {
        asyncLoadBeforeReroute(response.data.csrf)
      }
    }

    const signinFailed = (response) => {
      loginInProgress.value = false
      let message = 'Unable to process the request. Please contact support.'

      if (response.data.error) {
        message = response.data.error
      } 
      
      const newNotification = {
        type: 'danger',
        message: message
      }

      emit('notify', newNotification)

      store.commit('unauthenticate')
      store.commit('clearUserInformation')
    }

    const onSubmit = async () => {
      let credentials = {
        login: login.value,
        password: password.value
      }

      loginInProgress.value = true

      LoginService.authenticate(credentials)
      .then(response => signinSuccessful(response))
      .catch(error => signinFailed(error.response))
    }

    return {
      t,
      login,
      password,
      validation,
      invalidEmail,
      loginInProgress,
      onSubmit,
      resetPwd,
      emailValidation
    }
  }
}
</script>
