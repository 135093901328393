<template>
  <div class="field">
    <label class="label" v-if="label">
      {{ label }}
    </label>

    <!-- current selection -->
    <part-box 
      v-for="item in selectedItems"
      :key="item.id"
      :item="item"
      :input="input"
      @update:documentItem="updateDocumentItem"
      @update:selection="updateSelection"
    />
    
    <!-- drawer launch -->
    <button class="button is-primary is-small" :title="t('buttons.view')" @click="isDrawerActive = true">
      <span class="icon is-small">
        <font-awesome-icon icon="edit" size="sm"/>
      </span>
      <span>{{ t('customize.parts.manage') }}</span>
    </button>

    <!-- drawer -->
    <product-search
      :groupCode="input.group.code"
      @close="closeDrawer"
      @update:selection="updateSelection"
      v-show="isDrawerActive"
      :selection="computedValue"
    >
      {{ t('customize.parts.manage') }}
    </product-search>

    <field-message v-if="inputError.message.length" :object="inputError">
      {{ inputError.message }}
    </field-message>
  </div>
</template>

<script>
import { inject, computed, ref, provide, onBeforeMount, onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { inputError, updateInputError } from '@/shared/setup/notifications.js'
import { default as ProductService } from '@/services/DamService.js'
import ProductSearch from '@/components/inputs/accessories/ProductSearch.vue'
import PartBox from '@/components/inputs/PartBox.vue'

export default {
  components: {
    ProductSearch,
    PartBox
  },
  setup(props, { emit }){
    const { t } = useI18n()
    const store = useStore()
    const locale = inject('dmtLocale')
    const selectedItems = computed(() => Object.values(props.input.document_items).filter(p => p.selected == true))
    
    const computedValue = computed({
      get: () => {
        return props.input.value_array
      }
    })

    const storeSelection = (key, value, operation) => {
      // updates selection (used by preview)
      const item = {
        key: key,
        item: value,
        operation: operation || 'add'
      }

      store.dispatch('editCollection', item)
    }

    const updateDocumentItem = (inputRef, attr, newValue) => {
      // component allows multiple select, so inputRef has to be diRef
      // console.log(inputRef, attr, newValue)
      emit('update:input', inputRef, attr, newValue)
    }

    const updateSelection = (collectionItem) => {
      const partId = collectionItem.id.toString()
      
      if (computedValue.value.includes(partId)) {
        const idIndex = computedValue.value.indexOf(partId)
        computedValue.value.splice(idIndex, 1)

        storeSelection(props.input.key, collectionItem, 'remove')
      } else {
        computedValue.value.push(partId)
        storeSelection(props.input.key, collectionItem)
      }

      // updates value_array
      emit('update:modelValue', computedValue.value, props.input) 
    }

    const loadInitialSelection = async () => {
      const requests = []
      selectedItems.value.forEach((si) => {  
        let filter = {
          id_eq: si.selectable_id
        }

        requests.push(ProductService.ransackQuery(locale, 1, 1, props.input.group.code, filter))
      })

      Promise.all(requests)
      .catch(() => {
        console.log('error getting products')
      })
      .then(responses => {
        responses.forEach(response => {
          const items = response.data.items

          if (items.length) {
            const item = {
              key: props.input.key,
              item: items[0]
            }

            store.dispatch('editCollection', item)
          }
        })
      })
    }

    // make this async
    onBeforeMount(() => {
      const object = {
        key: props.input.key,
        value: []
      }
      store.commit('saveDocumentSelection', object)

      loadInitialSelection()
    })

    onBeforeUnmount(() => {
      const object = {
        key: props.input.key,
        value: []
      }
      store.commit('saveDocumentSelection', object)
    })

    const label = computed(() => props.input.properties.label)
    const placeholder = computed(() => props.input.properties.placeholder)

    const isDrawerActive = ref(false)
    const closeDrawer = () => {
      isDrawerActive.value = false
    }

    provide('selectionLimit', props.input.properties.max_select)
    provide('contentType', props.input.group.content_type)

    return {
      inputError,
      updateInputError,
      t,
      locale,
      computedValue,
      selectedItems,
      label,
      placeholder,
      updateDocumentItem,
      isDrawerActive,
      closeDrawer,
      updateSelection
    }
  },
  props: {
    input: {
      type: Object,
      required: true
    },
  }
}
</script>
