<template>
  <div class="card">
    <div class="card-content">
      <div class="media">
        <div class="media-left">
          <figure class="image is-48x48">
            <img :src="objectLogo" alt="Contact image">
          </figure>
        </div>
        <div class="media-content contact-title">
          <p class="title is-5">{{object.name}}</p>
          <p class="subtitle is-6">{{object.industry}}</p>
        </div>
      </div>

      <div class="content contact-content">
        <p class="short-description">{{ description }}</p>

        <div class="columns mt-2">
          <div class="column">
            <a :href="`mailto:${object.email}`" @click="record(object, 'Email')" class="button  is-ghost" :title="t('generic.email')">
              <span class="icon">
                <font-awesome-icon icon="envelope" />
              </span>
            </a>
            <a :href="object.website" @click="record(object, 'Website')" class="button  is-ghost" target="_blank" :title="t('generic.website')">
              <span class="icon ">
                <font-awesome-icon icon="desktop" />
              </span>
            </a>
          </div>

          <div class="column is-narrow" v-if="showMore">
            <router-link :to="{ name: 'organization', params: { id: object.id } }" role="button" class="button is-primary is-small">
              <span class="icon">
                <font-awesome-icon icon="plus" />
              </span>
              <span>{{ t('contacts.buttons.details') }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { resolveHash, recordAction } from '@/shared/setup/analyticsMethods.js'

export default {
  props: {
    object: {
      type: [Object],
      required: true
    },
    showMore: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup(props){
    const { t } = useI18n()
    const truncate = inject('truncate')
    const store = useStore()
    const imageSize = computed(() => store.getters.imageUrl)
    const objectLogo = computed(() => {
      const logo = props.object.logos && props.object.logos.length ? props.object.logos[0][imageSize.value] : null

      return logo || '/missing-4by3.png'
    })

    const hasDescription = computed(() => props.object.description && props.object.description.length)

    const description = computed(() => hasDescription.value ? truncate(props.object.description, 80, "...") : "" )

    const record = (contact, action) => {
      const other = {
        contact_id: contact.id
      }

      const hash = resolveHash('Contacts', 'Organization', action, null, other)
      recordAction(hash)
    }

    return {
      t,
      objectLogo,
      hasDescription,
      description,
      record
    }
  },
  
}
</script>