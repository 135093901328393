<template>
  <div class="container is-max-widescreen">
    <Notification
      v-model="notificationVisible"
      :type="notification.type"
      @dismiss="dismissNotification"
    >
      {{ notification.message }}
    </Notification>
  </div>

  <section class="section">
    <div class="container is-max-widescreen">
      <HeaderTitle
      :title="t('templates.index.heading')"
      :subtitle="t('templates.index.subtitle')"
      :isOutlined="true"
      ></HeaderTitle>
    </div>
  </section>

  <div class="has-gray-bg">
    <div class="container is-max-widescreen">

      <div>
        <div class="field is-horizontal pt-6">
          <div class="field-body">
            <div class="field">
              <control type="text" :placeholder="t('templates.search.by_name')" v-model="searchForm.pattern" />
            </div>

            <div class="field is-narrow">
              <control type="select" :placeholder="t('templates.search.by_status')" v-model="searchForm.status" :options="statusOptions"/>
            </div>

          </div>
        </div>
      </div>
      <div class="vh-100" v-show="loading.templates.show">
        <Loading :loading="loading.templates"></Loading>
      </div>
      <div class="py-5" v-if="!templatesNotLoaded">
        <div class="box">
          <div class="columns p-6 is-variable is-8 is-multiline is-centered">
            <div class="column is-4" v-for="t in templates" :key="t.name">
              <template-card-square :template="t" @notify="notify"/>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

   <overlay>
    <p class="title is-4">{{ t('documents.helpers.creating_new') }}</p>
   </overlay>
</template>


<script>
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { ref, reactive, computed, watch, inject } from 'vue'
import HeaderTitle from '@/components/shared/HeaderTitle.vue'
import Control from '@/components/shared/Control.vue'
import TemplateService from '@/services/TemplateService.js'
// import TemplateGrid from '@/components/template/TemplateGrid.vue'
import TemplateCardSquare from '@/components/template/TemplateCardSquare.vue'
import debounce from 'lodash/debounce'

import { 
  notificationVisible, 
  notification, 
  dismissNotification, 
  updateNotification
} from '@/shared/setup/notifications.js'

export default {
  components: {
    // TemplateGrid,
    TemplateCardSquare,
    Control,
    HeaderTitle
  },
  setup(){
    const { t } = useI18n()
    const settings = inject('clientGlobalSettings')
    const store = useStore()
    const templateListTimestamp = computed(() => store.state.templates.updated_at)
    const timeSinceRefresh = computed(() => templateListTimestamp.value ? (new Date().getTime() - templateListTimestamp.value) / 60000 : null)

    const perPage = ref(50)
    const templates = ref([])
    const loading = reactive({
      templates: {
        initial: true,
        show: true
      }
    })

    const searchForm = reactive({
      pattern: '',
      status: '',
    })

    const statusOptions = ref([
      {
        value: 'active',
        label: 'Active'
      },
      {
        value: 'draft',
        label: 'Draft'
      }
    ])

    const sortByOptions = ref([
      {
        value: 'name+asc',
        label: 'A-Z'
      },
      {
        value: 'name+desc',
        label: 'Z-A'
      },
      {
        value: 'updated_at+asc',
        label: 'Last updated last'
      },
      {
        value: 'updated_at+desc',
        label: 'Last updated first'
      },
    ])

    const firstTemplateLoad = async () => {
      if (timeSinceRefresh.value == null || timeSinceRefresh.value > settings.cache_expiry.templates){
        
        TemplateService.getTemplates(null, perPage.value, 1)
        .then(response => {
          templates.value = response.data
          loading.templates.initial = false
          loading.templates.show = false

          if (timeSinceRefresh.value == null || timeSinceRefresh.value > settings.cache_expiry.templates){
            // updates store
            store.dispatch('saveTemplateIndex', response.data)
          }
        })
        .catch(error => {
          console.log(error)
        })
      } else {
        templates.value = store.state.templates.index
        loading.templates.initial = false
        loading.templates.show = false
      }
    }
    
    const getTemplates = async (page) => {
      TemplateService.getTemplates(searchForm, perPage, page)
      .then(response => {
        templates.value = response.data
        loading.templates.show = false
      })
      .catch(error => {
        console.log(error)
      })
      
    }

    const templateOrientation = (t) => {
      const templateHeight = t.preview_image.preview_version_metadata.height
      const templateWidth = t.preview_image.preview_version_metadata.width
      var orientation = ''
      if (t.preview_image) {
        if ( templateWidth > templateHeight) {
          orientation = 'landscape'
        } else if (templateWidth < templateHeight) {
           orientation = 'portrait'
        } else if (templateWidth == templateHeight) {
           orientation = 'square'
        }
      }

      return orientation
    }

    const notify = (ntf) => {
      updateNotification(ntf, true)
    }

    const templatesNotLoaded = computed(() => templates.value.length === 0)
    const portraitTemplates = computed(() => templates.value.filter(t => templateOrientation(t) === 'portrait'))
    const landscapeTemplates = computed(() => templates.value.filter(t => templateOrientation(t) === 'landscape') )
    const squareTemplates = computed(() => templates.value.filter(t => templateOrientation(t) === 'square') )

    watch(searchForm, debounce(() => {
      loading.templates.show = true
      getTemplates(1)
    }, 600))

    return {
      notificationVisible, 
      notification,
      dismissNotification, 
      updateNotification,
      notify,
      t,
      searchForm,
      statusOptions,
      sortByOptions,
      templates,
      getTemplates,
      portraitTemplates,
      landscapeTemplates,
      squareTemplates,
      templatesNotLoaded,
      firstTemplateLoad,
      loading,
      perPage
    }


  },
  created(){
    this.firstTemplateLoad()
  },
}

</script>