const inputTypes = {
  dropdownSelect: {
    keys: ['call_to_action', 'input_dealer_logo', 'car_model', 'top_banner_1a', 'top_banner_1b', 'top_banner', 'input_brand_image', 'grp_industry'],
    attribute: 'value_array',
    hasChildren: ['car_model']
  },
  selectImage: {
    keys: ['background_image', 'header_image', 'grp_advertisement'],
    attribute: 'value_array'
  },
  radioSelect: {
    keys: ['ad_copy'],
    fieldTypes: ['group_select_single'],
    attribute: 'value_array'
  },
  radioSelectImage: {
    fieldTypes: ['logo_option'],
    substringKeys: ['_supplier_selected', '_product_images_selected'],
    attribute: 'value_array'
  },
  footerAddress: {
    keys: ['footer_address'],
    attribute: 'value'
  },
  selectWithChildren: {
    keys: ['ford-grp-wheels-best', 'ford-grp-wheels-good', 'ford-grp-wheels-better'],
    attribute: 'value_array'
  },
  dateField: {
    fieldTypes: ['date_field'],
    attribute: 'value_date'
  },
  textArea: {
    fieldTypes: ['text_area'],
    attribute: 'value_text'
  },
  multipleSelect: {
    fieldTypes: ['group_select_multi'],
    attribute: 'value_array'
  },
  phoneField: {
    fieldTypes: ['telephone_field'],
    attribute: 'value'
  },
  numberField: {
    fieldTypes: ['number_field'],
    attribute: 'value_int'
  },
  percentageField: {
    fieldTypes: ['discount_percent'],
    attribute: 'discount_percent'
  },
  logoUpload: {
    fieldTypes: ['file_upload'],
    attribute: 'attachment'
  }
}


const getGroupOptions = (groupSelect, code, property) => {
  return groupSelect[code] && groupSelect[code][property] ? groupSelect[code][property] : []
}

export { inputTypes, getGroupOptions }