<script>
import FileUpload from '@/components/inputs/FileUpload.vue'
import DateField from '@/components/inputs/DateField.vue'
import TextField from '@/components/inputs/TextField.vue'
import NumberField from '@/components/inputs/NumberField.vue'
import EmailField from '@/components/inputs/EmailField.vue'
import PercentageField from '@/components/inputs/PercentageField.vue'
import PhoneField from '@/components/inputs/PhoneField.vue'
import TextArea from '@/components/inputs/TextArea.vue'
import InputNotFound from '@/components/inputs/InputNotFound.vue'
import SelectImage from '@/components/inputs/SelectImage.vue'
// import SelectUserLogo from '@/components/inputs/SelectUserLogo.vue'
import SelectUserLocation from '@/components/inputs/SelectUserLocation.vue'
import DropdownSelect from '@/components/inputs/DropdownSelect.vue'
import RadioSelect from '@/components/inputs/RadioSelect.vue'
import ChildrenSelect from '@/components/inputs/ChildrenSelect.vue'
// used in CS ^
import RadioImageSelect from '@/components/inputs/accessories/RadioImageSelect.vue'
import CheckboxSelect from '@/components/inputs/CheckboxSelect.vue'
import CheckboxSelectBoxed from '@/components/inputs/CheckboxSelectBoxed.vue'
import SelectParts from '@/components/inputs/SelectParts.vue'

import ContactSearch from '@/components/inputs/ContactSearch.vue'

import DocumentItemAttribute from '@/components/inputs/accessories/DocumentItemAttribute.vue'
import DocumentItems from '@/components/inputs/DocumentItems.vue'

import ChildInputs from '@/components/inputs/ChildInputs.vue'

import { h, ref, computed, inject, resolveComponent, watch } from 'vue'
import { inputTypes } from '@/shared/setup/inputTypeHelpers.js'
import { useStore } from 'vuex'

export default {
  emits: ['update:modelValue', 'update:input', 'update:logo', 'update:assignContact', 'update:removeAttachment', 'update:gai'],
  components: {
    SelectParts,
    RadioImageSelect,
    SelectImage,
    DropdownSelect,
    FileUpload,
    DateField,
    TextField,
    NumberField,
    PhoneField,
    EmailField,
    PercentageField,
    TextArea,
    InputNotFound,
    SelectUserLocation,
    RadioSelect,
    ChildrenSelect,
    CheckboxSelect,
    CheckboxSelectBoxed,
    DocumentItemAttribute,
    DocumentItems,
    ChildInputs,
    ContactSearch
  },
  setup(props, { emit }){
    const customViewActive = inject('customView')
    const customComponent = computed(() => props.input.properties['vue3_component_custom'])
    const store = useStore()
    const computedInput = computed(() => props.input)
    const inputKey = ref(props.input.key)
    const fieldType = ref(props.input.field_type)
    const componentName = computed(() => customViewActive.value && customComponent.value ? customComponent.value : props.input.properties['vue3_component'])
    const childrenComponent = computed(() => props.input.properties['vue3_association_components'])
    const componentClass = computed(() => {
      if (customViewActive.value) {
        return props.input.properties['vue3_custom_class']
      } else {
        return 'field box mb-5'
      }
    })
    const groupAssociations = inject('groupAssociations')
    const dataElements = inject('dataElements')

    // find dependents for the input
    const dependents = computed({
      get: () => {
        const selectedItem = Object.values(props.input.document_items).find(di => di.selected)
        let matchingInputs = []
        let docObjectIds = []

        if (selectedItem && groupAssociations.value) {
          groupAssociations.value.forEach(ga => {
            const matchByItemCode = ga.group_association_items.find(gai => gai.target_group_item_code == selectedItem.code )
            
            if (matchByItemCode) {
              // document_object_id is the id of the logical_grouping element
              // console.log('dependents, match by item code', ga.document_object_id, ga.relationships.map(r => r.parent_id))
              docObjectIds.push(ga.document_object_id)
            }

          })
        }

        if (docObjectIds.length > 0) {
          matchingInputs = dataElements.value.filter(de => docObjectIds.includes(de.parent_id))
          console.log('has matching.', props.input.key, matchingInputs.map(x => [x.key, x.id]))
        }

        
        return matchingInputs
      }
    })
    
    // a computed property will reflect updates emitted to the parent
    const properties = {
      input: computedInput.value,
      class: componentClass.value,
      // class: customViewActive ? '' : 'field box mb-5',
      'onUpdate:immediate': (v, i) => emitUpdate(v, i, false),
      'onUpdate:modelValue': (v, i) => emitUpdate(v, i, true),
      'onUpdate:input': (updateRef, property, newValue) => {
        // updateRef could be the input itself or a reference to a specific DI (multiple checkbox case)
        if (Array.isArray(updateRef)) {
          const input = updateRef[0]
          const diRef = updateRef[1]

          emit('update:input', input, property, newValue, diRef)

        } else if (typeof(updateRef) == 'object') {
          emit('update:input', props.input, property, newValue)

        } else {
          emit('update:input', props.input, property, newValue, updateRef)
        }
      },
      'onUpdate:logo': (contactObject, contactType, triggerElementKey, logoId) => {
        emit('update:logo', contactObject, contactType, triggerElementKey, logoId)
      },
      'onUpdate:removeAttachment': () => {
        emit('update:removeAttachment', props.input)
      },
      'onUpdate:assignContact': (contactObject, contactType) => {
        emit('update:assignContact', contactObject, contactType, props.input)
      }
    }

    let resolvedComponent = null
    // update component > properties['componentName']
    if (componentName.value) {
      // point to component
      resolvedComponent = resolveComponent(componentName.value)

    } else if (fieldType.value === 'text_field') {
      resolvedComponent = TextField
    } else if ( fieldType.value === 'group_select_single'){
      
      if (inputTypes.dropdownSelect.keys.includes(inputKey.value)) {
        resolvedComponent = DropdownSelect

      } else if (inputTypes.selectImage.keys.includes(inputKey.value)) {
        resolvedComponent = SelectImage

      } else if (inputTypes.radioSelect.keys.includes(inputKey.value)) {
        resolvedComponent = RadioSelect
      } else {
        // defaults to radio select
        resolvedComponent = RadioSelect
      }

    } else if (
        fieldType.value === 'location_select'
      ){
      resolvedComponent = SelectUserLocation
    } else if (
        fieldType.value === 'date_field'
      ){
      resolvedComponent = DateField
    } else if (
        fieldType.value === 'number_field'
      ){
      resolvedComponent = NumberField
    } else if (
        fieldType.value === 'email_field'
      ){
      resolvedComponent = EmailField
    } else if (
        fieldType.value === 'discount_percent'
      ){
      resolvedComponent = PercentageField
    } else if (
        fieldType.value === 'file_upload'
      ){
      resolvedComponent = FileUpload
    } else if (fieldType.value == 'logo_option') {
      resolvedComponent = RadioImageSelect
    
    } else if (fieldType.value === 'group_select_multi') {
      resolvedComponent = CheckboxSelect
      
    } else if (fieldType.value === 'text_area') {
      resolvedComponent = TextArea
    
    } else if (fieldType.value == 'telephone_field') {
      resolvedComponent = PhoneField
    } else if (fieldType.value != 'logical_grouping') {
      // render-less field types: logical_grouping
      resolvedComponent = InputNotFound
    }


    const emitUpdate = (value, input, issueCall) => {
      if (input) {
        emit('update:modelValue', value, input, issueCall)
      } else {
        emit('update:modelValue', value, props.input, issueCall)
      }
    }

    const immediate = (ref, attr, value) => {
      const newRef = [...ref, attr]

      emit('update:documentItem', value, newRef, false)
    }

    const storeSelection = (value) => {
      if (childrenComponent.value && childrenComponent.value != 'false') {
        const object = {
          key: props.input.key,
          value: value
        }
        store.commit('saveDocumentSelection', object)
      }
    }

    // first, store selection.
    if (dependents.value.length) {
      storeSelection(dependents.value)
    }
    

    // rendering...
    let renderFunction = h('div')

    const resolveChildren = () => {
      // define properties
      const dependentProperties = {
        class: 'field box',
        'onUpdate:modelValue': (v, i) => emitUpdate(v, i, true),
        'onUpdate:input': (inputRef, property, newValue) => {
          if (Array.isArray(inputRef)) {
            const input = inputRef[0]
            const diRef = inputRef[1]
            emit('update:input', input, property, newValue, diRef)
          } else {
            emit('update:input', inputRef, property, newValue)
          }
        },
        'onUpdate:documentItem': (inputRef, property, newValue) => {
          emit('update:input', inputRef, property, newValue)
        },
        'onUpdate:immediate': (ref, attr, value) => immediate(ref, attr, value)
      }

      let newChild = null
      let dependentComponent

      // parent must indicate how the cluster will be grouped (vue3_component_association)
      if (childrenComponent.value && childrenComponent.value != 'false') {
        dependentComponent = resolveComponent(childrenComponent.value)
        
        dependentProperties.inputs = computed(() => store.state.currentDocument.sharedSelection[props.input.key])
        newChild = [h(dependentComponent, dependentProperties)]
      
      }

      return newChild
    }

    // Maybe in the future - two ways to render dependent components.
    // first: triggerElement has vue3_association_components value. All dependents grouped
    // else; dependents have their own vue3_association_components

    if (dependents.value.length) {
      const childrenRender = resolveChildren()

      let comps = [h(resolvedComponent, properties)]

      if (childrenRender && childrenRender.length) {
        comps = [...comps, ...resolveChildren()]
      }
      
      // if multiple components... (dependents.value > 0)
      if (comps.length) {
        renderFunction = h('div', {class: 'mb-5'}, comps)
      
      } else {
        renderFunction = h(resolvedComponent, properties)
      }

    } else if (resolvedComponent) {
      renderFunction = h(resolvedComponent, properties)
    
    } else {
      renderFunction = h('div', { style: 'display:none'})
    }


    // runs upon creating
    let renderObject = renderFunction

    watch(dependents, (value) => {
      storeSelection(value)
    })

    watch(props.input, (value) => {
      if (value.group_association_items) {
        emit('update:gai', value)
      }
    })

    return () => renderObject
  },
  props: {
    input: {
      type: Object
    }
  }
}
</script>
