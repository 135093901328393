<template>
  <Notification
    v-model="notificationVisible"
    :type="notification.type"
    @dismiss="dismissNotification"
  >
    {{ notification.message }}
  </Notification>

  <section class="section">
    <div class="container is-max-widescreen">
      <HeaderTitle
      :title="t('analytics.title')"
      :subtitle="t('analytics.subtitle')"
      :isOutlined="true"
      ></HeaderTitle>
    </div>
  </section>

  <div class="has-gray-bg">
    <section class="section pb-0">
      <div class="container is-max-widescreen">
        
        <div class="box analytics-toolbar animate__animated" :class="toolBarClasses">
          <div id="toolbar-toggle">
            
            <button class="button is-small is-primary"  @click.prevent="toolbarVisible = !toolbarVisible">
              <span class="icon is-small">
                <font-awesome-icon  icon="compress-alt" v-if="toolbarVisible" color="#FFF" />
                <font-awesome-icon  icon="expand-alt" v-else color="#FFF" />
              </span>
            </button>
          </div>

          <div class="control field">
            <label for="" class="pr-2 has-text-weight-bold is-relative">View</label>
            <div class="select is-fullwidth" v-if="viewOptions.length">
              <select v-model="view">
                <option :value="option.value" v-for="option in viewOptions" :key="option.value">{{ option.label }}</option>
              </select>
            </div>
          </div>
          <div class="field control">
            <div class="select is-fullwidth" v-show="userOptions.length && userLevelView">
              <select v-model="reportOptions.user_id">
                <option value="">- Select user -</option>
                <option :value="user.id" v-for="user in userOptions" :key="user.id">{{user.name}}</option>
              </select>
            </div>
          </div>

          <hr>

          <div class="field control">
            <label for="" class="pr-2 has-text-weight-bold">From</label>
            <Datepicker class="input" 
              :inputFormat="format" 
              v-model="start_at"
              :upperLimit="new Date()"
              :enableTimePicker="false" />
          </div>

          <div class="field control">
            <label for="" class="pr-2 has-text-weight-bold">To</label>
            <Datepicker class="input" 
              :inputFormat="format" 
              v-model="end_at"
              :upperLimit="new Date()"
              :enableTimePicker="false" />
          </div>

          <div class="field">
            <p><a href="#" @click.prevent="updateDates('last_week')">Last week</a></p>
            <p><a href="#" @click.prevent="updateDates('this_month')">This month</a></p>
            <p><a href="#" @click.prevent="updateDates('last_month')">Last month</a></p>
            
          </div>

          <div class="control field">
            <label for="" class="pr-2 has-text-weight-bold">Order by</label>
            <div class="select is-fullwidth">
              <select v-model="reportOptions.order_by">
                <option :value="option.value" v-for="option in orderOptions" :key="option.value">{{ option.label }}</option>
              </select>
            </div>
          </div>
          
        </div>
      </div>
    </section>

    <section class="section is-medium has-text-centered" v-if="waitingSelection">
      <Loading :loading="waitingSelection"></Loading>
    </section>

    <section class="section pt-4" v-else>
      <div class="container is-max-widescreen">
        <h2 class="title is-4 has-text-centered mb-6">{{ t('analytics.showing_reports')}} <span class="outlined yellow">{{ currentView }}</span></h2>

        <user-view
          v-if="userViewReady"
          :startAt="formattedDates.startAt"
          :endAt="formattedDates.endAt"
          :userId="reportOptions.user_id"
          :orderBy="reportOptions.order_by"
        />

        <account-view
          v-if="accountViewReady"
          :startAt="formattedDates.startAt"
          :endAt="formattedDates.endAt"
          :accountId="profile.account_id"
          :orderBy="reportOptions.order_by"
        />

        <!-- CSV download area -->
        <h2 class="title is-4">
          {{ t('analytics.download_csv') }}
        </h2>

        <div class="box mt-3 mb-6">
          <div class="">
            <ul class="has-columns-2">
              <li
                v-for="option in downloadableReports" 
                :key="option.identifier"
              >
                <a href="#" 
                  @click.prevent="downloadReport(option)" 
                  class="icon-text"
                >
                  <span class="icon">
                    <font-awesome-icon icon="file-csv" />
                  </span>
                  <span>{{ option.name }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    
  </div>
</template>

<style lang="scss">
@import '@/assets/stylesheets/module_specific/analytics.scss'
</style>

<script>
  import { DateTime } from 'luxon'
  import { reactive, ref, inject, computed, watch, onBeforeMount, provide } from 'vue'
  import AnalyticsService from '@/services/AnalyticsService.js'
  import HeaderTitle from '@/components/shared/HeaderTitle.vue'
  import Datepicker from 'vue3-datepicker'
  import { useI18n } from 'vue-i18n'
  import { useStore } from 'vuex'

  import UserView from '@/components/analytics/UserView.vue'
  import AccountView from '@/components/analytics/AccountView.vue'

  import { forceFileDownload } from '@/shared/setup/documentMethods.js'

  import { 
    notificationVisible, 
    notification, 
    dismissNotification, 
    updateNotification
  } from '@/shared/setup/notifications.js'

  export default {
    components: {
      HeaderTitle,
      Datepicker,
      UserView,
      AccountView
    },
    setup(){
      const { t } = useI18n()
      const store = useStore()
      const profile = computed(() => store.getters.profile)
      const templateOptions = computed(() => store.state.templates.latest)
      // const viewOptions = computed(() => profile.value.role == 'manager' ? [{ label: 'Account', value: 'account'},{ label: 'User', value: 'user'}] : [])
      
      const viewOptions = computed(() => [{ label: 'Account', value: 'account'},{ label: 'User', value: 'user'}])
      const view = ref('account')

      const userLevelView = computed(() => view.value == 'user')
      const accountLevelView = computed(() => view.value == 'account')
      
      const currentView = computed(() => {
        if (view.value == 'account') {
          return profile.value ? profile.value.account.name : ''
        } else {
          const user = userOptions.value.find(u => u.id == reportOptions.user_id)
          return user ? user.name : ''
        }
      })
      const userOptions = ref([])
      const downloading = ref(false)

      const waitingSelection = computed(() => view.value == 'user' && !reportOptions.user_id || view.value == 'account' && !profile.value)
      const format = ref('MM/dd/yyyy')
      const formatDate = inject('formatDate')

      // initial view: past 30 days
      const today = DateTime.local()
      const aMonthAgo = today.minus({months: 1})

      const start_at = ref(new Date(aMonthAgo.toISO()))
      const end_at = ref(new Date(today.toISO()))

      const formattedDates = computed(() => {
        return {
          startAt: jsDateToString(start_at.value),
          endAt: jsDateToString(end_at.value)
        }
      })


      const reportOptions = reactive({
        user_id: null,
        account_id: null,
        order_by: 'count desc'
      })

      const orderOptions = ref([
        {
          label: 'Total Desc',
          value: 'count desc'
        },
        {
          label: 'Total Asc',
          value: 'count asc'
        },
        {
          label: 'Template Name A-Z',
          value: 'name asc'
        },
        {
          label: 'Template Name Z-A',
          value: 'name desc'
        }
      ])

      

      const downloadableReports = ref([
        {
          name: 'Top and Bottom Advertisements',
          identifier: 'top_and_bottom_10_advertisements',
          format: 'csv'
        },
        {
          name: 'Top and Bottom Products',
          identifier: 'top_and_bottom_10_products',
          format: 'csv'
        },
        {
          name: 'Top and Bottom Suppliers',
          identifier: 'top_and_bottom_10_suppliers',
          format: 'csv'
        },
        {
          identifier: 'advertisements_not_utilized',
          name: 'Advertisements Not Utilized',
          format: 'csv'
        },
        {
          identifier: 'products_not_utilized',
          name: 'Products Not Utilized',
          format: 'csv'
        },
        {
          identifier: 'suppliers_not_utilized',
          name: 'Suppliers Not Utilized',
          format: 'csv'
        }
      ])

      

      const jsDateToString = (date) => {
        const dateString = formatDate(date.toISOString(), 'yyyy-MM-dd')
        return dateString
      }

    

      onBeforeMount(() => {
        if (profile.value) {
          getUsers()
        }

        if (!templateOptions.value.length) {
          store.dispatch('loadLatestTemplates')
        }
        
      })

      // METHODS

      const getUsers = async () => {
        const accountId = profile.value.account_id

        AnalyticsService.getUsers(accountId)
        .then(response => {
          userOptions.value = response.data.map(u => ({ id: u.id, name: [u.first_name, u.last_name].join(' ') }))
          reportOptions.user_id = profile.value.id
          reportOptions.account_id = profile.value.account_id
        })
        .catch(error => {
          console.log(error)
        })
      }

      

      const downloadReport = (report) => {
        downloading.value = true

        const options = {
          report_type: report.name,
          report_format: report.format
        }

        options.start_at = jsDateToString(start_at.value)
        options.end_at = jsDateToString(end_at.value)

        if (userLevelView.value) {
          options.user_id = reportOptions.user_id
        } else if (accountLevelView.value) {
          options.account_id = reportOptions.account_id
        }
        

        window.setTimeout(() => {
          // notifies if it takes longer than 2s
          if (downloading.value) {
            notification.message = 'Requesting data from the server. This might take a few seconds.'
            notification.type = 'warning'
            
            updateNotification(notification, true)
          }
          
        }, 2000)
        

        AnalyticsService.getAnalytics(options)
        .then(response => {
          if (response) {
            downloading.value = false
            forceFileDownload(response)
            updateNotification(false)
          }
        })
        .catch(e => {
          console.log(e)
          downloading.value = false
          notification.message = 'Issue encountered downloading report. Please report.'
          notification.type = 'danger'
          updateNotification(notification, true)
        })
      }


      watch(view, (v) => {
        if (v == 'user') {
          if (!userOptions.value.length) {
            getUsers()
          } else {
            reportOptions.user_id = profile.value.id
          }
          
        }
      })

      watch([start_at, end_at], (v) => {
        if (v[0] > v[1]) {
          const notification = {
            message: 'Invalid date selection. Start date should be earlier than end date.',
            type: 'danger'
          }

          updateNotification(notification, true)
        }
      })
      const toolbarVisible = ref(true)
      const toolBarClasses = computed(() => toolbarVisible.value ? 'animate__bounceInLeft' : 'animate__bounceOutLeft')

      provide('templateOptions', templateOptions)

      const updateDates = (unit) => {
        const today = DateTime.local()
        let startDate, endDate

        if (unit == 'last_week') {
          startDate = today.minus({ weeks: 1}).startOf('week')
          endDate = today.minus({ weeks: 1}).endOf('week')
        
        } else if (unit == 'this_month') {
          startDate = today.startOf('month')
          endDate = today
        
        } else if (unit == 'last_month') {
          startDate = today.minus({ months: 1}).startOf('month')
          endDate = today.minus({ months: 1}).endOf('month')
        }

        start_at.value = new Date(startDate.toISO())
        end_at.value = new Date(endDate.toISO())
      }

      const userViewReady = computed(() => userLevelView.value && profile.value && reportOptions.user_id)
      const accountViewReady = computed(() => accountLevelView.value && profile.value)

      return {
        t,
        today,
        format,
        notificationVisible, 
        notification, 
        dismissNotification, 
        updateNotification,
        orderOptions,
        start_at,
        end_at,
        formattedDates,
        userLevelView,
        accountLevelView,
        view,
        viewOptions,
        userOptions,
        reportOptions,
        downloadReport,
        toolBarClasses,
        downloadableReports,
        profile,
        currentView,
        downloading,
        toolbarVisible,
        waitingSelection,
        updateDates,
        accountViewReady,
        userViewReady
      }
    }
  }
</script>