<template>
  <Notification
    v-model="notificationVisible"
    :type="notification.type"
    @dismiss="dismissNotification"
  >
    {{ notification.message }}
  </Notification>
  <section class="section">
    <div class="container is-max-widescreen">
      <HeaderTitle
      :title="t('contacts.title')"
      :subtitle="t('contacts.subtitle')"
      :isOutlined="true"
      ></HeaderTitle>
    </div>
  </section>
  <div class="has-gray-bg pb-6">
    <div class="container is-max-widescreen">
      <div class="tabs pt-4 offset-pl">
          <ul>
            <li :class="{'is-active': tabs.active == tab}" v-for="(tab, index) in tabs.list" :key="index">
              <a @click="tabs.active = tab">{{ t(`contacts.${tab}.title`) }}</a>
            </li>
          </ul>
      </div>

      <div class="tab-content" v-show="tabs.active == 'people'" >
        <!-- search -->
        <div class="field">
          <div class="columns">
            <div class="column is-4">
              <control type="text" :placeholder="t('contacts.search.people_name')" v-model="peopleSearchForm.full_name_cont" />
            </div>
            <div class="column is-4">
              <control type="text" :placeholder="t('contacts.search.people_email')" v-model="peopleSearchForm.email_cont" />
            </div>
          </div>
        </div>
        

        <!--  people list -->
        <div v-if="peopleNotLoaded">
          <Loading :loading="loading.people"></Loading>
        </div>
        <div v-else class="columns is-multiline">
          <div class="column is-4" 
          v-for="(contact) in people"
          :key="contact.id"
          >
            <PersonCard
            :object="contact"
            ></PersonCard>
          </div>
        </div>

        <v-pagination
          v-show="pagination.people.totalPages > 1"
          v-model="pagination.people.currentPage"
          :pages="pagination.people.totalPages"
          :range-size="1"
          active-color="#28569A"
          @update:modelValue="fetchPage"
        />
      </div>

      <div class="tab-content" v-show="tabs.active == 'organizations'">
        <div class="field">
          <div class="columns">
            <div class="column is-4">
              <control type="text" :placeholder="t('contacts.search.people_name')" v-model="orgSearchForm.name_cont" />
            </div>
            <div class="column is-4">
              <control type="text" :placeholder="t('contacts.search.people_email')" v-model="orgSearchForm.email_cont" />
            </div>
          </div>
        </div>

        <!-- organization list -->

        <div v-if="organizationsNotLoaded">
          <Loading :loading="loading.organizations"></Loading>
        </div>
        <div v-else class="columns is-multiline" >
          <div class="column is-4" v-for="contact in organizations" :key="contact.id">
            <OrganizationCard
            :object="contact"
            ></OrganizationCard>
          </div>
        </div>
        <v-pagination
          v-show="pagination.organizations.totalPages > 1"
          v-model="pagination.organizations.currentPage"
          :pages="pagination.organizations.totalPages"
          :range-size="1"
          active-color="#28569A"
          @update:modelValue="fetchPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
require('@/assets/stylesheets/module_specific/contact.scss')
require('@/assets/stylesheets/module_specific/pagination.scss')
// import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import { useI18n } from 'vue-i18n'
import { reactive, ref, computed, watch, inject } from 'vue'
import { useStore } from 'vuex'
import Control from '@/components/shared/Control.vue'
import HeaderTitle from "@/components/shared/HeaderTitle.vue"
import PersonCard from "@/components/contact/PersonCard.vue"
import OrganizationCard from "@/components/contact/OrganizationCard.vue"

import ContactService from '@/services/ContactService.js'
import VPagination from "@hennge/vue3-pagination"

// import chunk from 'lodash/chunk'
import debounce from 'lodash/debounce'

import { 
  notificationVisible, 
  notification, 
  dismissNotification, 
  updateNotification
} from '@/shared/setup/notifications.js'

export default {
  setup(){
    const { t } = useI18n()
    const store = useStore()
    const settings = inject('clientGlobalSettings')

    const contactListLastUpdated = computed(() => store.state.contacts.updated_at)
    const timeSinceRefresh = computed(() => contactListLastUpdated.value ? (new Date().getTime() - contactListLastUpdated.value) / 60000 : null)

    let perPage = ref(15)
    let people = ref([])
    let organizations = ref([])

    const resolvePagination = (header, paginationObject) => {
      if (paginationObject){
        pagination[paginationObject].currentPage = header.current_page
        pagination[paginationObject].totalPages = header.total_pages
      } else {
        pagination.currentPage = header.current_page
        pagination.totalPages = header.total_pages
      }
      
    }

    const fetchPage = (newPage) => {
      if (tabs.active === 'people') {
        getPeople(newPage)
      } else if (tabs.active === 'organizations') {
        getOrganizations(newPage)
      }
    }

    const pagination = reactive({
      people: {
        currentPage: null,
        totalPages: null
      },
      organizations: {
        currentPage: null,
        totalPages: null
      }
    })

    const peopleSearchForm = reactive({
      full_name_cont: '',
      email_cont: ''
    })

    const orgSearchForm = reactive({
      name_cont: '',
      email_cont: ''
    })

    const loading = reactive({
      people: {
        initial: true,
        show: true
      },
      organizations: {
        initial: true,
        show: true
      }
    })

    const errors = ref([])

    const contentClass = ref("is-offset-4")

    const tabs = reactive({
      list: ["people", "organizations"],
      active: "people"
    })

    const getPeople = async (page) => {
      ContactService.getContacts(peopleSearchForm, perPage.value, page)
      .then(response => {
        people.value = response.data
        loading.people.initial = false
        loading.people.show = false
        resolvePagination(JSON.parse(response.headers['x-pagination']), 'people')
      })
      .catch(error => {
        console.log(error)
        const notification = {
          type: 'danger',
          message: 'Error encountered loading contacts.'
        }

        updateNotification(notification, true)
      })
    }

    const getOrganizations = async (page) => {
      ContactService.getOrganizations(orgSearchForm, perPage.value, page)
      .then(response => {
        organizations.value = response.data
        loading.organizations.initial = false
        loading.organizations.show = false
        resolvePagination(JSON.parse(response.headers['x-pagination']), 'organizations')
      })
      .catch(error => {
        console.log(error)
        const notification = {
          type: 'danger',
          message: 'Error encountered loading organizations.'
        }

        updateNotification(notification, true)
      })
    }

    const firstLoad = async () => {
      console.log(timeSinceRefresh.value, settings.cache_expiry.contacts)
      if (timeSinceRefresh.value == null || timeSinceRefresh.value > settings.cache_expiry.contacts){
        const peopleResult = await ContactService.getContacts(null, perPage.value, 1)
        const orgResult = await ContactService.getOrganizations(null, perPage.value, 1)

        
        people.value = peopleResult.data
        resolvePagination(JSON.parse(peopleResult.headers['x-pagination']), 'people')

        organizations.value = orgResult.data
        resolvePagination(JSON.parse(orgResult.headers['x-pagination']), 'organizations')

        loading.people.initial = false
        loading.people.show = false
        loading.organizations.initial = false
        loading.organizations.show = false

        const peopleObject = {
          contactType: 'people',
          data: peopleResult.data,
          pagination: pagination.people
        }

        const organizationsObject = {
          contactType: 'organizations',
          data: orgResult.data,
          pagination: pagination.organizations
        }

        // updates store (write)
        store.dispatch('saveContactList', peopleObject)
        store.dispatch('saveContactList', organizationsObject)


      } else {
        const contactsState = store.state.contacts
        people.value = contactsState.people.index
        pagination.people = contactsState.people.pagination
        loading.people.initial = false
        loading.people.show = false

        organizations.value = contactsState.organizations.index
        pagination.organizations = contactsState.organizations.pagination
        loading.organizations.initial = false
        loading.organizations.show = false
      }
      
    }

    watch(peopleSearchForm, debounce(() => {
      getPeople(1)
    }, 600))

    watch(orgSearchForm, debounce(() => {
      getOrganizations(1)
    }, 600))

    // const groupedPeople = computed(() => chunk(people.value, 3))
    // const groupedOrganizations = computed(() => chunk(organizations.value, 3))
    const peopleNotLoaded = computed(() => loading.people.initial)
    const organizationsNotLoaded = computed(() => loading.organizations.initial)
    
    firstLoad()

    return { 
      t,
      notificationVisible,
      notification,
      dismissNotification,
      updateNotification,
      people,
      peopleSearchForm,
      organizations,
      orgSearchForm,
      loading,
      tabs,
      firstLoad,
      errors,
      fetchPage,
      pagination,
      perPage,
      contentClass,
      // groupedPeople,
      // groupedOrganizations,
      peopleNotLoaded,
      organizationsNotLoaded
    }
  },
  components: {
    HeaderTitle,
    PersonCard,
    OrganizationCard,
    Control,
    VPagination
  },
}
</script>
