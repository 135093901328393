<template>
  <div>
    <label class="label" v-if="label">
      {{ label }}
    </label>
    <div v-if="options.length">
      <div class="control pt-1" v-for="(option) in options" :key="option.id">
        <label class="radio">
          <input v-model="computedValue" :id="option.id" :value="option.id.toString()" type="radio">
          {{ option.value_text_label || option.name }}
        </label>
        <override-option
          v-if="allowOverride" 
          :option="option"
          :documentItem="documentItems[option.id.toString()]"
          :attribute="overrideAttribute"
          :rows="rows"
          @update:input="updateDocumentItem"
        />
      </div>
    </div>
    <div v-else>
      <Loading :loading="options.length == 0"></Loading>
    </div>
    
    <field-message v-if="inputError.message.length" :object="inputError">
      {{ inputError.message }}
    </field-message>
  </div>
</template>

<script>
import { provide, inject, computed, ref } from 'vue'
import { inputError, updateInputError } from '@/shared/setup/notifications.js'
import { getGroupOptions } from '@/shared/setup/inputTypeHelpers.js'
import OverrideOption from '@/components/inputs/accessories/OverrideOption.vue'

export default {
  components: {
    OverrideOption
  },
  setup(props, { emit }) {
    const locale = inject('dmtLocale')
    const allowOverride = computed(() => props.input.properties.allow_override && props.input.properties.allow_override == 'true' )
    const overrideAttribute = computed(() => {
      if (allowOverride.value) {
        return properties.value.allow_override_value_text && properties.value.allow_override_value_text == true ? 'value_text' :
          properties.value.allow_override_value && properties.value.allow_override_value == 'true' ? 'value' :
          properties.value.allow_override_value2 && properties.value.allow_override_value2 == 'true' ? 'value2' : 
          properties.value.allow_override_value1 && properties.value.allow_override_value1 == 'true' ? 'value1' : undefined
      } else {
        return undefined
      }
    })
    const groupSelectName = computed(() => props.input.group.code)
    const groupSelect = inject('groupSelect')
    const properties = computed(() => props.input.properties)
    const computedValue = computed({
      get: () => {
        return props.input.value_array[1]
      },
      set: value => {
        const groupContentType = props.input.group.content_type || props.input.value_array[0]
        emit('update:modelValue', [groupContentType, value], props.input)
      }
    })

    const documentItems = computed({
      get: () => {
        return props.input.document_items
      }
    })

    const label = ref(properties.value.label)
    const placeholder = ref(properties.value.placeholder)

    const options = computed(() => {
      return getGroupOptions(groupSelect, groupSelectName.value, 'items')
    })

    const updateDocumentItem = (docItemId, attribute, newValue) => {
      emit('update:input', docItemId, attribute, newValue)
    }

    const rows = computed(() => props.input.properties.text_area_rows || null)

    provide('inputAllowsOverride', allowOverride.value)

    return {
      inputError,
      updateInputError,
      locale,
      computedValue,
      options,
      label,
      placeholder,
      documentItems,
      updateDocumentItem,
      allowOverride,
      overrideAttribute,
      rows
    }
  },
  props: {
    input: {
      type: Object,
      required: true
    },
  }
}
</script>
