import { securedAxiosInstance } from '@/axios/index.js'

export default {
  getProfile() {
    return securedAxiosInstance.get('/me')
  },
  updateProfile(user) {
    return securedAxiosInstance.post('/update_user', user, {
      headers: {
        'content-type': 'application/json'
      }
    })
  },
  getLocations(parent_id) {
    var url = '/locations'

    if (parent_id) {
      url = `/locations?document_id=${parent_id}`
    }
    return securedAxiosInstance.get(url)
  },
  updatePreferredDashboard(id){
    const baseUrl = '/update_preferred_dashboard/' + id
    return securedAxiosInstance.patch(baseUrl)
  },
  getLogos() {
    return securedAxiosInstance.get('/logo_image')
  }
}
