<template>
  <article class="media product-media">
    <figure class="media-left">
      <p class="image is-64x64">
        <img :src="imageUrl">
      </p>
    </figure>
    <div class="media-content">
      <div class="content">
        <nav class="level">
          <div class="level-left">
            <p class="level-item">
              <strong>{{ item.name }} ({{ item.id }})</strong>
            </p>
          </div>
          <div class="level-right">
            <p class="level-item">
              <span class="icon-text is-clickable" 
                @mouseenter="isHovering = true" 
                @mouseleave="isHovering = false" 
                :class="[{'has-text-success': selected || isHovering && !selected}, {'has-text-danger': isHovering && selected}]" 
                @click="updateSelection()"
                v-if="limitReached ? selected : true"
              >
                <span class="pr-1">
                  <font-awesome-icon icon="check-circle" size="sm" v-if="isHovering && !selected || !selected || selected && !isHovering"/>
                  <font-awesome-icon icon="times" size="sm" v-if="isHovering && selected" />
                </span>
                <span v-if="!isHovering || !selected">{{ selected ? t('helpers.selected') : t('helpers.select')}}</span>
                <span v-if="isHovering && selected">{{ t('helpers.remove') }}</span>
              </span>
            </p>
          </div>
        </nav>
      </div>
      <nav class="level is-mobile">
        <div class="level-left">
          <p class="level-item">
            <small>{{ t('dam.products.part_number')}}: {{ item.part_number }}</small>
          </p>
        </div>
        <div class="level-right">
          <p class="level-item">
            <small>{{ priceString }}</small>
          </p>
        </div>
      </nav>
    </div>
  </article>
</template>

<script>
  import { useStore } from 'vuex'
  import { ref, computed, inject } from 'vue'
  import { useI18n } from 'vue-i18n'

  export default {
    props: {
      item: {
        type: Object,
        required: true
      },
      selection: {
        type: Array
      }
    },
    emits: ['update:selection'],
    setup(props, { emit }) {
      const store = useStore()
      const { t } = useI18n()
      const imageSize = computed(() => store.getters.imageUrl)
      // missing URL tbd
      const imageUrl = computed(() => props.item.attachments && props.item.attachments.length ? props.item.attachments[0][imageSize] || props.item.attachments[0].url : '')

      const price = computed(() => props.item.price_cents ? (props.item.price_cents / 100).toFixed(2) : 'N/A')
      const priceString = computed(() => price.value == 'N/A' ? 'N/A' : props.item.price_currency + ' ' + price.value.toString())

      const maxSelection = inject('selectionLimit')
      const contentType = inject('contentType')

      const selectionCount = computed(() => {
        const filteredSelection = props.selection.filter(s => s !== contentType)

        return filteredSelection.length
      })

      const limitReached = computed(() => selectionCount.value == maxSelection)

      const updateSelection = () => {
        emit('update:selection', props.item)
      }

      const selectedItems = computed(() => props.selection)
      const selected = computed(() => selectedItems.value.includes(props.item.id.toString()))
      const isHovering = ref(false)

      return {
        t,
        imageUrl,
        price,
        priceString,
        updateSelection,
        selected,
        isHovering,
        selectedItems,
        limitReached
      }
    }
  }
</script>