<template>
  <div class="p-3">
    <div class="pb-4">
      <h3 class="title is-5">
        <slot>{{ t('output.' + option.content_type + '.content_title') }}</slot>
      </h3>
    </div>
    
    <p>{{ t('output.image.content') }}</p>
  
    <div class="mt-3">
      <p v-for="locale in option.locales" :key="locale.locale" class="flag-link">
        <country-flag :country="locale.country" size="normal" />
        <span>
          <download-image-btn :locale="locale" :dmtId="dmtId" :layoutId="layoutId" @notify="notify">
            {{ locale.locale_name }}
          </download-image-btn>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
  import CountryFlag from 'vue-country-flag-next'
  import { useI18n } from 'vue-i18n'
  import { ref } from 'vue'
  import DownloadImageBtn from '@/components/shared/DownloadImageBtn.vue'
  
  export default {
    components: {
      CountryFlag,
      DownloadImageBtn
    },
    props: {
      option: {
        type: Object,
        required: true
      }
    },
    emits: ['notify'],
    setup(props, { emit }) {
      const { t } = useI18n()
      const dmtId = ref(props.option.document_id)
      const layoutId = ref(props.option.layout_id)
      
      const notify = (notification) => {
        emit('notify', notification)
      }
      

      return { t, notify, dmtId, layoutId }
    }
  }
</script>