<template>
  <div class="field">
    <document-items
      v-for="input in computedChildren"
      :key="input.id"
      :input="input"
      @update:input="updateDocumentItem"
      @update:immediate="updateImmediate"
    ></document-items>
  </div>
</template>

<script>
  import { computed } from 'vue'
  import DocumentItems from '@/components/inputs/DocumentItems.vue'

  export default {
    props: {
      inputs: {
        type: [Array, Object]
      }
    },
    components: {
      DocumentItems
    },
    setup(props, { emit }){
      const computedChildren = computed({
        get: () => {
          return props.inputs ? props.inputs.value : []
        }
      })

      const updateDocumentItem = (inputRef, attr, newValue) => {
        emit('update:input', inputRef, attr, newValue)
      }

      const updateImmediate = (inputRef, attr, newValue) => {
        emit('update:immediate', inputRef, attr, newValue)
      }

      return {
        computedChildren,
        updateDocumentItem,
        updateImmediate
      }
    }
  }
</script>