import { createWebHistory, createRouter } from "vue-router"
import store from '../store'
import Privacy from '../views/General/Privacy.vue'
import Dashboard from '../views/General/Dashboard.vue'
import NotFoundComponent from '../views/General/NotFoundComponent.vue'
// test bed for staging only
import TestBed from '../views/General/TestBed.vue'

import UserLogin from '../views/Account/UserLogin.vue'
import UserAccount from '../views/Account/UserAccount.vue'

import Contacts from '../views/Contact/Contacts.vue'
import Contact from '../views/Contact/Contact.vue'
import Organization from '../views/Contact/Organization.vue'


import DAM from '../views/DAM/DigitalAssetManager.vue'
import Analytics from '../views/Analytics/Analytics.vue'
import TemplateIndex from '../views/Template/TemplateIndex.vue'
import DocumentIndex from '../views/Document/DocumentIndex.vue'
import Customize from '../views/Document/Customize.vue'
import Output from '../views/Document/Output.vue'
import PublicDocumentView from '../views/Document/Public.vue'


const routes = [
  
  {
    path: '/privacy-and-terms',
    name: 'privacy-and-terms',
    component: Privacy
  },
  {
    path: '/login/:token?',
    // props: true,
    props: route => ({ token: route.query.token, brand: route.query.brand }),
    name: 'login',
    component: UserLogin
  },
  {
    path: '/dashboard/:filter?',
    name: 'dashboard',
    props: true,
    component: Dashboard
  },
  {
    path: '/test',
    name: 'test-bed',
    component: TestBed
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: Analytics
  },
  {
    path: '/contacts',
    name: 'user-contacts',
    component: Contacts
  },
  {
    path: '/contact/:id',
    name: 'contact',
    props: true,
    component: Contact
  },
  {
    path: '/organization/:id',
    name: 'organization',
    props: true,
    component: Organization
  },
  {
    path: '/account',
    name: 'user-account',
    component: UserAccount
  },
  {
    path: '/dam',
    name: 'dam',
    component: DAM
  },
  {
    path: '/templates',
    name: 'template-index',
    component: TemplateIndex
  },
  {
    path: '/documents',
    name: 'document-index',
    component: DocumentIndex
  },
  {
    path: '/customize/:id',
    name: 'customize',
    props: true,
    component: Customize
  },
  {
    path: '/output/:id',
    name: 'output',
    props: true,
    component: Output
  },
  {
    path: '/published/:docId/:layoutId/:locale',
    name: 'public-document',
    props: true,
    component: PublicDocumentView
  },
  {
    path: "/",
    redirect: {
      name: "login"
    }
  },
  {
    path: '/404',
    name: 'not-found',
    component: NotFoundComponent
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFoundComponent
  }
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(from, to) {
    if (from.name && to.name && from.name !== to.name) {
      return { left: 0, top: 0, behavior: 'smooth' }
    }
    
  },
  routes: routes
})

router.beforeEach((to, from, next) => {
  const isAuth = store.state.session.isAuth
  const publicRoutes = ['login', 'reset-password', 'privacy-and-terms', 'not-found', 'public-document']

  store.commit('updateNextRoute', to)
  
  // if NOT going to public routes, authenticate
  if (!publicRoutes.includes(to.name) && !isAuth) 
    next({ name: 'login' })

  // if authenticated and tries login
  else if (to.name == 'login' && isAuth)
    next({name: 'dashboard'})
  else 
    next()
})

export default router
