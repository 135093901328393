<template>
  <div>
    <Notification
      v-model="notificationVisible"
      :type="notification.type"
      @dismiss="dismissNotification"
    >
      {{ notification.message }}
    </Notification>
    
    <section class="hero is-small is-fullheight has-background-grey-lighter">
      <div class="pt-3 pb-1 px-3">
        <nav class="level">
          <div class="level-left">
            <div class="level-item ">
              <div class="box py-2 px-4">
                <strong>Expiry date:</strong> {{ expiryDate }}
              </div>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item has-text-centered">
              <document-languages 
                :options="availableLocales"
                :loadingDocument="!loaded"
                @select="newLocale"
              />
            </div>
          </div>
        </nav>
      </div>
      <div class="hero-body">
        <div class="container">
          <layout-preview
            :locale="dmtLocale"
            :ts="userSelection"
            v-if="loaded"
            :layout="layout"
          ></layout-preview>
        </div>
      </div>
    </section>

    <overlay>
     <p class="title is-4">{{ t('documents.helpers.loading_published') }}</p>
    </overlay>
  </div>
</template>

<script>
  import { 
    notificationVisible, 
    notification, 
    dismissNotification, 
    updateNotification
  } from '@/shared/setup/notifications.js'

  import DocumentService from '@/services/DocumentService.js'
  import DocumentLanguages from '@/components/document/DocumentLanguages'
  import LayoutPreview from '@/components/document/preview/LayoutPreview'
  import { useStore } from 'vuex'
  import { computed, reactive, onBeforeUnmount, provide, ref, watch, inject } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'

  import { resolveUserSelection } from '@/shared/setup/populateMethods.js'

  export default {
    props: {
      layoutId: [String, Number],
      docId: [String, Number],
      locale: String
    },
    components: {
      LayoutPreview,
      DocumentLanguages
    },
    setup(props){
      const { t } = useI18n()
      const store = useStore()
      const router = useRouter()
      const userSelection = computed(() => store.state.currentDocument.sharedSelection)
      const dmtLocale = ref(props.locale)
      const dataElements = computed(() => dmt.data_elements || [])
      const assetElements = computed(() => dmt.asset_elements || [])
      const groupAssociations = computed(() => dmt.group_associations || [])
      const layout = reactive({})
      const dmt = reactive({})
      const formatDate = inject('formatDate')
      const output = computed(() => dmt.output_options ? dmt.output_options.find(o => o.layout_id == props.layoutId) : null)
      const availableLocales = computed(() => {
        const locales = output.value ? output.value.locales : []
        return locales
      })
      const expiryDate = computed(() => output.value ? formatDate(output.value.expiration_date, 'MM/dd/yyyy') : 'N/A')
      const loaded = ref(false)

      const setLoadingState = () => {
        store.dispatch('toggleLoading', true)
      }

      const loadPreview = () => {
        DocumentService.loadPreview(props.docId, props.layoutId, dmtLocale.value)
        .then(response => {
          store.dispatch('toggleLoading', false)
          loaded.value = true
          const loadedDocument = response.data

          console.log(loadedDocument)
          for (const [key, value] of Object.entries(loadedDocument)){
            dmt[key] = value
          }
          layout.preview_layouts = dmt.preview_layouts
          layout.properties = dmt.properties
         
        })
        .catch(error => {
          store.dispatch('toggleLoading', false)
          console.log("Error loading document: ", error)

          const notification = {
            message: 'Error encountered loading the document',
            type: 'danger'
          }

          updateNotification(notification, true)

        })
      }

      const newLocale = (locale) => {
        loaded.value = false
        dmtLocale.value = locale
        router.replace({ params: { locale: locale }})
        store.dispatch('clearDocumentSelection')
        loadPreview()
      }

      onBeforeUnmount(() => {
        // clear store selection
        store.dispatch('clearDocumentSelection')
      })

      setLoadingState()

      provide('dataElements', dataElements)
      provide('assetElements', assetElements)
      provide('dmtLocale', dmtLocale)

      watch(dataElements, (value) => {
        if (value.length) {
          resolveUserSelection(value, dmtLocale.value, groupAssociations.value)
        }
      })

      loadPreview()

      return {
        t,
        notificationVisible, 
        notification, 
        dismissNotification, 
        updateNotification,
        loaded,
        dmtLocale,
        newLocale,
        layout,
        availableLocales,
        userSelection,
        expiryDate
      }
    }
  }
</script>