<template>
  <section class="hero is-fullheight-with-navbar">
    <div class="hero-body">
      <div class="container has-text-centered">
        <p class="title">
          {{ t('not_found.title') }}
        </p>
        <p class="subtitle is-5">
          {{ t('not_found.subtitle') }}
        </p>
        <router-link :to="{ path: '/'}" class="button is-primary">{{ t('not_found.back') }}</router-link>
      </div>
    </div>
  </section>
</template>

<script>
  import { useI18n } from 'vue-i18n'
  export default {
    setup(){
      const { t } = useI18n()

      return {
        t
      }
    }
  }
</script>
