<template>
  <div class="field">
    <label class="label" v-if="label">
      {{ label }}
    </label>
    <!-- select footer -->
    <!-- if show_footer_img, shows only custom footers -->
    <div v-if="selectFooterImage && input.key == 'footer_address'" class="select">
      <select v-model="computedValue">
        <option value="" disabled>- Select -</option>
        <option v-for="option in options" 
        :key="option.value" 
        :value="option.value">
          {{ option.label }}
        </option>
      </select>
    </div>

    <!-- select address -->
    <!-- else - shows location list -->
    <div v-else>
      <div class="control pb-3" v-for="option in options" :key="option.id">
        <label class="radio">
          <input v-model="computedValue" :value="option.location_value" type="radio">
          {{ option.name }}
        </label>
        <location-info v-show="option.location_value == computedValue"  :location="option" />
      </div>
      <!-- plus, custom footers -->
      <div v-if="selectFooterImage">
        <div class="control pb-5" v-for="option in customFooterOptions" :key="option.id">
          <label class="radio footer-options">
            <input v-model="computedValue" :value="option.footer_value" type="radio">
            <img :src="option.url" class="footer-image" />
          </label>
        </div>
      </div>
    </div>

    <field-message v-if="inputError.message.length" :object="inputError">
      {{ inputError.message }}
    </field-message>

  </div>  
</template>

<script>
import { inject, computed, ref, onBeforeMount, watch } from 'vue'
import { inputError, updateInputError } from '@/shared/setup/notifications.js'
import { useStore } from 'vuex'
import LocationInfo from '@/components/inputs/accessories/LocationInformation'

import { findLocation, saveSelection } from '@/shared/setup/populateMethods.js'

export default {
  components: {
    LocationInfo
  },
  props: {
    input: {
      type: Object,
      required: true
    },
    // compModelVal: {
    //   type: [String, Number, Boolean, Array, Object],
    //   default: ''
    // },
  },
  setup(props, { emit }) {
    const locale = inject('dmtLocale')
    const store = useStore()
    const userLocations = computed(() => store.state.user.locations)
    
    const computedValue = computed({
      get: () => {
        // return props.compModelVal.value
        return props.input.value
      },
      set: value => {
        emit('update:modelValue', value, props.input)
        // POC2 - perhaps change this necessity
        // const object = {
        //   key: 'selectedLocation',
        //   value: selectedLocation.value
        // }

        // store.commit('saveDocumentSelection', object)
        // saveSelection('selectedLocation', selectedLocation.value)
      }
    })

    const label = ref(props.input.properties.label)
    const placeholder = ref(props.input.properties.placeholder)
    
    const selectFooterImage = computed(() => props.input.properties.no_image_footer && props.input.properties.no_image_footer == 'false')

    const options = computed(() => {
      let opt = userLocations.value

      if (selectFooterImage.value && props.input.key == 'footer_address') {
        const locationsWithCustomFooters = userLocations.value.filter(l => l.image_footers.length > 0)
        
        opt = locationsWithCustomFooters.map(location => ({ value: location.image_footers[0].footer_value, label: location.name}))
      } 

      return opt
    })

    const selectedLocation = ref()

    const customFooterOptions = computed(() => {
      let customFooters = []
      
      for (var i in options.value) {
        const opt = options.value[i]
        
        if (opt.image_footers.length) {
          customFooters.push(...opt.image_footers)
        }
      }
      
      return customFooters
    })

    

    onBeforeMount(() => {
       const locationMatch = findLocation(props.input.value, userLocations.value)
       selectedLocation.value = locationMatch
       saveSelection('selectedLocation', selectedLocation.value)
    })

    watch(computedValue, (v) => {
      const locationMatch = findLocation(v, userLocations.value)
      selectedLocation.value = locationMatch
      saveSelection('selectedLocation', selectedLocation.value)

    })

    watch(userLocations, (v) => {
      const locationMatch = findLocation(props.input.value, v)
      selectedLocation.value = locationMatch
      saveSelection('selectedLocation', selectedLocation.value)
    })


    return {
      inputError,
      updateInputError,
      locale,
      computedValue,
      options,
      label,
      placeholder,
      selectFooterImage,
      customFooterOptions
    }
  }
}
</script>
