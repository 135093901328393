<template>
	<figure class="image">
		<img :src="imageSrc" :alt="`${imgTitle} ${$t('helpers.image_preview')}`">
	</figure>
</template>

<script>
import { useStore } from 'vuex';
import { computed, ref } from "vue";

export default {
	props: {
		imageObject: {
			type: [String, Object],
			required: true
		},
		imageClass: {
			type: String,
			required: false
		}
	},
	setup(props){
		const store = useStore()
		const imageSize = computed(() => store.state.device.defaultImgSize)
		const image = typeof(props.imageObject) == 'string' ? props.imageObject : props.imageObject[`${imageSize.value}_url`]
		const imageSrc = ref(image)

		const imageMetadata = computed(() => props.imageObject[`${imageSize.value}_version_metadata`] )

		const imageRatio = computed(() => {
			const div = imageMetadata.value ? imageMetadata.value.width / imageMetadata.value.height : null
			var ratio

			if (div) {
				if (div == 1) {
					ratio = 'is-square'
				} else if (div < 1) {
					ratio = 'is-4by5'
				} else if (div > 1) {
					ratio = ''
				}
			} else {
				ratio = props.imageClass
			}
			
			return ratio
		})

		// TO-DO: "layout_name should be a generic title in the case of images"
		const imgTitle = computed(() => typeof(props.imageObject) !== 'string' && props.imageObject.layout_name ? props.imageObject.layout_name : "Placeholder")
			
		
		return {
			imageSize,
			imageSrc,
			imageMetadata,
			imageRatio,
			imgTitle
		}
	}
}
</script>