import AnalyticsService from '@/services/AnalyticsService.js'

async function recordAction(hash){
  if (hash) {
    try {
      const response = await AnalyticsService.recordUserAction(hash)
      console.log("Recorded ", response)
    } catch(e){
      console.log("Error recording action: ", e)
    } 
  }
}

function resolveHash(page, target, type, subtype, other) {
  const hash = {}

  if (page && target && type) {
    hash['action_page'] = page
    hash['action_target'] = target
    hash['action_type'] = type

    if (subtype) {
      hash['action_subtype']
    }

    if (other) {
      for (const [k, v] of Object.entries(other)) {
        hash[k] = v
      }
    }

    return hash
  } else {
    return null
  }
}

function convertToMinutes(seconds) {
  return Math.floor(seconds / 60)
}

const accountReports = [
  {
    name: 'Template Use by Date and Template',
    ui_label: 'Documents created by template (daily)',
    identifier: 'documents_created_by_template',
    label: 'Number of documents created',
    color: '#28569B',
    chartType: 'line',
    multiple: true
  },
  {
    name: 'Template Use by Date and Template',
    ui_label: 'Documents published by template (daily)',
    identifier: 'documents_published_by_template',
    label: 'Number of documents published',
    color: '#28569B',
    chartType: 'line',
    multiple: true
  },
  {
    name: 'Documents Created by Time Group',
    ui_label: 'Average time spent in a document per template',
    identifier: 'avg_time_per_document',
    label: 'Average time spent in a document per template',
    color: '#28569B',
    chartType: 'bar',
    multiple: true
  },
  {
    name: 'Time in Platform',
    ui_label: 'Time in platform',
    identifier: 'time_in_platform',
    label: 'Minutes spent in the platform',
    color: '#28569B',
    chartType: 'levelItem'
  },
  {
    name: 'Template Use by Date',
    ui_label: 'Documents created (daily)',
    identifier: 'documents_created',
    label: 'Number of documents created',
    color: '#28569B',
    chartType: 'levelItem'
  },
  {
    name: 'Template Use by Date',
    ui_label: 'Documents published (daily)',
    identifier: 'documents_published',
    label: 'Number of documents published',
    color: '#28569B',
    chartType: 'levelItem'
  },
  {
    name: 'Top and Bottom Advertisements',
    ui_label: 'Advertisements',
    identifier: 'top_and_bottom_10_advertisements',
    label: 'Top and Bottom Advertisements',
    chartType: 'list'
  },
  {
    name: 'Top and Bottom Suppliers',
    ui_label: 'Suppliers',
    identifier: 'top_and_bottom_10_suppliers',
    label: 'Top and Bottom 10 Suppliers',
    chartType: 'list'
  },
  {
    name: 'Top and Bottom Products',
    ui_label: 'Products',
    identifier: 'top_and_bottom_10_products',
    label: 'Top and Bottom Products',
    chartType: 'list'
  }
]

const userReports = [
  {
    name: 'Documents Created',
    ui_label: 'Documents created by template',
    identifier: 'documents_created',
    label: 'Number of documents created',
    color: '#28569B',
    chartType: 'bar'
  },
  {
    name: 'Documents Published',
    ui_label: 'Documents published by template',
    identifier: 'documents_published',
    label: 'Number of documents published by template',
    color: '#28569B',
    chartType: 'bar'
  },
  {
    name: 'Average Build Time',
    ui_label: 'Average build time',
    identifier: 'avg_build_time',
    label: 'Average build time (minutes)',
    color: '#18335C',
    chartType: 'bar'
  },
  {
    name: 'Time in Platform',
    ui_label: 'Time in platform',
    identifier: 'time_in_platform',
    label: 'Minutes spent in the platform',
    color: '#28569B',
    chartType: 'levelItem',
  },
  {
    name: 'Top and Bottom Advertisements',
    ui_label: 'Advertisements',
    identifier: 'top_and_bottom_10_advertisements',
    label: 'Top and bottom advertisements',
    chartType: 'list'
  },
  {
    name: 'Top and Bottom Products',
    ui_label: 'Products',
    identifier: 'top_and_bottom_10_products',
    label: 'Top and bottom products',
    chartType: 'list'
  },
  {
    name: 'Top and Bottom Suppliers',
    ui_label: 'Suppliers',
    identifier: 'top_and_bottom_10_suppliers',
    label: 'Top and bottom 10 suppliers',
    chartType: 'list'
  },
]

const accountLevelItems = [
  {
    identifier: 'time_in_platform',
    name: 'Minutes in the platform',
    value: 0
  },
  {
    identifier: 'documents_created',
    name: 'Documents Created',
    value: 0
  },
  {
    identifier: 'documents_published',
    name: 'Documents Published',
    value: 0
  }
]

const userLevelItems = [
  {
    identifier: 'time_in_platform',
    name: 'Minutes in the platform',
    value: 0
  },
  {
    identifier: 'documents_created',
    name: 'Documents Created',
    value: 0
  },
  {
    identifier: 'documents_published',
    name: 'Documents Published',
    value: 0
  }
]

const chartOptions = {
  scales: {
    y: {
      beginAtZero: true,
      min: 0,
      ticks: {
          beginAtZero: true,

      }
    }
  },
  maintainAspectRatio: false,
  legend: {
    position: 'top',
    align: 'left',
    labels: {
      padding: 10
    },
  }
}

const brandColors = ['#28569a', '#1ABAD6', '#E68E1C']
const multipleColors = ['#28569a', '#1ABAD6', '#F0E80C', '#E68E1C', '#AB0A5D', '#101261']
const pointColor = '#ffffff'

export { recordAction, resolveHash, convertToMinutes, accountReports, accountLevelItems, userReports, userLevelItems, chartOptions, brandColors, multipleColors, pointColor }