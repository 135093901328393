import { securedAxiosInstance } from '@/axios/index.js'

export default {
  getAnalytics(reportOptions){
    const requestUrl = '/analytics'
    const report_options = {}
    const report_type = reportOptions.report_type

    for (const [key, value] of Object.entries(reportOptions)){
      if (value && key != 'report_type') {
        report_options[key] = value
      }
    }
    return securedAxiosInstance.post(requestUrl, { report_type: report_type, report_options: report_options })
  },
  recordUserAction(hash){
    return securedAxiosInstance.post('/record_user_action', hash)
  },
  getUsers(accountId){
    const baseUrl = 'users/index?q[account_id_eq]=' + accountId
    return securedAxiosInstance.get(baseUrl)
  }
}