<template>
  <div class="py-5 part-box-detail is-relative">
    <div class="buttons are-small">
      <button class="button is-primary is-small" role="button" :title="t('buttons.view')" v-if="hasAttachments" @click="attachmentVisible = !attachmentVisible">
        <span class="icon is-small">
          <font-awesome-icon icon="eye" size="sm" v-if="!attachmentVisible" />
          <font-awesome-icon icon="eye-slash" size="sm" v-if="attachmentVisible" />
        </span>
      </button>
      <button class="button is-danger is-small" role="button" :title="t('buttons.remove')" @click="updateSelection()">
        <span class="icon is-small">
          <font-awesome-icon icon="minus-circle" size="sm"/>
        </span>
      </button>
    </div>
    <div class="columns">
      <div class="column" v-if="allowedOverrides.includes('value')">
          <label class="label">{{ t('dam.products.part_number') }}</label>
          <input class="input is-small" type="text" :value="item.part_number" readonly>
      </div>
      <div class="column" v-if="allowedOverrides.includes('value_price') || allowedOverrides.includes('price')">
        <label class="label" v-show="!item['value_price_label']">{{ t('dam.products.price') }}</label>
        <document-item-attribute
          :input="input"
          :item="item"
          attribute="value_price"
          size="small"
          :hideLabel="true"
          @update:documentItem="updateDocumentItem"
        />
      </div>
    </div>
    <div class="columns" v-if="allowedOverrides.includes('value_text')">
      <div class="column" v-if="allowedOverrides.includes('value')">
        <document-item-attribute
          :input="input"
          :item="item"
          attribute="value"
          size="small"
          @update:documentItem="updateDocumentItem"
        />
      </div>
    </div>
    <attachment-list 
      v-if="hasAttachments"
      :attachments="item.attachments"
      v-show="attachmentVisible"
    />
  </div>
</template>
<script>
  import DocumentItemAttribute from '@/components/inputs/accessories/DocumentItemAttribute.vue'
  import AttachmentList from '@/components/inputs/accessories/AttachmentList.vue'
  import { computed, ref } from 'vue'
  import { useI18n } from 'vue-i18n'
  // import { useStore } from 'vuex'

  export default {
    props: {
      item: {
        type: Object,
        required: true
      },
      input: {
        type: Object,
        required: true
      }
    },
    components: {
      DocumentItemAttribute,
      AttachmentList,
    },
    emits: ['update:documentItem', 'update:selection'],
    setup(props, { emit }) {
      const { t } = useI18n()
      // const store = useStore()

      const properties = computed(() => props.input.properties)
      const attachmentVisible = ref(false)
      
      // multiple overrides are allowed
      const allowedOverrides = computed(() => {
        const subst = 'allow_override_'
        const overrides = []

        for (const [key, value] of Object.entries(properties.value)) {
          if (key.includes(subst) && [true, 'true'].includes(value)) {
            overrides.push(key.split(subst)[1])
          }
        }

        return overrides
      })

      const updateDocumentItem = (inputRef, attr, newValue) => {
        // const diRef = props.item.selectable_id
        console.log(inputRef, attr, newValue)
        emit('update:documentItem', inputRef, attr, newValue)
      }
       

      const updateSelection = () => {
        const item = {
          id: props.item.selectable_id
        }
        // adds or removes product
        emit('update:selection', item)
      }

      const hasAttachments = computed(() => props.item.attachments && props.item.attachments.length)

      return {
        t,
        allowedOverrides,
        attachmentVisible,
        updateDocumentItem,
        updateSelection,
        hasAttachments
      }
    }
  }
</script>