<template>
  <div class="card">
    <div class="columns card-content">
      <div class="column is-5">
        <p>About this contact: Lorem ipsum dolor sit amet, consectetur adipisicing, elit. Inventore quos dolores voluptatibus adipisci, odit non, alias molestiae sapiente, voluptas temporibus soluta, placeat nisi odio et quis voluptatum praesentium suscipit nostrum.</p>
      </div>
      <div class="column">
        <IconText
          iconName="phone"
          type="phone"
          :href="object.home_phone"
          class="mb-2"
        > {{ object.home_phone }}</IconText>
        <IconText
          iconName="phone"
          type="phone"
          :href="object.mobile_phone"
          class="mb-2"
        >
          {{ object.mobile_phone }}
        </IconText>
        <IconText
          iconName="phone"
          type="phone"
          :href="object.work_phone"
          class="mb-2"
        >
          {{ object.work_phone }}
        </IconText> 
        <IconText
          iconName="desktop"
          target="_blank"
          type="anchor"
          :href="object.website"
          class="mb-2"
          @click="record(object, 'Website')"
        >
          {{ t('generic.website') }}
        </IconText>
        <IconText
          iconName="inbox"
          type="mail"
          :href="object.email"
          @click="record(object, 'Email')"
          class="mb-2"
        >
          {{ object.email }}
        </IconText>
      </div>
      <div class="column is-3">
        <FigureImage
          :imageObject="object.logo ? object.logo : '/missing-4by3.png'"
          imageClass="is-128x128"
        ></FigureImage>  
      </div>
    </div>
  </div>
</template>

<script>
import IconText from '@/components/shared/IconText.vue'
import FigureImage from '@/components/shared/FigureImage.vue'
import { useI18n } from 'vue-i18n'
import { resolveHash, recordAction } from '@/shared/setup/analyticsMethods.js'

export default {
  components: {
    IconText,
    FigureImage
  },
  props: {
    object: {
      type: [Object],
      required: true
    }
  },
  setup(){
    const { t } = useI18n()

    const record = (contact, action) => {
      const other = {
        contact_id: contact.id
      }

      const hash = resolveHash('Contacts', 'Contact', action, null, other)
      recordAction(hash)
    }

    return { t, record }
  }
  
}
</script>