<template>
  <nav class="level pt-4 pb-6">
    <div class="level-item has-text-centered" v-for="item in levelItems" :key="item.identifier">
      <div>
        <p class="heading">{{ item.name }}</p>
        <p class="title">{{ item.value }}</p>
      </div>
    </div>
  </nav>

  <template-view
    :startAt="startAt"
    :endAt="endAt"
    :accountId="accountId"
    :orderBy="orderBy"
  />

  <!-- line reports -->
  <div v-for="report in lineReports" :key="report.identifier">
    <h2 class="title is-4">
      {{ report.ui_label }}
    </h2>

    <div class="chart-container single-chart mt-3 box">
      <line-chart v-if="loaded[report.identifier]" :chartData="lineCharts[report.identifier]" :chartOptions="chartOptions"/>
    </div>
  </div>

  <!-- bar reports -->
  <div v-for="report in barReports" :key="report.identifier">
    <h2 class="title is-4" >
      {{ report.ui_label }}
    </h2>

    <div class="chart-container single-chart mt-3 box">
      <bar-chart v-if="loaded[report.identifier]" :chartData="barCharts[report.identifier]" :chartOptions="chartOptions"/>
    </div>
  </div>

  <!-- list reports  -->
  <div v-for="report in listReports" :key="report.identifier">
    <h2 class="title is-4" v-if="loaded[report.identifier]">
      {{ report.ui_label }}
    </h2>

    <div class="mt-3 single-chart box" v-if="loaded[report.identifier]">
      <list-report :datasets="listCharts[report.identifier].datasets" />
    </div>
  </div>

</template>

<script>
  import LineChart from '@/components/analytics/visual/LineChart.vue'
  import BarChart from '@/components/analytics/visual/BarChart.vue'
  import ListReport from '@/components/analytics/visual/ListReport.vue'

  import TemplateView from '@/components/analytics/TemplateView.vue'

  import { reactive, computed, onBeforeMount, watch, toRefs, ref } from 'vue'

  import AnalyticsService from '@/services/AnalyticsService.js'

  import { convertToMinutes, accountReports, accountLevelItems, chartOptions, brandColors, multipleColors, pointColor } from '@/shared/setup/analyticsMethods.js'

  export default {
    components: {
      BarChart,
      ListReport,
      LineChart,
      TemplateView
    },
    props: {
      accountId: {
        type: [Number, String],
        required: true
      },
      startAt: {
        type: String,
        required: true
      },
      endAt: {
        type: String,
        required: true
      },
      orderBy: {
        type: String,
        required: false,
        default: 'count desc'
      }
    },
    setup(props){
      const barCharts = reactive({})
      const lineCharts = reactive({})
      const listCharts = reactive({})
      const loaded = reactive({})

      const reportOptions = computed({
        get: () => {
          return {
            start_at: props.startAt,
            end_at: props.endAt,
            order_by: props.orderBy,
            account_id: props.accountId
          }
        }
      })

      const reportTypes = accountReports
      const levelItems = ref(accountLevelItems)

      reportTypes.forEach(rt => {
        loaded[rt.identifier] = false
      })


      const barReports = computed(() => reportTypes.filter(r => r.chartType == 'bar'))
      const listReports = computed(() => reportTypes.filter(r => r.chartType == 'list'))
      const lineReports = computed(() => reportTypes.filter(r => r.chartType == 'line'))

      // const chartOptions = reactive(chartOptions)

      const generateDocumentReports = () => {
        resetCharts()
        const requests = []

        reportTypes.forEach((rt) => {
          const requestOptions = Object.assign({}, reportOptions.value)
          requestOptions.start_at = props.startAt
          requestOptions.end_at = props.endAt
          requestOptions.report_type = rt.name

          // modifications to options by report

          if (rt.identifier == 'documents_published') {
            requestOptions.report_on = 'docs_published'
          }

          if (rt.identifier == 'time_in_platform') {
            requestOptions.dt_unit = 'Hour'
          }

          if (rt.identifier.includes('top_and_bottom')) {
            requestOptions.top_set_size = 10
            requestOptions.bottom_set_size = 10
            delete requestOptions.order_by
          }

          requests.push(AnalyticsService.getAnalytics(requestOptions))
        })

        Promise.all(requests)
        .then(responses => {
          responses.forEach((r, i) => {
            
            if (r) {
              const data = r.data
              const newReport = {}
              const report = reportTypes[i]

              if (data.labels) {
                newReport.labels = data.labels
              }

              if (report.multiple) {
                
                if (report.chartType == 'bar') {
                  const newLabels = data.datasets[0].labels
                  const backgroundColors = brandColors

                  newReport.datasets = newLabels.map((l, i) => ({ label: l, backgroundColor: backgroundColors[i], data: data.datasets.map(ds => ds.datasets[i]) }))
                } else if (report.chartType == 'line') {
                  const colors = multipleColors
                  
                  newReport.datasets = data.datasets.map(ds => {
                    const borderColor = colors[Math.floor(Math.random() * colors.length)]
                    return ({ label: ds.label, borderColor: borderColor, fill: false, pointBackgroundColor: pointColor, data: ds.data })
                  })
                }

              } else {

                newReport.datasets = [
                  {
                    label: report.label,
                    data: data.datasets
                  }
                ]

                if (report.chartType == 'line' ) {
                  newReport.datasets[0].borderColor = reportTypes[i].color || brandColors[1]
                  newReport.datasets[0].fill = false
                  newReport.datasets[0].pointBackgroundColor = pointColor
                  newReport.datasets[0].pointBorderColor = pointColor
                } else {
                  newReport.datasets[0].backgroundColor = reportTypes[i].color || brandColors[1]
                }
                
              }
              
              
              const key = report.identifier
              
              if (key) {

                if (report.chartType == 'bar') {
                  barCharts[key] = newReport
                }

                if (report.chartType == 'line') {
                  lineCharts[key] = newReport
                }

                if (report.chartType == 'list') {
                  listCharts[key] = newReport
                }

                loaded[key] = true

                if (report.chartType == 'levelItem') {
                  const index = levelItems.value.findIndex(item => item.identifier == key)
                  
                  if (index >= 0) {
                    levelItems.value[index].value = data.datasets && data.datasets.length ? data.datasets.reduce((a, b) => a + b ) : 0

                    if (key == 'time_in_platform') {
                      levelItems.value[index].value = convertToMinutes(levelItems.value[index].value).toLocaleString('en')
                    } 
                  }
                }

                 
              }
            }
          })
        })
        .catch(errors => {
          console.log(errors)
        })
      }

      onBeforeMount(() => {
        generateDocumentReports()
      })

      const startDate = toRefs(props).startAt
      const endDate = toRefs(props).endAt
      const orderBy = toRefs(props).orderBy

      watch([startDate, endDate, orderBy], (v) => {
        if (v[1] > v[0]) {
          generateDocumentReports()
        }
      })

      const resetCharts = () => {
        Object.keys(loaded).forEach(k => {
          loaded[k] = false
        })
      }
      
      return {
        chartOptions,
        levelItems,
        reportTypes,
        barCharts,
        barReports,
        lineCharts,
        lineReports,
        listCharts,
        listReports,
        loaded
      }
    }
  }
</script>