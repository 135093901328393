<template>
  <div class="field">
    <label class="label" v-if="label">
      {{ label }}
    </label>

    <p class="control has-icons-right">
      <datepicker v-model="computedValue" :typeable="allowsNull" :inputFormat="format" :lowerLimit="lowerLimit" class="input"/>
      <span class="icon is-small is-right">
        <font-awesome-icon icon="calendar" />
      </span>
    </p>
    
    <field-message v-if="inputError.message.length" :object="inputError">
      {{ inputError.message }}
    </field-message>
  </div>
</template>

<script>
import { inject, computed, ref } from 'vue'
import { inputError, updateInputError } from '@/shared/setup/notifications.js'
import Datepicker from 'vue3-datepicker'
export default {
  components: {
    Datepicker
  },
  emits: ['update:modelValue'],
  setup(props, { emit }){
    const locale = inject('dmtLocale')
    const allowsNull = ref(true)
    const format = ref('MM/dd/yyyy')
    const today = new Date()
    const lowerLimit = ref(today)
    const computedValue = computed({
      get: () => {
        let initialDate
        const date = props.input.value_date

        if (date) {
          const dateArr = date.split("-")
          const y = dateArr[0]
          const m = parseInt(dateArr[1]) - 1
          const d = dateArr[2]

          initialDate = new Date(y, m, d)
        } else {
          initialDate = allowsNull.value ? null : new Date()
        }
        

        return initialDate
      },
      set: value => {
        const ymd = value.toISOString().split("T")[0]
        emit('update:modelValue', ymd)
        
      }

    })

    const label = computed(() => props.input.properties.label)
    const placeholder = computed(() => props.input.properties.placeholder)

    return {
      inputError,
      updateInputError,
      locale,
      computedValue,
      label,
      format,
      allowsNull,
      placeholder,
      lowerLimit
    }
  },
  props: {
    input: {
      type: Object,
      required: true
    },
    // compModelVal: {
    //   type: [String, Number, Boolean, Array, Object],
    //   default: ''
    // }
  }
}
</script>
