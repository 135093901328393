<template>
  <demo :filter="filter"/>
  <!-- <version-one v-else /> -->
</template>

<script>
import { useStore } from 'vuex'
import { computed, onMounted } from 'vue'
// import VersionOne from '@/components/dashboards/VersionOne.vue'
import Demo from '@/components/dashboards/Demo.vue'
import { resolveHash, recordAction } from '@/shared/setup/analyticsMethods.js'

export default {
  components: {
    // VersionOne,
    Demo
  },
  props: {
    filter: String
  },
  setup(){
    const store = useStore()
    const hasActiveDashboard = computed(() => store.getters.activeDashboard)

    onMounted(() => {
      const hash = resolveHash('Dashboard', 'Dashboard', 'Display')
      recordAction(hash)
    })
    

    return {
      hasActiveDashboard
    }
  },
}
</script>

