<template>
  <div class="field">
    <div v-if="allowsOverride">
      <document-item-attribute
        :input="input"
        attribute="value_text"
        :maxLength="textLimit"
        @update:documentItem="updateDocumentItem"
      />
    </div>

    <!-- v-else -->
    <div v-else>
      <label class="label" v-if="label">
        {{ label }}
      </label>
        
      <textarea
        class="textarea"
        v-model="computedValue"
        :placeholder="placeholder"
        @change="emitUpdate($event)"
        :rows="rows"
        :maxLength="textLimit"
      ></textarea>
    </div>
    
    <field-message v-if="inputError.message.length" :object="inputError">
      {{ inputError.message }}
    </field-message>
  </div>
</template>

<script>
import { inject, computed } from 'vue'
import { inputError, updateInputError } from '@/shared/setup/notifications.js'
import DocumentItemAttribute from '@/components/inputs/accessories/DocumentItemAttribute.vue'
export default {
  components: {
    DocumentItemAttribute
  },
  setup(props, { emit }){
    const allowsOverride = computed(() => props.input.properties.allow_override_value_text && props.input.properties.allow_override_value_text == 'true' )
    const locale = inject('dmtLocale')
    const computedValue = computed({
      get: () => {
        // return props.compModelVal.value
        return props.input.value_text
      },
      set: value => {
        emit('update:immediate', value)
      }
    })

    const label = computed(() => props.input.properties.label)
    const placeholder = computed(() => props.input.properties.placeholder)
    const rows = computed(() => props.input.properties.text_area_rows || null)
    const textLimit = computed(() => props.input.properties.text_area_size || 3000)
    
    const emitUpdate = (ev) => {
      const value = ev.target.value
      emit('update:modelValue', value)
    }

    const updateDocumentItem = (inputRef, attr, newValue) => {
      emit('update:input', inputRef, attr, newValue)
    }

    return {
      inputError,
      updateInputError,
      locale,
      computedValue,
      label,
      placeholder,
      allowsOverride,
      emitUpdate,
      updateDocumentItem,
      rows,
      textLimit
    }
  },
  props: {
    input: {
      type: Object,
      required: true
    },
    // compModelVal: {
    //   type: [String, Number, Boolean, Array, Object],
    //   default: ''
    // }
  }
}
</script>
