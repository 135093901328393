function documentItemsPriceArray (de){
  const prices = Object.values(de.document_items).map(di => di[di.locale].value_price / 100)
  return prices
}

function arrSum (arr){
  if (arr) {
    return arr.reduce(function(a, b) {
      return parseFloat(a) + parseFloat(b)
    }, 0)
  }
}

function scrollTo (x, y) {
  window.scroll({
    top: y,
    left: x,
    behavior: 'smooth'
  })
}

export { arrSum, documentItemsPriceArray, scrollTo }