<template>
  <div v-for="report in barReports" :key="report.identifier">
    <div class="level" v-if="report.hasSubgroup">
      <div class="level-left">
        <div class="level-item">
          <h2 class="title is-4">
            {{ report.ui_label }}
          </h2>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item" v-if="['documents_created_by_template', 'documents_published_by_template'].includes(report.identifier)">
          <div class="select is-fullwidth" v-if="templateOptions.length">
            <select v-model="templateId">
              <option value="" selected>- Select template -</option>
              <option :value="option.id" v-for="option in templateOptions" :key="option.id">{{ option.name }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <h2 class="title is-4" v-else>
      {{ report.ui_label }}
    </h2>

    <div class="chart-container mt-3 box single-chart">
      <bar-chart v-if="loaded[report.identifier]" :chartData="barCharts[report.identifier]" :chartOptions="chartOptions"/>
    </div>
  </div>
  
</template>

<script>
  import BarChart from '@/components/analytics/visual/BarChart.vue'
  import AnalyticsService from '@/services/AnalyticsService.js'

  import { inject, watch, reactive, ref, computed, toRefs, onBeforeMount } from 'vue'
  import { chartOptions, brandColors } from '@/shared/setup/analyticsMethods.js'

  export default {
    components: {
      BarChart,
    },
    props: {
      accountId: {
        type: [Number, String],
      },
      userId: {
        type: [Number, String],
      },
      startAt: {
        type: String,
        required: true
      },
      endAt: {
        type: String,
        required: true
      },
      orderBy: {
        type: String,
        required: false,
        default: 'count desc'
      }
    },
    setup(props) {
      const barCharts = reactive({})
      const lineCharts = reactive({})
      const listCharts = reactive({})
      const loaded = reactive({})

      const reportTypes = [
        {
          name: 'Template Use by Date',
          hasSubgroup: true,
          ui_label: 'Documents created (daily)',
          identifier: 'documents_created_by_template',
          label: 'Number of documents created',
          color: '#28569B',
          chartType: 'bar'
        },
        {
          name: 'Template Use by Date',
          hasSubgroup: true,
          ui_label: 'Documents published (daily)',
          identifier: 'documents_published_by_template',
          label: 'Number of documents published',
          color: '#28569B',
          chartType: 'bar'
        },
      ]
      
      const barReports = computed(() => reportTypes.filter(r => r.chartType == 'bar'))

      const templateOptions = inject('templateOptions')
      const templateId = ref('')
      const selectedTemplate = computed(() => {
        const template = templateOptions.value.find(t => t.id == templateId.value)
        return template
      })

      const generateDocumentReports = () => {
        resetCharts()
        const requests = []

        reportTypes.forEach((rt) => {
          const requestOptions = {}
          requestOptions.start_at = props.startAt
          requestOptions.end_at = props.endAt
          requestOptions.report_type = rt.name

          if (rt.identifier == 'documents_created_by_template') {
            
            if (props.accountId) {
              requestOptions.account_id = props.accountId
            } else if (props.userId) {
              requestOptions.user_id = props.userId
            }
            // must select template id
            requestOptions.template_id = templateId.value
          }

          if (rt.identifier == 'documents_published_by_template') {
            requestOptions.report_on = 'docs_published'
          }

          requests.push(AnalyticsService.getAnalytics(requestOptions))
        })
        Promise.all(requests)
        .then(responses => {
          responses.forEach((r, i) => {
            if (r) {
              const data = r.data
              const newReport = {}
              const report = reportTypes[i]

              if (data.labels) {
                newReport.labels = data.labels
              }

              if (report.multiple) {
                const newLabels = data.datasets[0].labels
                const backgroundColors = brandColors

                newReport.datasets = newLabels.map((l, i) => ({ label: l, backgroundColor: backgroundColors[i], data: data.datasets.map(ds => ds.datasets[i]) }))

              } else {

                newReport.datasets = [
                  {
                    label: report.label,
                    data: data.datasets
                  }
                ]

                if (report.chartType == 'line' ) {
                  newReport.datasets[0].borderColor = reportTypes[i].color || brandColors[0]
                  newReport.datasets[0].fill = false
                } else {
                  newReport.datasets[0].backgroundColor = reportTypes[i].color || brandColors[0]
                }
                
              }
              
              
              const key = report.identifier
              
              if (key) {

                if (report.chartType == 'bar') {
                  barCharts[key] = newReport
                }

                if (report.chartType == 'line') {
                  lineCharts[key] = newReport
                }

                if (report.chartType == 'list') {
                  listCharts[key] = newReport
                }

                loaded[key] = true
              }
            }
          })

        })
        .catch(errors => {
          console.log(errors)
        })
      }

      const startDate = toRefs(props).startAt
      const endDate = toRefs(props).endAt
      const orderBy = toRefs(props).orderBy
      const userId = toRefs(props).userId
      const accountId = toRefs(props).accountId

      watch([startDate, endDate, orderBy, templateId, userId, accountId], (v) => {
        if (v[1] > v[0]) {
          generateDocumentReports()
        }
      })

      const resetCharts = () => {
        Object.keys(loaded).forEach(k => {
          loaded[k] = false
        })
      }

      onBeforeMount(() => {
        generateDocumentReports()
      })

      return {
        chartOptions,
        barCharts,
        lineCharts,
        reportTypes,
        templateOptions,
        selectedTemplate,
        templateId,
        barReports,
        loaded,
        resetCharts
      }
    }
  }
</script>