<template>
  <div>
    <vue-horizontal :button="true" v-if="documents.length">
      <div class="item" :class="[itemClass, {'is-hidden': deleteQueue.includes(doc.id)}]" v-for="doc in documents"  :key="doc.id">
        <DocumentCard :document="doc" :class="cardClass" @delete="deleteDocument" :actions="actions">
        </DocumentCard>
      </div>
    </vue-horizontal>
  </div>
</template>


<script>
import { computed, ref } from 'vue'
import DocumentCard from '@/components/document/DocumentCard'
import VueHorizontal from 'vue-horizontal'

export default {
  components: {
    DocumentCard,
    VueHorizontal
  },
  props: {
    documents: {
      type: [Object, Array],
      required: true
    },
    cardClass: {
      type: [Array, String],
      required: false,
      default: 'is-3'
    },
    itemClass: {
      type: [Array, String],
      required: false,
      default: 'gap-2'
    },
    actions: {
      type: [Array],
      default: () => {
        return ['edit', 'output', 'delete']
      }
    }
  },
  emits: ['delete'],
  setup(props, { emit }){
    // Math.max.apply(Math, array.map(function(o) { return o.y; }))
    const maxW = computed(() => Math.max.apply(Math, props.documents.map(function(o) { return o.preview_image.preview_version_metadata.width })))
    const maxH = computed(() => Math.max.apply(Math, props.documents.map(function(o) { return o.preview_image.preview_version_metadata.height })))

    const deleteQueue = ref([])

    const deleteDocument = (id) => {
      emit('delete', id)
      window.setTimeout(() => {
       deleteQueue.value.push(id)
      }, 1000)
    }

    return {
      maxW,
      maxH,
      deleteQueue,
      deleteDocument
    }
  }
}
</script>