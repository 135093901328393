<template>
  <div class="container is-max-desktop">
    <div class="has-text-centered my-6">
      <h2 class="title is-3">{{ sections.main_title}}</h2>
    </div>

    <div class="has-text-centered my-6 is-max-tablet">
      <h3 class="title is-4 is-spaced">{{ sections.sections[0].title}}</h3>
      <p class="subtitle is-6">{{ sections.sections[0].subtitle}}</p>
    </div>

    <div class="my-6 is-max-tablet">
      <div class="box my-6" v-for="section in sections.subsections" :key="section.title">
        <p class="is-size-5">{{ section.title }}</p>
        <p class="is-size-6">{{ section.subtitle }}</p>

        <div class="mt-5" v-if="typeof(section.data_elements[0]) == 'string'" :class="[section.data_elements.length > 1 ? 'columns is-multiline' : '']">
          <document-inputs
            v-for="elKey in section.data_elements" :key="elKey" 
            :input="dataEl(elKey)"
            @update:modelValue="emitUpdate"
            @update:input="updateInput"
            @update:logo="updateLogo"
            @update:removeAttachment="removeAttachment"
            @update:assignContact="assignContact"
            @update:documentItem="updateDocumentItem"
          />
        </div>

        <div class="mt-5" v-else>
          <div class="columns">
            <div class="column is-half">
              <document-inputs
                v-for="elKey in section.data_elements[0]" :key="elKey" 
                class="field"
                :input="dataEl(elKey)"
                @update:modelValue="emitUpdate"
                @update:input="updateInput"
                @update:logo="updateLogo"
                @update:removeAttachment="removeAttachment"
                @update:assignContact="assignContact"
                @update:documentItem="updateDocumentItem"
              />
              <!-- formula total -->
              <div class="field">
                <label class="label">Total charges per month to quote</label>
                <p class="control has-currency">
                  <input type="text" class="input" value="$" readonly>
                  <input
                    type="number"
                    min="0"
                    v-model="formula.formula_total"
                    class="input"
                  />
                </p>
              </div>
            </div>
            <div class="column is-half">
              <div class="has-background-grey-lighter px-5 py-3">
                <div class="has-text-centered has-text-white-bis has-background-grey mt-3 p-1">
                  <p class="is-size-6 has-text-weight-bold">{{ section.data_elements[1].title }}</p>
                </div>
                <div>
                  <table class="table is-fullwidth" v-if="section.data_elements[1].table">
                    <thead>
                      <tr>
                        <th v-for="item in section.data_elements[1].table.head" :key="item">{{ item }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="items in section.data_elements[1].table.body" :key="items[0]">
                        <td v-for="item in items" :key="item">{{ item }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="my-3">
                    <p v-for="item in section.data_elements[1].disclaimers" :key="item.text" :class="item.class">{{ item.text }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DocumentInputs from '@/components/document/update/DocumentInputs'
  import { inject, computed } from 'vue'

  export default {
    props: {
      inputs: {
        type: Array
      },
      sections: {
        type: Object
      }
    },
    components: {
      DocumentInputs
    },
    emits: [
      'update:modelValue',
      'update:input',
      'update:logo',
      'update:assignContact',
      'update:removeAttachment',
      'update:documentItem'
    ],
    setup(props, { emit }) {
      const formulaElements = inject('formulaElements')
      const dmtLocale = inject('dmtLocale')
      const dataEl = (key) => {
        const dataEl = props.inputs.find(de => de.key == key)
        return dataEl
      }

      const documentItemsPriceArray = (de) => {
        const prices = Object.values(de.document_items).map(di => di[di.locale].value_price / 100)
        return prices
      }

      const arrSum = (arr) => {
        if (arr) {
          return arr.reduce(function(a, b) {
            return parseFloat(a) + parseFloat(b)
          }, 0)
        }
      }

      const formula = computed(() => {
        var results = {}
        
        if (formulaElements.value && formulaElements.value.length > 0) {
          
          formulaElements.value.forEach(fe => {
            const formulaVue = fe.properties.formulaVue3

            // const formulaVue = `(arrSum(documentItemsPriceArray(dataEl('wpo_services'))) * dataEl('mt_num_of_employees').value_int * (dataEl('mt_num_of_employees').value_int <= 100 ? 1.2 : dataEl('mt_num_of_employees').value_int > 1000 ? 1 : 1.1) * (['', undefined, null].includes(dataEl('mt_discount_rate').discount_percent) ? (1 - (Object.values(dataEl('grp_wpo_proposal_term').document_items).find(i => i.selected == true).discount_percent / 100) ) : (1 - (dataEl('mt_discount_rate').discount_percent)/100))).toFixed(2)`

            const maxValue = fe.properties.max_value ? parseFloat(fe.properties.max_value) : 0
            const minValue = fe.properties.min_value ? parseFloat(fe.properties.min_value) : 0
            let result

            if (formulaVue) {
              result = eval(formulaVue)
            }

            console.log('formula vue results: ', result)

            if (maxValue && result > maxValue) {
              result = maxValue
            } else if ( minValue && result < minValue) {
              result = minValue
            } 

            results[fe.key] = result

          })
        }

        return results
      })

      // emits to CustomizeScreen (just pass information)
      const updateInput = (input, property, newValue, diRef) => {
        emit('update:input', input, property, newValue, diRef)
      }

      const updateLogo = (contactObject, contactType, triggerElementKey, logoId) => {
        emit('update:logo', contactObject, contactType, triggerElementKey, logoId)
      }
      
      const removeAttachment = (input) => {
        emit('update:removeAttachment', input)
      }

      const assignContact = (contactObject, contactType, input) => {
        emit('update:assignContact', contactObject, contactType, input)
      }

      const emitUpdate = (value, input, issueCall) => {
        emit('update:modelValue', value, input, issueCall)
      }

      const updateDocumentItem = (value, newRef, bool) => {
        emit('update:documentItem', value, newRef, bool)
      }

      return {
        arrSum, 
        documentItemsPriceArray,
        dmtLocale,
        dataEl,
        emitUpdate,
        updateInput,
        updateLogo,
        removeAttachment,
        assignContact,
        updateDocumentItem,
        formula,
        // sections
      }
    }
  }
</script>