<template>
  <div class="is-relative preview-container">
    <div id="layoutContainer" v-if="vuePreviewLayout">
      <v-runtime-template :template="previewBody"></v-runtime-template>
    </div>
    <div v-else>
      <p class="title is-5">{{ t('customize.layout_not_found') }}</p>
    </div>
    <div v-if="isMultiPage && vuePreviewLayout" class="preview-navigation">
      <div class="svg-container">
        <font-awesome-icon icon="chevron-circle-left" size="1x" @click="goToPage(-1)" :class="{'disabled': isFirstPage}"/>
        <font-awesome-icon icon="chevron-circle-right" size="1x" @click="goToPage(1)" :class="{'disabled': isLastPage}" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/stylesheets/document_preview.scss'
</style>

<script>
import { onMounted, onBeforeUnmount, inject, computed, ref, watch, nextTick } from 'vue'
import VRuntimeTemplate from 'vue3-runtime-template'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import selectionMethods from '@/shared/mixins/selectionMethods'
import { DMS_ASSETS_URL } from '@/axios'

export default {
  mixins: [selectionMethods],
  name: 'Layout Preview',
  components: {
    VRuntimeTemplate
  },
  props: {
    layout: {
      type: Object,
      required: false
    },
    ts: {
      type: Object
    },
    locale: {
      type: String,
      required: false,
      default: 'en'
    }
  },
  data(){
    return {
      currentPage: 1
    }
  },
  computed: {
    isFirstPage(){
      return this.currentPage == 1
    },
    isLastPage(){
      return this.currentPage == this.pageTotal
    },
    pageTotal() {
      return this.isMultiPage ? parseInt(this.layout.properties.pageNum) : 1
    },
    isMultiPage() {
      return [true, 'true'].includes(this.layout.properties.isMultipage) && parseInt(this.layout.properties.pageNum) > 1
    },
  },
  watch: {
    layout() {
     if (this.isMultiPage) {
      this.currentPage = 1
     }
    }
  },
  setup(props){
    const { t } = useI18n()
    const route = useRoute()
    const routeName = computed(() => route.name)
    const dataElements = inject('dataElements')
    const assetElements = inject('assetElements')
    const store = useStore()
    const translation_keys = computed(() => dataElements.value.filter(
        de => de.object_type == 'translation_key'
      )[0])
    const previewLayouts = computed(() => props.layout.preview_layouts[0].preview_versions)
    const vuePreviewLayout = computed(() => previewLayouts.value.find(pl => pl.version === 'vue3'))

    const fontDependencies = computed(() => {
      const fontDeps = []

      for (const [key, value] of Object.entries(props.layout.properties)) {
        if (key.includes('font_url')) {
          fontDeps.push(value)
        }
      }

      return fontDeps
    })

    const fontsLinked = ref([])

    const previewStyle = computed(() => { 
      return vuePreviewLayout.value ? vuePreviewLayout.value.layout_css : ''
     
    })

    const previewBody = computed(() => {
      return vuePreviewLayout.value.layout_body ? vuePreviewLayout.value.layout_body : ''
      // return ``
      
    })

    const styleNodeRef = ref(null)

    const createStyleTag = (content) => {
      removeStyleTags()

      let style = document.createElement('style')
      style.type = 'text/css'
      style.scoped = 'true'
      style.appendChild(document.createTextNode(''))
      style.appendChild(document.createTextNode(content))
      styleNodeRef.value = style.childNodes[0] // a reference I store in the data hash
      
      document.head.appendChild(style)

      resolveFonts()
    }

    const resolveFonts = () => {
      if (fontDependencies.value.length) {
        
        fontDependencies.value.forEach((f) => {
          let stylesheetLink = document.createElement('link')
          stylesheetLink.type = 'text/css'
          stylesheetLink.rel = 'stylesheet'
          stylesheetLink.href = DMS_ASSETS_URL + f

          document.head.appendChild(stylesheetLink)

          fontsLinked.value.push(stylesheetLink)
        })
      }
    }

    const removeStyleTags = () => {
      if (styleNodeRef.value && styleNodeRef.value.parentElement) {
        styleNodeRef.value.parentElement.remove()
      }

      fontsLinked.value.forEach(f => f.remove())
      fontsLinked.value = []
    }


    const calculateResize = async() => {
      await nextTick()
      const container = document.getElementById('layoutContainer')
      const layoutFe = container ? container.firstElementChild : null
      
      if (container){
        container.style.height = ''
        layoutFe.style.transform = ''
      }

      if (layoutFe) {

        if (routeName.value != 'public-document') {
          const cntWidth = container.offsetWidth
          const layoutWidth = layoutFe.offsetWidth

          // if the template is wider than the container, shrink it. 
          if (layoutWidth > cntWidth) {
            const ratio = cntWidth / layoutWidth
            layoutFe.style.transform = 'scale(' + ratio + ')'
            layoutFe.style.transformOrigin = 'top left'
            container.style.height = layoutFe.offsetHeight * ratio + 'px'

          } else if (layoutWidth < cntWidth) {
            // If it's smaller, enlarge it to 80% of container. (cntWidth * 0.8)
            const ratio = cntWidth / layoutWidth
            layoutFe.style.transform = 'scale(' + ratio + ')'
            layoutFe.style.transformOrigin = 'top left'
            container.style.height = layoutFe.offsetHeight * ratio + 'px'
          } else {
            layoutFe.style.marginLeft = 'auto'
            layoutFe.style.marginRight = 'auto'
          }
          // save current height in store
          const resolvedHeight = container.style.height
          store.dispatch('saveDocumentInfo', { key: 'layoutHeight', value: resolvedHeight })
           
        } else {
          layoutFe.style.marginLeft = 'auto'
          layoutFe.style.marginRight = 'auto'
        }

        
      }
    }

    onMounted(() => {
      createStyleTag(previewStyle.value)
      calculateResize()     
    })

    onBeforeUnmount(() => {
      removeStyleTags()
    })

    watch(previewLayouts, () => {
      createStyleTag(previewStyle.value)
      calculateResize()
      
    })


    return {
      t,
      previewBody,
      previewStyle,
      createStyleTag,
      dataElements,
      assetElements,
      vuePreviewLayout,
      store,
      translation_keys
    }
  }
}
</script>