<template>
  <Notification
    v-model="notificationVisible"
    :type="notification.type"
    @dismiss="dismissNotification"
  >
    {{ notification.message }}
  </Notification>
  <section >
    <div class="container is-max-widescreen">
      <breadcrumbs :items="breadcrumbItems" />

      <HeaderTitle
      :title="pageTitle"
      :subtitle="t('single_contact.subtitle')"
      :isOutlined="true"
      class="mb-6"
      ></HeaderTitle>

    </div>
    <div class="has-gray-bg pt-4 pb-6">
      <div class="container is-max-widescreen pt-3">
        <div v-if="orgNotLoaded">
          <Loading :loading="loading.org"></Loading>
        </div>

        <div v-else>
            <h2 class="title is-4">Information</h2>
            <div class="columns">
              <div class="column is-12">
                <OrgInfoCard
                  :object="org"
                ></OrgInfoCard>
              </div>
            
            </div>
            
            
            <h3 class="title is-4 pt-6">Locations</h3>
            <!-- location cards -->

            <vue-horizontal v-if="orgLocationsExist" :button="orgLocations.length > 4">
              <div class="tile is-ancestor">
                <div 
                  class="item tile is-parent is-3"
                  v-for="location in orgLocations"
                  :key="location.id">
                    <LocationCard
                      :location="location"
                      :includeContact="false"
                      class="tile is-child"
                    ></LocationCard>
                </div>
              </div>
            </vue-horizontal>

            <p v-else>{{ t('single_contact.locations.does_not_exist') }}</p>
            

            <h3 class="title is-4 pt-6">Contacts</h3>           
            <!-- person cards -->
            <vue-horizontal v-if="orgContactsExist" :button="orgContacts.length > 4">
              <div class="tile is-ancestor">
                <div 
                  class="item tile is-parent is-3"
                  v-for="contact in orgContacts"
                  :key="contact.id">
                    <PersonCard
                      :object="contact"
                      class="tile is-child"
                      ></PersonCard>
                </div>
              </div>
            </vue-horizontal>

            <p v-else>{{ t('single_contact.organizations.does_not_exist') }}</p>

            <h3 class="title is-4 pt-6">Documents</h3>
            <!-- document cards -->
            <DocumentGrid v-if="orgDocumentsExist" :documents="org.documents" itemClass="has-gap gap-2 max-w-3" :actions="['edit', 'output']"></DocumentGrid>
            
            <p v-else>{{ t('single_contact.documents.does_not_exist') }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
require('@/assets/stylesheets/module_specific/contact.scss');
import { inject, ref, reactive, computed } from 'vue'
import { useI18n } from 'vue-i18n';
import HeaderTitle from '@/components/shared/HeaderTitle.vue'
import ContactService from '@/services/ContactService.js'

import PersonCard from "@/components/contact/PersonCard.vue"
import LocationCard from "@/components/shared/LocationCard.vue"
import DocumentGrid from '@/components/document/DocumentGrid.vue'
import OrgInfoCard from "@/components/contact/OrgInfoCard"

import VueHorizontal from 'vue-horizontal'

import { 
  notificationVisible, 
  notification, 
  dismissNotification, 
  updateNotification
} from '@/shared/setup/notifications.js'

export default {
  created () {
    this.loadOrg()  
  },
  setup(props){
    const { t } = useI18n()
    const org = reactive({})
    const errors = ref([])
    const loading = reactive({
      org: {
        show: true
      }
    })
    const contentClass = ref('is-offset-4')

    // computed
    const settings = inject('clientGlobalSettings')
    
    const orgNotLoaded = computed(() => loading.org.show )
    
    const pageTitle = computed(() => org.name ? org.name : "Organization")
    
    const orgLocations = computed(() => org.locations)
    
    const orgContacts = computed(() => org.contacts)
    
    const orgContactsExist = computed(() => orgContacts.value.length)
    
    const orgDocumentsExist = computed(() => org.documents.length)
    
    const orgLocationsExist = computed(() => orgLocations.value.length)

    const loadOrg = async () => {
      ContactService.getOrganization(props.id)
      .then(response => {
        const loadedOrg = response.data
        for (const [key, value] of Object.entries(loadedOrg)) {
          org[key] = value
        }
        loading.org.show = false
      })
      .catch(error => {
        console.log(error)
        const notification = {
          type: 'danger',
          message: 'Error encountered loading this organization.'
        }

        updateNotification(notification, true)
      })
    }

    const breadcrumbItems = ref([
      {
        route: 'user-contacts',
        button: 'contacts.buttons.back'
      }
    ])

    return {
      t,
      notificationVisible,
      notification,
      dismissNotification,
      updateNotification,
      loadOrg,
      org,
      errors,
      loading,
      contentClass,
      settings,
      orgNotLoaded,
      pageTitle,
      orgLocations,
      orgContacts,
      orgContactsExist,
      orgDocumentsExist,
      orgLocationsExist,
      breadcrumbItems
    }
  },
  components: {
    HeaderTitle,
    OrgInfoCard,
    LocationCard,
    PersonCard,
    DocumentGrid,
    VueHorizontal
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  }
}
</script>
