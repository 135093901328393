<template>
  <a role="button" @click.prevent="downloadImage()" :class="{'is-loading button is-ghost': downloadInProgress }">
    <slot></slot>
  </a>
</template>

<script>
  import { forceFileDownload } from '@/shared/setup/documentMethods.js'
  import DocumentService from '@/services/DocumentService.js'
  import { ref } from 'vue'

  export default {
    props: {
      locale: {
        type: Object
      },
      dmtId: {
        type: [String, Number]
      },
      layoutId: {
        type: [String, Number]
      }
    },
    emits: ['notify'],
    setup(props, { emit }) {
      const downloadInProgress = ref(false)

      const downloadImage = () => {
        downloadInProgress.value = true

        DocumentService.requestFile(props.dmtId, props.layoutId, props.locale.locale)
          .then(response => {
            forceFileDownload(response)
            downloadInProgress.value = false
          })
          .catch(error => {
            console.log(error)

            const notification = {
              message: 'Issue encountered downloading file.',
              type: 'danger'
            }

            emit('notify', notification)
          })
      }

      return {
        downloadImage,
        downloadInProgress
      }
    }
  }
</script>