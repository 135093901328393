<template>
  <div class="columns" v-for="dataset in datasets" :key="dataset.label">
    <div class="column" v-for="data in dataset.data" :key="data.label">
      <h4 class="title is-6">{{ data.label }}</h4>
      
      <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth" v-if="data.data.length">
        <thead>
          <tr>
            <th>Uses</th>
            <th>Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in data.data" :key="item.name || item.rank">
            <td>{{ item.count }}</td>
            <td>{{ item.name || '' }}</td>
            <td class="has-text-centered is-relative image-hover">
              <span class="icon is-small is-clickable" v-if="item.logo">
                <font-awesome-icon icon="eye" size="sm" />
              </span>
              <div class="box img-box" v-if="item.logo">
                <img :src="item.logo" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else>Nothing to show for the selected dates.</p>
    </div>
  </div>
</template>

<script>
  import { useI18n } from 'vue-i18n'

  export default {
    props: {
      datasets: {
        type: [Array],
        required: true
      }
    },
    setup() {
      const { t } = useI18n()

      return {
        t
      }
    }
  }
</script>