<template>  
  <section class="section">
    <div class="container is-max-widescreen">
      <div class="columns is-centered is-multiline">
        <div class="column is-three-quarters has-text-centered mb-5">
          <h1 class="title">Test Bed</h1>
        </div>
      </div>

      <ComponentFromJson :node="node" :groupSelects="groupSelects" :input="input" @updateInput="updateInput"></ComponentFromJson>

      <div>
        <p>Input: {{input.key}}</p>
        <p>Value: {{input.value}}</p>
      </div>
    </div>
  </section>
</template>


<script>
import ComponentFromJson from "@/components/inputs/ComponentFromJson.vue"

export default {
  components: {
    ComponentFromJson
  },
  setup(){

  },
  data(){
    return {
      node: null,
      groupSelects: {},
      input: null,
    }
  },
  created(){
    this.getJSON()
  },
  methods: {
    getJSON(){
      const jsonObj = `{
        "tagName": "div",
        "properties": {
          "class": ["inputClass"]
        },
        "children": [
          {
            "tagName": "label",
            "properties": {
              "class": "label"
            },
            "children": "Select headline"
          },
          {
            "tagName": "div",
            "properties": {
              "class": ["select"]
            },
            "children": [
              {
                "tagName": "select",
                "vModel": "value",
                "properties": {
                  "class": ["selectClass"]
                },
                "children": {
                  "tagName": "option",
                  "collection": "headline_options",
                  "collectionItems": "items",
                  "labelProperty": "name_label",
                  "valueProperty": "id",
                  "properties": {}
                }
              }
            ]
          }
        ]
      }`


      const options = `{
        "id": 150,
        "code": "greatdane-grp-parts-headline-001",
        "value": null,
        "value_text": null,
        "value_label": "Parts Headline",
        "item_type": "Content",
        "items": [
            {
                "id": 648,
                "position": "1",
                "name_label": "Great stays cool under pressure",
                "name": "GREAT STAYS COOL <br>UNDER PRESSURE",
                "code": "greatdane-content-parts-headline-001",
                "format": null,
                "description_label": null,
                "description": null,
                "glob_id": 420762,
                "value_label": "Great stays cool under pressure",
                "value": "GREAT STAYS COOL <br>UNDER PRESSURE",
                "value1_label": null,
                "value1": null,
                "value2_label": null,
                "value2": null,
                "value_text_label": null,
                "value_text": null,
                "value1_text_label": null,
                "value1_text": null,
                "value_int_label": null,
                "value_int": "",
                "value_float_label": null,
                "value_float": "",
                "discount_percent_label": null,
                "discount_percent": null,
                "price_cents": null,
                "price_currency": "USD",
                "locale": "en",
                "attachments": []
            },
            {
                "id": 649,
                "position": "2",
                "name_label": "Great is in all the details",
                "name": "GREAT IS IN ALL THE DETAILS",
                "code": "greatdane-content-parts-headline-002",
                "format": null,
                "description_label": null,
                "description": null,
                "glob_id": 420763,
                "value_label": "Great is in all the details",
                "value": "GREAT IS IN ALL THE DETAILS",
                "value1_label": null,
                "value1": null,
                "value2_label": null,
                "value2": null,
                "value_text_label": null,
                "value_text": null,
                "value1_text_label": null,
                "value1_text": null,
                "value_int_label": null,
                "value_int": "",
                "value_float_label": null,
                "value_float": "",
                "discount_percent_label": null,
                "discount_percent": null,
                "price_cents": null,
                "price_currency": "USD",
                "locale": "en",
                "attachments": []
            },
            {
                "id": 650,
                "position": "3",
                "name_label": "Helping you do the heavy lifting",
                "name": "HELPING YOU DO THE HEAVY LIFTING",
                "code": "greatdane-content-parts-headline-003",
                "format": null,
                "description_label": null,
                "description": null,
                "glob_id": 420764,
                "value_label": "Helping you do the heavy lifting",
                "value": "HELPING YOU DO THE HEAVY LIFTING",
                "value1_label": null,
                "value1": null,
                "value2_label": null,
                "value2": null,
                "value_text_label": null,
                "value_text": null,
                "value1_text_label": null,
                "value1_text": null,
                "value_int_label": null,
                "value_int": null,
                "value_float_label": null,
                "value_float": null,
                "discount_percent_label": null,
                "discount_percent": null,
                "price_cents": null,
                "price_currency": "USD",
                "locale": "en",
                "attachments": []
            }
        ]
    }`

    const input = `{
            "cache_key": "JSON_UI/document_objects/199754-20210729172825753678_en_params_14-optional_price_cents:price_centsoptional_price_currency:price_currency-9-true",
            "cache_duration": 5400,
            "id": 199754,
            "name": null,
            "object_type": "input",
            "field_type": "group_select_single",
            "required": null,
            "key": "headline",
            "value": "",
            "value_text": "",
            "value_date": null,
            "value_array": [
                "Content",
                "648"
            ],
            "position": 13,
            "status": "active",
            "section": "customize",
            "properties": {
                "label": "Headline",
                "help_text": "Headline",
                "source_template_object_id": "2440",
                "ui_input_position": "3"
            },
            "document_id": 10753,
            "created_at": "2021-07-12T15:00:34.259Z",
            "updated_at": "2021-07-29T17:28:25.753Z",
            "value_int": "",
            "value_float": null,
            "discount_percent": null,
            "price_cents": null,
            "price_currency": "USD",
            "parent_id": null,
            "version_number": 0,
            "group_id": 150,
            "template_object_id": 1943,
            "source_document_object_id": null,
            "caching_on": true,
            "group": {
                "cache_key": "JSON_UI/groups/150-20210621170448381878_en_params_3-id-name-code-group_type-9-true",
                "cache_duration": 5400,
                "id": 150,
                "name": "Great Dane - Group - Parts Headline",
                "code": "greatdane-grp-parts-headline-001",
                "group_type": "CONTENT",
                "caching_on": true,
                "value": null,
                "value_text": null,
                "value_label": "Parts Headline",
                "content_type": "Content",
                "source_document_object_id": null,
                "url": "https://dms3_api.getdms.com/group_select/greatdane-grp-parts-headline-001.json"
            },
            "document_input_object": {
                "cache_key": "JSON_UI/document_input_objects/158847-20210729172825745905_en_params_14-optional_price_cents:price_centsoptional_price_currency:price_currency-9-true",
                "cache_duration": 5400,
                "id": 158847,
                "type": "DocumentInputObject",
                "key": "company_name",
                "required": null,
                "value": "",
                "value_text": "",
                "value_array": [
                    "Content",
                    "648"
                ],
                "value_date": null,
                "parent_id": null,
                "status": "active",
                "created_at": "2021-07-12T15:00:34.253Z",
                "updated_at": "2021-07-29T17:28:25.745Z",
                "input_type_id": 44,
                "user_id": null,
                "document_template_id": 124,
                "template_object_id": 1943,
                "document_id": 10753,
                "document_object_id": 199754,
                "field_type": null,
                "value_int": "",
                "value_float": null,
                "discount_percent": null,
                "price_cents": null,
                "price_currency": "USD",
                "source_template_object_id": null,
                "source_document_object_id": null,
                "attachment_type": null,
                "caching_on": true
            },
            "document_items": {
                "648": {
                    "selected": true,
                    "selected_cache_key": "document_objects/199754-20210729172825753678_selected",
                    "cache_key": "JSON_UI/globalizations/456609-20210712150036341741/globalization/translations/795428-20210712150036323469_en_params_3-optional_price_cents-optional_price_currency-discount_percent-value-value_label-value1-value1_label-value2-value2_label-value_text-value_text_label-value1_text-value1_text_label-value_price_label-14-optional_price_cents:price_centsoptional_price_currency:price_currency-9-true",
                    "cache_duration": 5400,
                    "id": 201883,
                    "code": "greatdane-content-parts-headline-001",
                    "selectable_id": 648,
                    "item_type": "Content",
                    "caching_on": true,
                    "category": null,
                    "category_code": null,
                    "price_cents": null,
                    "price_currency": "USD",
                    "discount_percent": null,
                    "value": "GREAT STAYS COOL <br>UNDER PRESSURE",
                    "value_label": "Great stays cool under pressure",
                    "value1": null,
                    "value1_label": null,
                    "value2": null,
                    "value2_label": null,
                    "value_text": null,
                    "value_text_label": null,
                    "value1_text": null,
                    "value1_text_label": null,
                    "value_price_label": null,
                    "value_price": null,
                    "locale": "en",
                    "en": {
                        "glob_id": 456609,
                        "value": "GREAT STAYS COOL <br>UNDER PRESSURE",
                        "value_label": "Great stays cool under pressure",
                        "value1": null,
                        "value1_label": null,
                        "value2": null,
                        "value2_label": null,
                        "value_text": null,
                        "value_text_label": null,
                        "value1_text": null,
                        "value1_text_label": null,
                        "value_price_label": null,
                        "value_price": null,
                        "value_price_currency": "USD"
                    },
                    "es": {
                        "glob_id": 456609,
                        "value": "GRAN MANTENIMIENTO FRÍO BAJO PRESIÓN",
                        "value_label": "Great stays cool under pressure",
                        "value1": null,
                        "value1_label": null,
                        "value2": null,
                        "value2_label": null,
                        "value_text": null,
                        "value_text_label": null,
                        "value1_text": null,
                        "value1_text_label": null,
                        "value_price_label": null,
                        "value_price": null,
                        "value_price_currency": "USD"
                    },
                    "fr": {
                        "glob_id": 456609,
                        "value": "SUPER SÉJOUR AU <br>FRAIS SOUS PRESSION",
                        "value_label": "Great stays cool under pressure",
                        "value1": null,
                        "value1_label": null,
                        "value2": null,
                        "value2_label": null,
                        "value_text": null,
                        "value_text_label": null,
                        "value1_text": null,
                        "value1_text_label": null,
                        "value_price_label": null,
                        "value_price": null,
                        "value_price_currency": "USD"
                    },
                    "attachments": [],
                    "properties": {}
                },
                "649": {
                    "selected": false,
                    "selected_cache_key": "document_objects/199754-20210729172825753678_selected",
                    "cache_key": "JSON_UI/globalizations/459752-20210715201409087190/globalization/translations/800929-20210715201409068317_en_params_3-optional_price_cents-optional_price_currency-discount_percent-value-value_label-value1-value1_label-value2-value2_label-value_text-value_text_label-value1_text-value1_text_label-value_price_label-14-optional_price_cents:price_centsoptional_price_currency:price_currency-9-true",
                    "cache_duration": 5400,
                    "id": 204641,
                    "code": "greatdane-content-parts-headline-002",
                    "selectable_id": 649,
                    "item_type": "Content",
                    "caching_on": true,
                    "category": null,
                    "category_code": null,
                    "price_cents": null,
                    "price_currency": "USD",
                    "discount_percent": null,
                    "value": "GREAT IS IN ALL THE DETAILS",
                    "value_label": "Great is in all the details",
                    "value1": null,
                    "value1_label": null,
                    "value2": null,
                    "value2_label": null,
                    "value_text": null,
                    "value_text_label": null,
                    "value1_text": null,
                    "value1_text_label": null,
                    "value_price_label": null,
                    "value_price": null,
                    "locale": "en",
                    "en": {
                        "glob_id": 459752,
                        "value": "GREAT IS IN ALL THE DETAILS",
                        "value_label": "Great is in all the details",
                        "value1": null,
                        "value1_label": null,
                        "value2": null,
                        "value2_label": null,
                        "value_text": null,
                        "value_text_label": null,
                        "value1_text": null,
                        "value1_text_label": null,
                        "value_price_label": null,
                        "value_price": null,
                        "value_price_currency": "USD"
                    },
                    "es": {
                        "glob_id": 459752,
                        "value": "GRANDE ESTÁ EN <br>TODOS LOS DETALLES",
                        "value_label": "Great is in all the details",
                        "value1": null,
                        "value1_label": null,
                        "value2": null,
                        "value2_label": null,
                        "value_text": null,
                        "value_text_label": null,
                        "value1_text": null,
                        "value1_text_label": null,
                        "value_price_label": null,
                        "value_price": null,
                        "value_price_currency": "USD"
                    },
                    "fr": {
                        "glob_id": 459752,
                        "value": "GRAND EST DANS TOUS LES DÉTAILS",
                        "value_label": "Great is in all the details",
                        "value1": null,
                        "value1_label": null,
                        "value2": null,
                        "value2_label": null,
                        "value_text": null,
                        "value_text_label": null,
                        "value1_text": null,
                        "value1_text_label": null,
                        "value_price_label": null,
                        "value_price": null,
                        "value_price_currency": "USD"
                    },
                    "attachments": [],
                    "properties": {}
                }
            },
            "group_select_url": "https://dms3_api.getdms.com/group_select/greatdane-grp-parts-headline-001.json",
            "dio_update_url": "https://dms3_api.getdms.com/update_document_input_object/158847"
        }`

    this.node = JSON.parse(jsonObj)
    this.groupSelects['headline_options'] = JSON.parse(options)
    this.input = JSON.parse(input)

    },
    updateInput(value, property){
      console.log("got the function ", value, property)
      this.input[property] = value

    }

  }
}

</script>