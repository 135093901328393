<template>
  <div class="p-3">
    <div class="pb-4">
      <h3 class="title is-5">
        <slot>{{ t('output.' + option.content_type + '.content_title') }}</slot>
      </h3>
    </div>
    
    <form @submit.prevent="share">
      <div class="field">
        <label class="label">E-mail address(es)</label>
        <div class="control">
          <input class="input" type="text" v-model="email.emailAddresses">
        </div>
         <p class="help">Separate e-mail addresses with ";"</p>
      </div>

      <div class="field">
        <label class="label">Subject</label>
        <div class="control">
          <input class="input" type="text" v-model="email.queue.subject">
        </div>
      </div>

      <div class="field">
        <label class="label">Message</label>
        <div class="control">
          <textarea class="textarea" v-model="email.queue.message"></textarea>
        </div>
      </div>

      <div class="field">
        <div class="control">
          <label class="checkbox">
            <input type="checkbox" v-model="email.queue.send_confirmation">
              Send me a confirmation e-mail
          </label>
        </div>
      </div>

      <div class="field">
        <p class="control">
          <button class="button is-primary" type="submit">
            Send
          </button>
        </p>
      </div>
    </form>    
      
  </div>
</template>

<script>
  import { useI18n } from 'vue-i18n'
  import { ref, reactive } from 'vue'
  import SharingService from '@/services/SharingService.js'

  export default {
    components: {
    },
    props: {
      option: {
        type: Object,
        required: true
      }
    },
    emits: ['notify'],
    setup(props, {emit}) {
      const { t } = useI18n()
      const dmtId = ref(props.option.document_id)
      const layoutId = ref(props.option.layout_id)

      const email = reactive({
        emailAddresses: null,
        queue: {
          subject: null,
          message: null,
          send_confirmation: 0
        }
      })

      const notify = (notification) => {
        emit('notify', notification)
      }

      const share = () => {
        const contents = {
          id: dmtId.value,
          layout_id: layoutId.value,
          email_addresses: email.emailAddresses,
          email_queue: email.queue
        }

        SharingService.emailDocument(dmtId.value, contents)
          .then(response => {
            console.log(response.data)
            email.emailAddresses = null
            email.queue.subject = null
            email.queue.message = null
            email.queue.send_confirmation = 0

            const ntf = {
              type: 'success',
              message: 'Your message was successfully sent.'
            }

            notify(ntf)
          })
          .catch(error => {
            console.log(error)
            const ntf = {
              type: 'danger',
              message: 'Message could not be sent.'
            }

            notify(ntf)
          })
      }

      return { t, notify, dmtId, layoutId, share, email }
    }
  }
</script>